/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useCommonHomeStore from '../../../../app//stores/others/commonHomeStore';
import useMapStore, { addMarker } from '../../../../app/stores/company/mapStore';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import useLayoutStore from '../../../../app/stores/others/layoutStore';
import { k_request_paths } from '../../../../app/utility/const';
import { iBlackCross, iFullScreen, iRightArrowVector } from '../../../../app/utility/imageImports';
import CommonHomePageCard from '../../../../components/card/CommonHomePageCard';
import MapModal from '../../../../components/map/MapModal';
import OpenStreetMap from '../../../../components/OpenStreetMap/OpenStreetMap';

const TopHeader = () => {

    const [request, setRequest] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const navigateTo = useNavigate();

    const { map } = useMapStore();
    const { isOnline } = useGeneralStore();
    const { setSideBarSelectItemTitle } = useLayoutStore();

    const { t } = useTranslation();

    const { homePageData, home_location_points } = useCommonHomeStore();
    const [markerPoints, setMarkerPoints] = useState([]);


    const [topBarData, setTopBarData] = useState({
        title: '',
        link: "",
    });

    const selectCard = (index, path) => {
        navigateTo(path);
    }

    const handleBarClick = (linkFlow = true) => {
        if (linkFlow)
            navigateTo(topBarData?.link);
        else
            setRequest(false);
    }

    useEffect(() => {
        if (!homePageData?.is_profile_completed)
            setTopBarData({ title: t('Add an Address'), link: '/settings/profile' });
        else if (!homePageData?.is_car_added)
            setTopBarData({ title: t('Add a car'), link: '/car-manager' });
        else if (!homePageData?.is_place_bid)
            setTopBarData({ title: t('Place your first bid'), link: '/global-request' });
        else if (!homePageData?.is_create_request)
            setTopBarData({ title: t('Create your first request'), link: '/request/create' });
        else
            setTopBarData({ title: '', link: '' });
    }, [homePageData]);

    // useEffect(() => {
    //     generateHomeLocationPoints(homePageData);
    // }, [homePageData])

    useEffect(() => {
        isOnline && map && addMarker(home_location_points);
        const points = [];
        home_location_points?.forEach(point => {
            points.push({
                lat: point.pickup_lat,
                lng: point.pickup_lng
            })
        });
        setMarkerPoints(points);
    }, [home_location_points, map, isOnline]);

    return (
        <div className=''>
            {
                topBarData?.title && request ?
                    <div className='z-10 flex justify-between overflow-hidden cursor-pointer rounded-br8'>
                        <div onClick={() => {
                            if (topBarData?.title === t('Add an Address')) {
                                setSideBarSelectItemTitle(topBarData?.link + "Settings");
                            } else if (topBarData?.title === t('Add a car')) {
                                setSideBarSelectItemTitle(topBarData?.link + "Management");
                            }
                            handleBarClick(true);
                        }} className='flex items-center w-full pl-s15 py-s10 bg-cPrimary100'>
                            <span className=' text-fs16 font-fw500 text-cMainBlack'>{topBarData?.title}</span>
                            <img src={iRightArrowVector} alt="" className='w-s28 ml-s15' />
                        </div>
                        <img onClick={() => { handleBarClick(false) }} src={iBlackCross} alt="" className='z-50 px-3 bg-cPrimary100' />
                    </div>
                    : ""
            }


            {/* Home PageCard start */}

            <div className={`title  ${topBarData?.title && request ? 'my-4' : 'mb-4'}`}>{t('Dashboard')}</div>

            <div className='w-full h-[254px] relative mb-6 ring-2 ring-[#D4D4D4] rounded-xl overflow-hidden'>
                <OpenStreetMap width={'100%'} height={'254px'} markerPoints={markerPoints} scrollWheelZoom={true} />
                {/* <LimadiMap /> */}
                {!showModal && <img onClick={() => setShowModal(true)} src={iFullScreen} alt=""
                    className={`absolute w-8 h-8 z-[999]
                        bg-white p-1 rounded-md cursor-pointer top-2 right-2`}
                />}
            </div>

            <div className='flex flex-col items-center w-full md:flex-row'>

                <div className='grid w-full grid-cols-4 gap-4 md:w-1/7 lg:grid-cols-4 md:gap-4 lg:gap-4 xl:gap-5 2xl:gap-5'>

                    <CommonHomePageCard
                        onClick={() => {
                            selectCard(0, k_request_paths.in_bidding);
                            // setShowExpandedSidebarItem(true);
                            setSideBarSelectItemTitle(k_request_paths.in_bidding)

                        }}
                        title={t("In Bidding")}
                        count={homePageData?.in_bidding_count ?? 0}
                    />



                    <CommonHomePageCard
                        onClick={() => {
                            selectCard(1, k_request_paths.invitation);
                            // setShowExpandedSidebarItem(true);
                            setSideBarSelectItemTitle(k_request_paths.invitation)
                        }}
                        title={t("Invitation")}
                        count={homePageData?.invitation_count ?? 0}
                    />



                    {/* <CommonHomePageCard
                        onClick={() => {
                            selectCard(2, "/requests/not-planned");
                            // setShowExpandedSidebarItem(true);
                            setSideBarSelectItemTitle("/requests/not-planned")
                        }}
                        title={t("Not Planned")}
                        count={homePageData?.awarded_not_planned_count ?? 0}
                    /> */}



                    <CommonHomePageCard
                        onClick={() => {
                            selectCard(3, k_request_paths.ongoing);
                            setSideBarSelectItemTitle(k_request_paths.ongoing)
                            // setShowExpandedSidebarItem(true);
                        }}
                        title={t("Ongoing")}
                        count={homePageData?.ongoing_count ?? 0}
                    />



                    <CommonHomePageCard
                        onClick={() => {
                            selectCard(4, k_request_paths.completed);
                            setSideBarSelectItemTitle(k_request_paths.completed)
                            // setShowExpandedSidebarItem(true);
                        }}
                        title={t("Completed")}
                        count={homePageData?.compete_count ?? 0}
                    />



                    {/* <CommonHomePageCard
                        onClick={() => {
                            selectCard(5, "/global-request");
                        }}
                        title={t("Global Request")}
                        count={homePageData?.global_count ?? 0}
                    /> */}


                </div>
            </div>
            {/* Home PageCard end */}

            <MapModal showModal={showModal} setShowModal={setShowModal} markerPoints={markerPoints} />
        </div>
    );
};

export default TopHeader;