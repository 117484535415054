import axios from "axios";
import { create } from "zustand";
import { kuAddCar, kuAllCarTableData, kuCarDetails, kuDeleteCar, kuGetCarLicense, kuLicensePlateNumberAvailable, kuRenewCarLicense, kuUpdateCar } from "../../urls/companyUrl";
import { Toastr } from "../../utility/utilityFunctions";
import useGeneralStore from '../others/generalStore';
import useUtilityStore from "../others/utilityStore";
import { t } from "i18next";
import { common_take, k_license_status } from "../../utility/const";

const { setLoading } = useGeneralStore.getState();
const { setLoadingSearch } = useUtilityStore.getState();
const { path_record } = useGeneralStore.getState();

const useCarStore = create((set, get) => ({

  allCarList: [],
  setAllCarList: (value) => set({ allCarList: value }),

  allCarListTemp: [],
  setAllCarListTemp: (value) => set({ allCarListTemp: value }),

  allCarLicenseList: [],
  setAllCarLicenseList: (value) => set({ allCarLicenseList: value }),

  selectedLicensePack: 0,
  setSelectedLicensePack: (value) => set({ selectedLicensePack: value }),

  selectedCarIndex: 0,
  setSelectedCarIndex: (value) => set({ selectedCarIndex: value }),

  carDetails: {},
  setCarDetails: (data) => {
    set({ carDetails: data });
    return;
  },

  carSearchKey: '',
  setCarSearchKey: (value) => set({ carSearchKey: value }),

  carLicenseRenewID: -1,
  setCarLicenseRenewID: (value) => set({ carLicenseRenewID: value }),

  isNoLicenseApply: false,
  setNoLicenseApply: (value) => set({ isNoLicenseApply: value }),

  licenseAddUpdateForm: {
    id: 0,
    license_id: 0,
    license_start: '',
    purchase_license_comment: '',

  },
  setLicenseAddUpdateForm: (value) => set({ licenseAddUpdateForm: value }),
  resetLicenseAddUpdateForm: () => {
    set({
      licenseAddUpdateForm: {
        id: 0,
        license_id: 0,
        license_start: '',
        purchase_license_comment: '',

      }
    })
  },

  updateCarForm: {
    id: 0,
    name: '',
    license_id: '',
    car_license_plate_number: '',
    license_start: '',
    image: '',
    comment: '',
  },
  setUpdateCarForm: (value) => {
    set({ updateCarForm: value });
  },

  addCarForm: {
    id: 0,
    name: '',
    license_id: '',
    car_license_plate_number: '',
    license_start: '',
    image: '',
    comment: '',
  },
  setAddCarForm: (value) => {

    set({ addCarForm: value });
  },
  resetAddCarForm: () => set({
    addCarForm: {
      id: 0,
      name: '',
      license_id: '',
      car_license_plate_number: '',
      license_start: '',
      image: '',
      comment: '',
    }
  }),

  //All Modal

  showAddCarModal: false,
  setShowAddCarModal: (value) => set({ showAddCarModal: value }),

  showEditCarModal: false,
  setShowEditCarModal: (value) => set({ showEditCarModal: value }),

  showCarLicensePackageModal: false,
  setShowCarLicensePackageModal: (value) => set({ showCarLicensePackageModal: value }),

  showCarDeleteModal: false,
  setShowCarDeleteModal: (value) => set({ showCarDeleteModal: value }),

  showCarLicenseSkip: false,
  setShowCarLicenseSkip: (value) => set({ showCarLicenseSkip: value }),


  // table view
  order: null,
  setOrder: (order) => {
    set({ order: order });
    return;
  },

  is_asc: 1,
  setIsAsc: (data) => {
    set({ is_asc: data });
    return;
  },

  take: common_take,
  setTake: (data) => {
    set({ take: data });
    return;
  },

  search_key: '',
  setSearchKey: (key) => {
    set({ search_key: key })
    return;
  },

  api_url: kuAllCarTableData,
  setApiUrl: (url) => {
    set({ api_url: url })
    return;
  },

  car_table_data: null,
  setCarTableData: (data) => set({ car_table_data: data }),

  termsAccepted: false,
  setTermsAccepted: (data) => set({ termsAccepted: data })

}));

export default useCarStore;

// *******************************
//e      handler functions
// *******************************


// ***********************
//        api calls
// ***********************


export const getCarsTableData = async () => {

  const { setCarTableData, is_asc, take, order, search_key, api_url } = useCarStore.getState();

  let body = {
    take: take,
    search: search_key,
    order_by: order,
    is_asc: is_asc,
  };

  console.log('body', body);

  try {
    if (search_key) setLoadingSearch(true); else setLoading(true);
    const res = await axios.get(api_url ?? kuAllCarTableData, { params: body });
    console.log('getCarsTableData:::', res.data);

    if (res?.data?.success) {
      setCarTableData(res?.data?.data)
      if (search_key) setLoadingSearch(false); else setLoading(false);
      return true;
    } else {
      Toastr({ "message": res?.data?.message, type: 'error' });
      if (search_key) setLoadingSearch(false); else setLoading(false);
      return false;
    }
  } catch (error) {
    console.log('getCarsTableData:::', error);
    Toastr({ "message": t("An error occurred!"), type: 'error' });
    if (search_key) setLoadingSearch(false); else setLoading(false);
    return false;
  }
};


// get license list
export const getAllLicenseList = async () => {
  const { setAllCarLicenseList } = useCarStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(kuGetCarLicense);
    console.log('getAllLicenseList: ', res.data);
    if (res.data.success) {
      setAllCarLicenseList(res.data.data);
    } else {
      Toastr({ message: res.data.message });
    }
    setLoading(false);
  } catch (err) {
    console.log('getAllLicenseList: ', err);
    Toastr({ message: t("An error occurred!") });
    setLoading(false);
  }
};

//l    apply license or update
export const updateOrApplyForLicense = async (data, doRenew = false) => {
  const { setCarDetails } = useCarStore.getState();

  try {
    setLoading(true);

    let body = {
      id: data?.id,
      license_id: data?.license_id,
      license_start: data?.license_start,
      purchase_license_comment: data?.purchase_license_comment,
    };

    if (doRenew) {
      body.renew_license_start = body.license_start;
      delete body.license_start;
    }

    console.log("body", body)

    let res = doRenew ? await axios.post(kuRenewCarLicense, body) : await axios.post(kuUpdateCar, body);

    console.log('UpdateApplyForLicense: ', res.data);

    if (res.data.success) {
      Toastr({ message: "License Purchase request created", type: "success" });
      await getCarsTableData();
      setCarDetails(res.data.data);
      return true;
    } else {
      Toastr({ message: res.data.message });
      return false;
    }

  } catch (err) {
    console.log('UpdateApplyForLicense: ', err);
    Toastr({ message: t("An error occurred!") });
    return false;
  } finally {
    setLoading(false);
  }
};

//  add car data
export const addCar = async (data, imgUpdated = false) => {
  const { setCarDetails, isNoLicenseApply } = useCarStore.getState();
  try {
    setLoading(true);

    let body = {
      id: data?.id,
      name: data?.name,
      car_license_plate_number: data?.car_license_plate_number,
      comment: data?.comment,
    };

    if (imgUpdated) {
      body.image = data?.image;
    }

    const res = await axios.post(kuAddCar, body);

    console.log('addCar - after axios: ', res.data);

    if (res.data.success) {
      if (isNoLicenseApply) Toastr({ message: "Car created successfully without license", type: "success" })
      await getCarsTableData();
      setCarDetails(res.data.data.car);
      setLoading(false);
      return res?.data;
    } else {
      setLoading(false);
      Toastr({ message: res.data.message });
      return false;
    }

  } catch (error) {
    console.log('addCar: ', error);
    Toastr({ message: t("An error occurred!") });
    setLoading(false);
    return false;
  }
}

//  update car data
export const updateCar = async (data, imageUpdated = false) => {
  const { setCarDetails } = useCarStore.getState();
  try {
    setLoading(true);
    let body = {
      id: data?.id,
      name: data?.name,
      car_license_plate_number: data?.car_license_plate_number,
      comment: data?.comment,
    };

    if (imageUpdated) {
      body.image = data?.image;
    }

    console.log('updateCar - BODY: ', body);

    const res = await axios.post(kuUpdateCar, body);

    console.log('updateCar - after axios: ', res.data);

    if (res.data.success) {
      Toastr({ "message": res?.data?.message, type: 'success' });
      await getCarsTableData();
      setCarDetails(res?.data?.data);
      return true;
    } else {
      Toastr({ message: res.data.message });
      return false;
    }

  } catch (error) {
    console.log('updateCar: ', error);
    Toastr({ message: t("An error occurred!") });
    return false;
  } finally {
    setLoading(false);
  }
}

// delete car 
export const deleteCar = async (car_id) => {
  const { setApiUrl } = useCarStore.getState();
  try {
    setLoading(true);

    const body = { id: car_id };
    console.log(body);
    const res = await axios.post(kuDeleteCar, body);
    console.log('deleteCar', res.data);

    if (res.data.success) {
      Toastr({ "message": res?.data?.message, type: 'success' });
      await setApiUrl(kuAllCarTableData);
      await getCarsTableData();
      return true;
    } else {
      Toastr({ message: res.data.message });
      return false;
    }

  } catch (err) {
    console.log('deleteCar', err);
    Toastr({ message: t("An error occurred!") });
    return false;
  } finally {
    setLoading(false);
  }
};


export const getLicenseStatusText = (license_status) => {
  if (license_status === k_license_status?.no_license) return 'No License';
  if (license_status === k_license_status?.expire_warning) return "Expire soon";
  return license_status;
};


export const handleOrder = async (order_by, action) => {
  const { setIsAsc, is_asc, setOrder, setApiUrl, order } = useCarStore.getState();
  await setOrder(order_by);
  if (order !== order_by) await setIsAsc(1);
  else await setIsAsc(is_asc ? 0 : 1);
  await setApiUrl(kuAllCarTableData);
  const success = await action();
  if (!success) setIsAsc(is_asc ? 0 : 1);
  if (!success && order !== order_by) setIsAsc(1);
}


//car details
export const getCarDetails = async (car_id) => {

  const { setCarDetails } = useCarStore.getState();
  try {
    setLoading(true);
    const res = await axios.get(kuCarDetails, { params: { "car_id": car_id } });
    console.log('getCarDetails :::::', res.data);

    if (res.data.success) {
      setCarDetails(res?.data?.data)
      return res?.data?.data;
    } else {
      Toastr({ message: res.data.message });
      return false;
    }

  } catch (err) {
    console.log('getCarDetails', err);
    Toastr({ message: t("An error occurred!") });
    return false;
  } finally {
    setLoading(false);
  }
};

/**
 * Check if license plate number is available.
 *
 * @param {string} licensePlateNumber License plate number to check.
 * @returns {Promise<boolean>} true if license plate number is available, false otherwise.
 */
export const licensePlateNumberAvailable = async (licensePlateNumber) => {
  try {
    const res = await axios.get(kuLicensePlateNumberAvailable, { params: { "car_license_plate_number": licensePlateNumber } });

    if (res.data.success) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const isDetailsPath = path_record?.old?.toLowerCase().includes('/car-manager/details/');
