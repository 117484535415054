import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import * as React from 'react';
import { FcCheckmark } from "react-icons/fc";
import { base_url_src } from '../../app/utility/const';
import { iCrossOutlined, iDropdownArrowIcon, iDummyUserAvatar } from '../../app/utility/imageImports';
import Image from '../image/Image';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default function CommonMultipleSelect({ label = "", dataArray = [], disabled = false, onSelectItem, selectItem = [], handleRemoveItem, isRoundedFull= false, noDataText= "No Data Found" }) {

    const [open, setOpen] = React.useState(false);

    return (
        <div className='max-h-[56px] min-h-[56px]'>
            <FormControl sx={{ m: 0, width: '100%' }}>

                <InputLabel
                    shrink={selectItem?.length !== 0}
                    sx={{
                        marginLeft: "-13px", // No margin on the left
                        paddingLeft: 0, // No padding on the left
                        marginBottom: 2, // 1 unit of margin at the bottom
                        paddingBottom: 2, // 1 unit of padding at the bottom
                        width: '100%',
                    }}
                    id="demo-multiple-chip-label">
                    <div className={`${disabled ? "text-[#939D98]" : "text-[#48504C]"} w-max text-fs16 font-fw500 capitalize`}>{label}</div>
                </InputLabel>

                <Select
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    IconComponent={() => (
                        <div style={{ position: 'absolute', bottom: '3px', right: '0px', padding: '0', margin: '0', }}>
                            <img src={iDropdownArrowIcon} />
                        </div>
                    )}
                    disabled={disabled}
                    style={{ p: 0, m: 0, cursor: disabled ? "not-allowed" : "pointer" }}
                    sx={{
                        m: 0,
                        p: 0,
                        outline: "none",
                        borderBottom: disabled ? "1px solid #BCC2BF" : "1px solid #949494",
                        cursor: disabled ? "not-allowed" : "pointer",
                        borderRadius: 0,
                        '&:hover': {
                            borderBottom: disabled ? "1px solid #BCC2BF" : '2px solid #949494',
                        },
                        ".MuiOutlinedInput-notchedOutline": {
                            border: 0,
                            outline: "none",
                            p: 0,
                            m: 0,
                        },

                        "&:focus-within .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                            p: 0,
                            m: 0,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: 0,
                            p: 0,
                            m: 0,
                            backgroundColor: "none",
                        },
                        '& .MuiSelect-select': {
                            paddingRight: 0,
                            paddingLeft: 0,
                            paddingTop: "12px",
                            paddingBottom: "8px",
                        },
                        '& .Mui-disabled': {
                            cursor: "not-allowed"
                        }

                    }}

                    id="demo-multiple-chip"
                    multiple
                    value={selectItem}

                    renderValue={(e) => (

                        <div className='flex flex-wrap mt-s2 gap-x-2'>
                            {
                                selectItem?.map((value) => (
                                    <SelectItem
                                        key={value?.id}
                                        label={value?.title}
                                        image={value?.image}
                                        onRemove={() => handleRemoveItem(value)} // Pass remove handler
                                        isRoundedFull={isRoundedFull}
                                    />
                                ))
                            }
                        </div>
                    )}
                    MenuProps={false}  // Hide menu if no items selected
                >
                    <div className={` ${dataArray?.length > 5 ? "h-[288px]" : dataArray?.length ? "h-full" : "h-s37"}  overflow-y-auto`}>
                        {dataArray?.length ? dataArray?.map((item, index) => (
                            <div
                                key={index}
                                className={`flex my-s5 mx-s10 px-s10 justify-between cursor-pointer rounded-br8 hover:bg-[#F5F5F5] ${selectItem?.includes(item) ? "bg-cPrimary50" : ""}`}
                                onClick={() => {
                                    onSelectItem(item);
                                }}
                            >
                                <div className='flex items-center space-x-2'>
                                    <Image src2={item?.image ? base_url_src + item?.image : null} roundedFull={isRoundedFull} dummyImage={iDummyUserAvatar} alt="" className='w-5 h-5 rounded cp' />
                                    <div className='flex items-center justify-center'>
                                        <ListItemText primary={item?.title} />
                                    </div>
                                </div>
                                {selectItem?.includes(item) && <div className='flex items-center justify-center'><FcCheckmark /></div>}
                            </div>
                        )) : 
                        <div className='flex justify-center items-center h-full cfont-normal text-center text-fs14 text-cGray800'>{noDataText}</div>}
                    </div>
                </Select>
            </FormControl>
        </div>
    );
}

const SelectItem = ({ label, onRemove, image = null, isRoundedFull }) => {
    return (
        <div onMouseDown={(event) => {
            event.stopPropagation();
        }}
            className='flex items-center py-1 space-x-1 rounded-full px-s6 border-[1px] border-cBorder'>
            <Image className="flex items-center w-5 h-5 rounded" roundedFull={isRoundedFull} src2={image ? base_url_src + image : null} dummyImage={iDummyUserAvatar} />
            <div className='flex items-center justify-center text-fs14 font-fw400 text-cGray800'>{label}</div>
            <img onClick={(e) => {
                e.stopPropagation();
                onRemove(e);
            }} src={iCrossOutlined} alt="" className='cp' />
        </div>
    )
}
