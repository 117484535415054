/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore from "../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../app/stores/others/generalStore";
import { user_role as role } from "../../../../app/utility/const";
import { iBorderedImageUploadIcon } from "../../../../app/utility/imageImports";
import {
    addMinutesToTime,
    checkForCurrentDate,
    formatDateToApiDate,
    getStringFromDateObject,
    isFutureDateTime,
    isWhitespaceOnly,
    Toastr,
} from "../../../../app/utility/utilityFunctions";
import CommonButton from "../../../../components/button/CommonButton";
import ImageBtn from "../../../../components/image/ImageBtn";
import ImageUpload from "../../../../components/imageUpload/ImageUpload";
import AddressAutoComplete from "../../../../components/input/AddressAutoComplete";
import CommonDatePicker from "../../../../components/input/CommonDatePicker";
import CommonInput from "../../../../components/input/CommonInput";
import CommonTimePicker from "../../../../components/input/CommonTimePicker";
import CommonModal from "../../../../components/modal/CommonModal";
import CancelImageBtn from "../../../../components/image/CancelImageBtn";

const EditDeliveryModal = ({ index = 0 }) => {
    const {
        tableSelectStopsAddDeliveryProduct,
        removeSelectStopsDeliveryProduct,
        updateSelectStopsForm,
        changeSelectStopsProductQuantity,
        showEditDeliveryModal,
        setShowEditDeliveryModal,
        selectStop,
        setTableCrFullForm,
        cr_form,
    } = useCreateRequestStore();

    const [doSearch, setDoSearch] = useState(false);
    const [isUpdateClicked, setIsUpdateClicked] = useState(false);
    const [updateClicked, setUpdateClicked] = useState(false);

    const { t } = useTranslation();

    const { user_role, isDirty, setIsDirty, isMassUploadDirty, setIsMassUploadDirty } = useGeneralStore();

    const setDirtyMode = ( ) => {
        if(!isDirty) setIsDirty(true)
        if(!isMassUploadDirty) setIsMassUploadDirty(true)
      }

    const formData = selectStop && selectStop[index ? index : 0];

    const clearFormData = () => {
        setDirtyMode()
        updateSelectStopsForm(index, "address", "");
        updateSelectStopsForm(index, "lat", "");
        updateSelectStopsForm(index, "lng", "");
        updateSelectStopsForm(index, "floor_number", "");
        updateSelectStopsForm(index, "formatted_date", "");
        updateSelectStopsForm(index, "date", "");
        updateSelectStopsForm(index, "start_time", "");
        updateSelectStopsForm(index, "end_time", "");
        updateSelectStopsForm(index, "duration", "");
        updateSelectStopsForm(index, "removed_products_ids", []);
        updateSelectStopsForm(index, "comment", "");
        updateSelectStopsForm(index, "attachment", "");
        updateSelectStopsForm(index, "products", [{ text: "" }]);
    }

    useEffect(() => {
        if (showEditDeliveryModal) setIsUpdateClicked(false);
        formData?.products?.length === 0 && tableSelectStopsAddDeliveryProduct(index, true);
    }, [showEditDeliveryModal]);

    const scrollDivRef = useRef(null);

    const [height, setHeight] = useState(window.innerHeight - 200);

    const isCrossVisible =
        !!formData?.date ||
        !!formData?.start_time ||
        !!formData?.duration ||
        !!formData?.end_time ||
        !!formData?.delivery_time ||
        (formData?.floor_number && !isWhitespaceOnly(formData?.floor_number)) ||
        !!formData?.address ||
        !!formData?.comment ||
        !!formData?.attachment ||
        (formData?.products?.length > 0 && !!formData?.products?.filter((item) => item.text).length)


    let curr_hour = (new Date()).getHours();
    let curr_min = (new Date()).getMinutes();

    const isTimeValidationRequired = () => {

        if (cr_form?.pickup?.date) {
            const pickupDate = new Date(cr_form?.pickup?.date);
            const deliveryDate = new Date(formData?.date);
            const isFutureDeliveryDate = deliveryDate > pickupDate;

            if (!isFutureDeliveryDate) {
                return true;
            } else {
                return false;
            }
        }
        if (formData?.date) {
            if (!isFutureDateTime(formData?.date, true)) return true;
            else return false;
        }
    }

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight - 200);
        };

        window.addEventListener("resize", handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    useEffect(() => {
        console.log("selectStop: ", selectStop);
        if (updateClicked) {
            setTableCrFullForm(selectStop);
            setUpdateClicked(false);
            setShowEditDeliveryModal(false);
        }
    }, [selectStop, updateClicked]);

    return (
        <div>
            <CommonModal
                showModal={showEditDeliveryModal}
                setShowModal={setShowEditDeliveryModal}
                modalTitle={
                    <div className="flex items-center space-x-4">
                        <div>{t("Edit Delivery") + ` ${index + 1}`}</div>
                        {isCrossVisible ? <div>
                            <CancelImageBtn
                                hasHoverAction={true}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    clearFormData()
                                }}
                                showTooltip={true}
                                tooltipMessage={t("Clear")}
                            />
                        </div> : <></>
                        }
                    </div>
                }
                widthClass="w-[900px]"
                modalSpace={true}
                mainContent={
                    <>
                        <div
                            ref={scrollDivRef}
                            style={{ maxHeight: `${height}px` }}
                            className="pr-4 overflow-y-auto"
                            key={index}
                        >
                            <input
                                value={formData?.address}
                                onChange={() => { }}
                                className="absolute h-0 outline-none caret-transparent"
                            />
                            <div className="grid grid-cols-2 gap-x-6">
                                <AddressAutoComplete
                                    className="w-full"
                                    required={true}
                                    label={t("Delivery Address")}
                                    name={"address"}
                                    address={formData?.address}
                                    isInvalid={(!formData?.lat && !formData?.lng && isCrossVisible) || (isUpdateClicked && !formData?.lat && !formData?.lng)}
                                    latName={"lat"}
                                    lngName={"lng"}
                                    changeValue={(name, value) => {
                                        setDirtyMode()
                                        if (name === "address") {
                                            updateSelectStopsForm(index, "address", value?.toString());
                                        } else if (name === "lat") {
                                            updateSelectStopsForm(index, "lat", value?.toString());
                                        } else if (name === "lng") {
                                            updateSelectStopsForm(index, "lng", value?.toString());
                                        }
                                    }}
                                    doSearch={doSearch}
                                    setDoSearch={setDoSearch}
                                    onFavAddressModalItemClick={(address) => {
                                        setDirtyMode()
                                        updateSelectStopsForm(index, "address", address?.address);
                                        updateSelectStopsForm(index, "lat", address?.lat);
                                        updateSelectStopsForm(index, "lng", address?.lng);
                                        updateSelectStopsForm(
                                            index,
                                            "floor_number",
                                            address.floor_number === null ? "" : address.floor_number
                                        );
                                    }}
                                />

                                <CommonInput
                                    max_input={20}
                                    type="text"
                                    labelText={t("Floor Number")}
                                    name={"floor_number"}
                                    value={formData?.floor_number !== "" && isWhitespaceOnly(formData?.floor_number) ? "" : formData?.floor_number}
                                    onChange={(e) => {
                                        setDirtyMode()
                                        if (e.target.value?.trim() === "") {
                                            updateSelectStopsForm(index, "floor_number", "")
                                        }
                                        else updateSelectStopsForm(
                                            index,
                                            "floor_number",
                                            e.target.value
                                        );
                                    }}
                                />
                            </div>

                            {useGeneralStore.getState().user_role === role.company && (
                                <div>
                                    <div className="grid grid-cols-2 mt-3 gap-x-6">
                                        <div className="grid grid-cols-2 gap-x-6">
                                            <div className="w-full">
                                                <CommonDatePicker
                                                    isInvalid={(!!formData?.start_time || !!formData?.end_time) && !formData?.date}
                                                    startDate={cr_form?.pickup?.date ? new Date(cr_form?.pickup?.date) : new Date()}
                                                    required={false}
                                                    allowPastDate={true}
                                                    shouldUpdateValue={true}
                                                    label={t("Date")}
                                                    name="formatted_date"
                                                    value={formData?.date}
                                                    onChange={(date) => {
                                                        setDirtyMode()
                                                        updateSelectStopsForm(
                                                            index,
                                                            "formatted_date",
                                                            getStringFromDateObject(date)
                                                        );
                                                        updateSelectStopsForm(
                                                            index,
                                                            "date",
                                                            formatDateToApiDate(date)
                                                        );

                                                        updateSelectStopsForm(
                                                            index,
                                                            "start_time",
                                                            ""
                                                        );
                                                        updateSelectStopsForm(index, "end_time", "");
                                                    }}
                                                />
                                            </div>

                                            <div className="w-full mt-3">
                                                <CommonTimePicker
                                                    showExtendedTimeUi={false}
                                                    disabled={!formData?.date}
                                                    required={false}
                                                    label={t("Time")}

                                                    init_time={formData?.start_time}


                                                    init_date={(formData?.date) ? formData?.date : cr_form?.pickup?.date ? cr_form?.pickup?.date : new Date()}
                                                    isCustomMinTimeValidation={isTimeValidationRequired()}
                                                    customMinTimeValue={cr_form?.pickup?.start_time ? addMinutesToTime(cr_form?.pickup?.start_time, 15) : curr_hour + ":" + curr_min}
                                                    isCurrentTimeValidation={!cr_form?.pickup?.date && formData?.date && checkForCurrentDate(formData?.date)}


                                                    end_time={formData?.end_time}
                                                    name="start_time"
                                                    onChange={(start_time, end_time) => {
                                                        if(start_time == formData?.start_time) return;
                                                        setDirtyMode()
                                                        updateSelectStopsForm(
                                                            index,
                                                            "start_time",
                                                            start_time
                                                        );
                                                        updateSelectStopsForm(index, "end_time", end_time);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-2 gap-x-6">
                                            <div className="w-full mt-3">
                                                <CommonTimePicker
                                                    showExtendedTimeUi={false}
                                                    required={false}
                                                    disabled={!formData?.date}
                                                    label={t("Range")}
                                                    init_time={formData?.end_time}
                                                    end_time={formData?.end_time}
                                                    name="start_time"
                                                    onChange={(start_time, end_time) => {
                                                        setDirtyMode()
                                                        updateSelectStopsForm(
                                                            index,
                                                            "end_time",
                                                            start_time
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="-mt-3">
                                                <CommonInput
                                                    max_input={5}
                                                    type="number"
                                                    labelText={t("Duration (min)")}
                                                    isInvalid={formData?.duration && formData?.duration < 3}
                                                    name={"duration"}
                                                    value={formData?.duration === 0 ? "" : formData?.duration}
                                                    onChange={(e) => {
                                                        setDirtyMode()
                                                        updateSelectStopsForm(
                                                            index,
                                                            "duration",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {formData?.products?.map((productItem, productIndex) => (
                                <div className={`relative -mt-[${user_role === role.company ? "11px" : ""}]`}>
                                    <CommonInput
                                        valuePaddingRight="28px"
                                        required={false}
                                        labelText={t("Product")}
                                        max_input={255}
                                        name={"product"}
                                        value={formData?.products[productIndex]?.text ?? ""}
                                        onChange={(e) => {
                                            setDirtyMode()
                                            if (e.target.value?.trim() === "") changeSelectStopsProductQuantity("Product", index, productIndex);
                                            else if (!e.target.value?.includes(",")) changeSelectStopsProductQuantity(e.target.value, index, productIndex);
                                        }}
                                    />
                                    {/* {formData?.products[productIndex]?.text && ( */}
                                    {(productIndex > 0 || formData?.products[productIndex]?.text?.length > 0) && (
                                        <div className="absolute right-0 top-3">
                                            <CancelImageBtn
                                                hasHoverAction={true}
                                                onClick={(e) => {
                                                    setDirtyMode()
                                                    e.stopPropagation();
                                                    if (productItem && productItem?.id) {
                                                        if (formData?.removed_products_ids?.length > 0) {
                                                            updateSelectStopsForm(index, "removed_products_ids", [
                                                                ...formData?.removed_products_ids,
                                                                productItem?.id,
                                                            ]);
                                                        } else {
                                                            updateSelectStopsForm(index, "removed_products_ids", [
                                                                productItem?.id,
                                                            ]);
                                                        }
                                                    }
                                                    if (formData?.products?.length === 1) updateSelectStopsForm(index, "products", [{ text: "" }]);
                                                    else removeSelectStopsDeliveryProduct(index, productIndex);
                                                }}
                                                showTooltip={false}
                                                hoverSize="w-7 h-7"
                                            />
                                        </div>

                                    )}
                                </div>
                            ))}

                            <AddProduct
                                onClick={() => {
                                    // if (formData?.products[formData?.products?.length - 1]?.text?.trim() === "") {
                                    //     return Toastr({ message: t("Please enter a product text."), type: "warning" });
                                    // }
                                    setDirtyMode()
                                    tableSelectStopsAddDeliveryProduct(index, true);
                                    setTimeout(() => {
                                        const scrollDiv = scrollDivRef?.current;
                                        if (scrollDiv) {
                                            scrollDiv.scrollTop = scrollDiv.scrollHeight;
                                        }
                                    }, 0);
                                }}
                            />

                            <div className="mt-4 mb-8">
                                <CommonInput
                                    labelText={t("Comment")}
                                    name={"comment"}
                                    value={isWhitespaceOnly(formData?.comment) ? "" : formData?.comment}
                                    onChange={(e) => {
                                        setDirtyMode()
                                        updateSelectStopsForm(
                                            index,
                                            "comment",
                                            e.target.value
                                        );

                                    }}
                                    textarea={true}
                                    max={255}
                                    max_input={255}
                                />
                            </div>

                            <ImageUpload
                                setImage={(value) => {
                                    setDirtyMode()
                                    updateSelectStopsForm(index, "attachment", value);
                                }}
                                image_url={formData?.attachment_url}
                                src={formData?.attachment}
                                setSrc={(value) => {
                                    setDirtyMode()
                                    updateSelectStopsForm(index, "attachment", value);
                                }}
                                title="Add Attachment"
                                imageUploadIcon={iBorderedImageUploadIcon}
                                addButtonClassName="border border-dashed border-cGray300 p-s10 w-max"
                                width="w-max"
                                showInfoText={true}
                                textStyle="text-fs14 font-fw400 text-cBrand leading-[25px]"
                            />
                        </div>

                        <div className="flex justify-end mt-7">
                            <CommonButton
                                onClick={async () => {
                                    setDirtyMode()
                                    setIsUpdateClicked(true);

                                    if (!formData?.address) return Toastr({ message: t("Please fill in the address field."), type: "warning" });
                                    if (isCrossVisible && !formData?.lat && !formData?.lng) return Toastr({ message: t("Address is invalid. Select a valid address from the suggestions."), type: "warning" });
                                    if (!!formData?.duration && formData?.duration < 3) return Toastr({ message: t("Duration can not be less than 3"), type: "warning" });
                                    if (formData?.products?.length === 0) return Toastr({ message: t("Please enter a product text."), type: "warning" });
                                    if ((!!formData?.start_time || !!formData?.end_time) && !formData?.date) return Toastr({ message: t("Date is required if start time or end time is selected."), type: "warning" });

                                    console.log("formData?.products:::: ", formData?.products);
                                    // let updatedProductsDataIndex = -1;
                                    formData?.products?.map((item, p_index) => {
                                        if (item?.text?.trim() === "") {
                                            // updatedProductsDataIndex = p_index;
                                            changeSelectStopsProductQuantity("Product", index, p_index);
                                        }
                                    });
                                    // console.log("updatedProductsDataIndex: ", updatedProductsDataIndex);

                                    // if (updatedProductsDataIndex !== -1) {
                                    //     console.log("TRACK ::: updatedProductsDataIndex: ", updatedProductsDataIndex);
                                    //     changeSelectStopsProductQuantity("Product", index, updatedProductsDataIndex);
                                    // }



                                    // setAddProduct(true);
                                    setUpdateClicked(true);
                                }}
                                btnLabel={t("Update")}
                            />
                        </div>
                    </>
                }
            />
        </div>
    );
};

export default EditDeliveryModal;

const AddProduct = ({ onClick }) => {
    const { t } = useTranslation();
    return (
        <div
            onClick={onClick}
            className="flex flex-row items-center justify-start mt-5 space-x-2 cursor-pointer w-max"
        >
            {/* <img src={iPlus} alt="" /> */}
            <div className="text-cBrand text-fs16 font-fw500">{t("Add Product")}</div>
        </div>
    );
};
