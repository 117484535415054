import { useTranslation } from "react-i18next";
import useCreateRequestStore from "../../../../../../../../../app/stores/others/createRequestStore";
import CommonButton from "../../../../../../../../../components/button/CommonButton";
import CommonModal from "../../../../../../../../../components/modal/CommonModal";
import useGeneralStore from "../../../../../../../../../app/stores/others/generalStore";

export default function RemoveAllStopConfirmationModal() {

  const {
    setTableViewInvalidIndex,
    setResetProduct,
    setTableCurrentPage,
    setTableCrFullForm,
    table_cr_form,
    showRemoveAllStopConfirmationModal,
    setRemoveAllStopConfirmationModal,
    setMassUpClearTriggered,
    updateCrField,
    cr_form,
  } = useCreateRequestStore();

  const {isDirty, setIsDirty, isMassUploadDirty, setIsMassUploadDirty} = useGeneralStore();

  const { t } = useTranslation();

  const resetStopsTableToDefault = (stopsTableArray, defaultSize = 30) => {
    setMassUpClearTriggered(true);
    if (stopsTableArray.length > 30) {
      return stopsTableArray.slice(0, 30);
    }
    return stopsTableArray;
  }

  return (
    <CommonModal
      showModal={showRemoveAllStopConfirmationModal}
      setShowModal={setRemoveAllStopConfirmationModal}
      modalTitle={t("Remove All Stops")}
      mainContent={
        <>
          <div className='mt-s20 '>{t('Are you sure that you want to clear all the input fields? Once you clear the input fields, you will need to fill out them again manually. Also it will delete all the custom ROWs you have added manually.')}</div>
          <div className="flex flex-row justify-end mt-5">
            <CommonButton
              btnLabel={t("Confirm")}
              onClick={async () => {
                let removedStopIdArray = [];
                let updatedArray = table_cr_form?.map((item, ItemIndex) => {

                  console.log("item from clear all modal: ", item);
                  if (item?.id) removedStopIdArray.push(item?.id);
                  return {
                    date: '',
                    formatted_date: '',
                    start_time: '',
                    end_time: '',
                    address: '',
                    lat: '',
                    lng: '',
                    comment: '',
                    attachment_url: '',
                    products: [],
                    floor_number: '',
                    duration: '',
                  };
                });

                if(!isDirty) setIsDirty(true);
                if(!isMassUploadDirty) setIsMassUploadDirty(true)

                updateCrField("removed_stops_ids", removedStopIdArray);

                console.log("removedStopIdArray: ", removedStopIdArray);
                console.log("cr_form: ", cr_form);

                updatedArray = resetStopsTableToDefault(updatedArray, 30);

                setTableCrFullForm(updatedArray);
                setTableCurrentPage(1);
                setResetProduct(true)
                await setTableViewInvalidIndex([]);
                setRemoveAllStopConfirmationModal(false);
              }}
              btn_type="button"
              colorType="danger"
            />

          </div>
        </>
      }
    />
  );
}

/* 


*/