import { Tooltip } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useGeneralStore from "../../app/stores/others/generalStore";

export default function NormalSideBarItem({
  title,
  linkTo = null,
  isActiveLink,
  isOpenSidebar,
  normalIcon,
  selectedIcon,
  onClick,
  total_unread_message = 0,
}) {

  const navigateTo = useNavigate();
  const {isDirty, setIsDirty} = useGeneralStore();

  const normalClickFunction = () => {
    onClick();
    linkTo && navigateTo(linkTo)
  }

  const handleClick = () => {
    let confirm = false
    if(isDirty && !confirm) {
      confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?')
    }

    if(isDirty && confirm) {
      if(title !== "Log out") {
        setIsDirty(false);
      }
      normalClickFunction();
    }

    else if (!isDirty) {
      normalClickFunction();
    }
    
    // setShowExpandedSidebarItem(false);
  }

  const [isHovered, setIsHovered] = useState(false);

  return (
    <div>
      <Tooltip
        title={isOpenSidebar ? "" : title}
        placement="right"
        color={'#242826'}
        componentsProps={{
          tooltip: {
            sx: {
              textTransform: "capitalize"
            }
          }
        }}
      >
        <div onClick={handleClick}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          className="cursor-pointer mt-s5">
          <div
            className={`flex items-center h-s44 transition-all duration-300 my-1.5
           ${isActiveLink
                ? "text-cBaseWhite bg-cBrand"
                : "bg-cBgSideBar text-cSideBarNormalText hover:bg-[#CBE9DA]"} ${isOpenSidebar ? "px-s5 rounded-br8" : "justify-center w-20"}`}
          >
            <div
              className={`${(isHovered && !isActiveLink) ? "bg-[#CBE9DA]" : null} relative flex justify-center items-center rounded-full transition-all duration-300 w-s30 h-s30 
              ${isActiveLink === true ? "" : "bg-cBgSideBar "} ${isOpenSidebar ? "my-s7 ml-s7" : ""}`}
            >
              <img
                className="object-contain max-w-[20px] min-w-[20px] min-h-[19px] max-h-[19px]"
                src={isActiveLink ? selectedIcon : normalIcon}
                alt="sidebar-icon"
              />

              {
                !isOpenSidebar && <div className="absolute top-2 flex items-end justify-end right-[-20px] ">
                  {
                    (title === "Chat" && total_unread_message !== 0) &&
                    <div className={`flex items-center justify-center ${total_unread_message > 99 ? "w-5 h-5" : "w-4 h-4"} bg-cRed500 text-cBaseWhite rounded-full text-fs10`}>
                      {total_unread_message > 99 ? "99+" : total_unread_message}
                    </div>
                  }
                </div>
              }

            </div>
            {
              isOpenSidebar ? (
                <div className="flex justify-between w-full">
                  <div className={`pl-3 text-fs14 text-left capitalize font-fw700  ${isActiveLink === true ? "text-cBaseWhite" : "text-cSideBarNormalText"}`}>
                    {title}
                  </div>
                  {
                    (title === "Chat" && total_unread_message !== 0) &&
                    <div className={`flex items-center justify-center ${total_unread_message > 99 ? "w-7 h-6" : "w-5 h-5"}  rounded-full text-fs14 bg-cRed500 text-cBaseWhite`}>
                      {total_unread_message > 99 ? "99+" : total_unread_message}
                    </div>
                  }
                </div>
              ) : (
                ""
              )
            }
          </div>
        </div>
      </Tooltip>
    </div>
  );
}
