import { Tooltip } from "antd";
import React, { useState } from "react";
import { iCrossGrey } from "../../app/utility/imageImports";

const CancelImageBtn = ({
  hoverColor = "bg-cButtonHoverColor",
  onClick = () => { },
  hoverImageSrc,
  imageSrc = iCrossGrey,
  hasHoverAction = false,
  showTooltip = false,
  tooltipMessage = "showing tooltip",
  tooltipColor = "#242826",
  customClass = "",
  imageClass = "",
  hoverSize = "w-7 h-7",
  iconSize = "max-w-[16px] min-w-[16px] min-h-[16px] max-h-[16px]",
}) => {
  const [image, setImage] = useState(imageSrc);
  const [bgColor, setBgColor] = useState("");
  return (
    <div
      onClick={onClick}
      className={`${hoverSize} flex items-center justify-center ${bgColor} rounded-full cursor-pointer ${customClass}`}
      onMouseOver={() => {
        if (hasHoverAction) {
          setImage(hoverImageSrc ? hoverImageSrc : imageSrc);
          setBgColor(hoverColor);
        }
      }}
      onMouseOut={() => {
        if (hasHoverAction) {
          setImage(imageSrc);
          setBgColor("");
        }
      }}
    >
      {showTooltip ? (
        <Tooltip color={tooltipColor} title={tooltipMessage}>
          <img
            alt="with tooltip"
            src={image}
            className={`cursor-pointer ${iconSize}  ${imageClass}`}
          />
        </Tooltip>
      ) : (
        <img alt="without tooltip" src={image} className={`${iconSize} ${imageClass}`} />
      )}
    </div>
  );
};

export default CancelImageBtn;
