/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import useCreateRequestStore, { handleCompany, searchCompany } from '../../../../../../app/stores/others/createRequestStore'
import CommonSearchBox from '../../../../../../components/input/CommonSearchBox'
import SubmittedForGlobal from './components/SubmittedForGlobal'
import Suggestions from './components/Suggestions'
import { useTranslation } from 'react-i18next'
import RatingChipContent from './components/RatingChipContent'
import CommonCheckbox from '../../../../../../components/input/CommonCheckbox'
import RequestAccordion from "../../../../../../components/Accordion/RequestAccordion";
import { create_request_steps } from "../../../../../../app/utility/const";
import { useLocation } from 'react-router-dom'
import useRequestStore from '../../../../../../app/stores/others/requestStore'
import CommonEmptyView from '../selectShift/components/CommonEmptyView'
import useGeneralStore from '../../../../../../app/stores/others/generalStore'

export default function SelectCompany() {

  const {
    setRate,
    setFavSelected,
    cr_form,
    changeCrForm,
    search_company_key,
    setSearchCompanyKey,
    rate,
    is_company_searching,
    company_search_result,
    available_companies,
    is_fav_selected,
    selected_companies,
    expandedAccordion,
    setExpandedAccordion,
    setSelectedCompanies,
    isCompanyChosen,
    setIsCompanyChosen,
  } = useCreateRequestStore();

  const { request_details } = useRequestStore();

  const { t } = useTranslation();
  const location = useLocation();

  const {isDirty, setIsDirty} = useGeneralStore()

  const showFilter = location.pathname.includes("/request/create") || request_details?.invitation_ids?.length || location.pathname.includes("/request/edit")

  useEffect(() => {
    // ! Using debounce
    const x = [];
    // for (let index = 0; index < rate; index++)  x.push(index + 1);
    let timer
    if (rate) { for (let i = rate; i <= 5; i++) x.push(parseInt(i)); }
    timer = setTimeout(() => searchCompany(search_company_key, x), 500);

    return () => clearTimeout(timer)
  }, [search_company_key, rate]);

  useEffect(() => {
    setFavSelected(false);
  }, [])

  useEffect(() => {
    handleCompany();
  }, [company_search_result, is_fav_selected, selected_companies, rate]);

  const isRequestEditMode = () => {
    if (location.pathname.includes("/requests/saved/details/")) return false;
    if (location.pathname.includes("/request/create")) return true;
    if (location.pathname.includes("/request/edit/")) return true;
  }

  const getSubtitleOne = () => {
    if (isRequestEditMode()) {
      return cr_form?.is_global ? "Submitted for Global" : "Not Submitted for Global";
    } else {
      return request_details?.is_global ? "Submitted for Global" : "Not Submitted for Global";
    }
  }

  const getSubtitleTwo = () => {
    if (isRequestEditMode()) {
      return cr_form?.company_user_ids?.length > 0 ? cr_form?.company_user_ids?.length + " directly invited" : "No direct invites";
    } else {
      return request_details?.invitation_ids?.length > 0 ? request_details?.invitation_ids?.length + " directly invited" : "No direct invites";
    }
  }

  const filteredInvitedCompanies = available_companies?.filter(item => request_details?.invitation_ids?.includes(item?.id));

  return (

    <RequestAccordion
      // isInvalid={isApplyGenerate && !isCrFormValidationWithAddress()?.pickup}
      showClearButton={false}
      // clearHandler={() => {
      //   clearPickupFrom(); console.log("clear Pickup HITS !!");
      // }}
      title={t("Selected Companies")}
      subTitleOne={getSubtitleOne()}
      subTitleTwo={getSubtitleTwo()}
      isForm={true}
      isExpanded={expandedAccordion === create_request_steps.selectedShifts}
      onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.selectedShifts ? null : create_request_steps.selectedShifts) }}
    >
      <div className={`${expandedAccordion === create_request_steps.selectedShifts ? "pt-5" : "pt-8"}`}>

        <SubmittedForGlobal
          checked={isRequestEditMode() ? cr_form?.is_global : request_details?.is_global}
          onClick={() => {
            if(!isDirty) setIsDirty(true);
            changeCrForm('is_global', cr_form?.is_global ? 0 : 1)
          }}
          isReadOnly={isRequestEditMode() ? false : true}
        />

        <div
          onClick={() => {
            console.log("selected_companies::::", selected_companies);
          }}

          className='mt-10 mb-3 sub-title text-cGray800'>{isRequestEditMode() ? t("Invite Companies") : t("Invited Companies")} ({isRequestEditMode() ? selected_companies?.length : request_details?.invitation_ids?.length})</div>

        {showFilter ?
          <div className='flex justify-between mb-5'>
            <div className=''>
              <CommonSearchBox
                fullWidth={false}
                placeholder={t('Search')}
                value={search_company_key}
                onChange={(e) => {
                  setSearchCompanyKey(e.target.value);
                }}
                onSearchClear={() => { setSearchCompanyKey(""); }}
                withClearSearch={true}

                search_loading={is_company_searching}
              />

            </div>

            <div className='flex space-x-4'>
              <div onClick={() => setFavSelected(!is_fav_selected)} className='cursor-pointer flex px-3 py-2 space-x-2 border-[1px] rounded border-cGray200'>
                <div className='font-medium text-fs16 text-cGray800'>{t("Favorite")}</div>
                <CommonCheckbox checked={is_fav_selected} onChange={() => setFavSelected(!is_fav_selected)} />
              </div>


              <div className='cursor-pointer flex px-3 py-2 space-x-2 border-[1px] rounded border-cGray200'>
                {/* <div className='flex items-center justify-center w-full font-medium text-fs16 text-cGray800'>{t("Rating")}</div> */}
                <RatingChipContent value={rate} onChange={(e) => setRate(e.target.value)} />
              </div>

            </div>
          </div> :
          <></>}

        {!isRequestEditMode() && !request_details?.invitation_ids?.length ?
          <CommonEmptyView message='You have not chosen any company yet' /> :

          <Suggestions
            isReadOnly={isRequestEditMode() ? false : true}
            companies={isRequestEditMode() ? available_companies : filteredInvitedCompanies}
            collectUserId={location.pathname.includes("/requests/saved/details/")}
          />
        }
      </div>
    </RequestAccordion>



  )
}

export const SelectItem = ({ title = "", is_selected = false, onClick = () => { } }) => {
  return (
    <div onClick={onClick} className={`w-fit rounded cursor-pointer border-[1px] p-3 ${is_selected ? "text-cBrand border-cBrand" : "border-cGray200 text-cGray800 "}`}>
      {title}
    </div>
  )
}
