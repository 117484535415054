import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from 'leaflet';

// Create a custom green marker icon with specific hex color
const createColorMarkerIcon = (color) => {
    return new L.Icon({
        iconUrl: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 52" width="22" height="35">
        <path fill="${color}" d="M16 0C7.178 0 0 7.178 0 16c0 8.821 16 36 16 36s16-27.179 16-36C32 7.178 24.822 0 16 0zm0 22.58c-3.624 0-6.564-2.94-6.564-6.565 0-3.624 2.94-6.564 6.564-6.564 3.625 0 6.565 2.94 6.565 6.564S19.625 22.58 16 22.58z"/>
        <circle cx="16" cy="16" r="6" fill="#ffffff"/>
      </svg>
    `)}`,
        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
        iconSize: [22, 35],
        iconAnchor: [11, 35],
        popupAnchor: [1, -34],
        shadowSize: [35, 35]
    });
};

const customGreenMarkerIcon = createColorMarkerIcon('#285D43');

export default function OpenStreetMap({
    width,
    height,
    center = [55.676098, 12.568337],
    zoom = 10,
    markerPoints = [],
    scrollWheelZoom = false }) {
    return (
        <MapContainer
            style={{ width: width, height: height }}
            center={center}
            zoom={zoom}
            minZoom={8}  // Limit zoom out
            maxZoom={18} // Limit zoom in
            scrollWheelZoom={scrollWheelZoom}
            keepAlive={true}
        >
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png"
            />
            {
                markerPoints.map((marker, index) => {
                    return (
                        <Marker
                            position={[marker.lat, marker.lng]}
                            key={index}
                            icon={customGreenMarkerIcon}
                        >
                            {/* <Popup>
                                A pretty CSS3 popup. <br /> Easily customizable.
                            </Popup> */}
                        </Marker>
                    )
                })
            }
        </MapContainer>
    );
}