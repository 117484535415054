/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import useCarStore, { getCarDetails, updateCar } from '../../../../app/stores/company/carStore';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/button/CommonButton';
import CommonInput from '../../../../components/input/CommonInput';
import CommonTitle from '../../../../components/title/CommonTitle';
import SquareImageUploader from '../../../../components/imageUpload/SquareImageUploader';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import { Toastr } from '../../../../app/utility/utilityFunctions';
import { k_license_status } from '../../../../app/utility/const';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import { useLocation } from 'react-router';

const EditCar = () => {

    const { updateCarForm, setUpdateCarForm, carDetails } = useCarStore();
    const { isDirty, setIsDirty } = useGeneralStore();

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const { car_id } = useParams();

    const isFormValid = !!updateCarForm?.name && !!updateCarForm?.car_license_plate_number && !!updateCarForm?.comment;

    const handleSubmit = async () => {
        if (isFormValid) {
            if (isDirty) setIsDirty(false);

            if (updateCarForm?.car_license_plate_number?.length > 9) {
                return Toastr({ message: t("License number max 9 characters"), type: "warning" });
            }

            const success = await updateCar(updateCarForm, Boolean(updateCarForm?.image));
            if (success) navigateTo(`/car-manager/details/${car_id}`);
        } else {
            Toastr({ message: t("Please fill up all required fields"), type: "warning" })
            return;
        }
    };

    useEffect(() => {
        setUpdateData();
    }, []);

    const setCarFormData = (data) => {
        setUpdateCarForm({
            id: data?.id,
            name: data?.name,
            car_license_plate_number: data?.car_license_plate_number,
            comment: data?.comment,
            // image: data?.image,
        });
    };

    const setUpdateData = async () => {
        if (isFormValid) {
            if (!updateCarForm?.id) {
                const success = await getCarDetails(car_id);
                setCarFormData(success);
            } else {
                setCarFormData(carDetails);
            }
        }
    };

    const location = useLocation();

    const { setPathRecord } = useGeneralStore();

    const handleGetCarDetails = async () => {
        console.log('handleGetCarDetails TRIGGERED', car_id);

        const data = await getCarDetails(car_id);

        setUpdateCarForm({
            id: data?.id,
            name: data?.name,
            car_license_plate_number: data?.car_license_plate_number,
            comment: data?.comment,
            image: data?.image,
        });
    }

    useEffect(() => {
        console.log("_OK");

        if (!updateCarForm?.id) {
            handleGetCarDetails();
        }
    }, []);

    useEffect(() => {
        setPathRecord(location.pathname);
    }, [location.pathname]);

    return (
        <div>

            <div className='flex justify-between'>
                <div>
                    <CommonTitle
                        withConfirm
                        title={t("Edit Car")}
                        link={"/car-manager"}
                        icon={true}
                    />
                    <div className='mt-2 mb-5'></div>
                </div>

                <div className='flex justify-end space-x-5'>
                    <CommonButtonOutlined
                        onClick={() => {
                            let confirm = false;
                            if (isDirty && !confirm) {
                                confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?')
                            }

                            if (isDirty && confirm) {
                                setIsDirty(false);
                                navigateTo(`/car-manager/details/${car_id}`);
                            }

                            else if (!isDirty) {
                                navigateTo(`/car-manager/details/${car_id}`);
                            }
                        }}
                        colorType='danger'
                        btnLabel={t('Cancel')}
                    />

                    {carDetails?.license_status !== k_license_status?.pending &&
                        <CommonButton
                            width="w-[120px]"
                            type='submit'
                            btnLabel={t("Update")}
                            onClick={handleSubmit}
                            // colorType={isFormValid ? "primary" : "disable"}
                            isDisabled={!isFormValid}
                        />}
                </div>
            </div>

            <div className='flex space-x-10'>
                <div className='w-full'>
                    <SquareImageUploader
                        iImage={carDetails?.image ?? null}
                        onChange={(e) => {
                            setUpdateCarForm({ ...updateCarForm, image: e });
                            if (!isDirty) setIsDirty(true);
                        }}
                        clearImage={() => { setUpdateCarForm({ ...updateCarForm, image: null }) }}
                        rounded={'!rounded-full'}
                    />
                    <div className='mt-5 sub-title'>{t("Details")}</div>

                    <form className='mt-4' onSubmit={(e) => e.preventDefault()}>

                        <div className='flex justify-between space-x-5'>
                            <CommonInput
                                labelText={t('Car Name')}
                                value={updateCarForm?.name}
                                name={'name'}
                                type='text'
                                required={true}
                                show_asterisk={true}
                                onChange={(e) => {

                                    if (e.target.value !== " ") {
                                        const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                        setUpdateCarForm({ ...updateCarForm, name: trimmedValue })
                                    }
                                    if (!isDirty) setIsDirty(true);
                                }}
                            />

                            <CommonInput
                                labelText={t('Car License Number')}
                                value={updateCarForm?.car_license_plate_number}
                                max_input={9}
                                name={'license'}
                                required={true}
                                show_asterisk={true}
                                onChange={(e) => {
                                    if (e.target.value !== " ") {
                                        const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                        setUpdateCarForm({ ...updateCarForm, car_license_plate_number: trimmedValue })
                                    }
                                    if (!isDirty) setIsDirty(true);
                                }}
                            />
                        </div>

                        <CommonInput
                            textarea={true}
                            rows={3}
                            max_input={255}
                            labelText={t('Car Specification')}
                            value={updateCarForm?.comment}
                            name={'details'}
                            required={true}
                            show_asterisk={true}
                            onChange={(e) => {
                                setUpdateCarForm({ ...updateCarForm, comment: e.target.value })
                                // if (trimmedValue !== "") {
                                //     setUpdateCarForm({ ...updateCarForm, comment: trimmedValue })
                                //     const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                // }
                                if (!isDirty) setIsDirty(true);
                            }} />
                    </form>
                </div>


                <div className='min-w-[380px] max-w-[380px]'></div>
            </div>
        </div>
    )
}

export default EditCar
