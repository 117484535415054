
import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FaChevronCircleRight, FaChevronCircleLeft } from "react-icons/fa";



import useCarStore from '../../../../app/stores/company/carStore';
import LicenseCard from './LicenseCard';
import useGeneralStore from '../../../../app/stores/others/generalStore';

const LicenseCarousel = ({
    selectedLicensePack,
    setSelectedLicensePack,
    isNoLicenseApply
}) => {

    const {
        allCarLicenseList,
        licenseAddUpdateForm,
        setLicenseAddUpdateForm,
    } = useCarStore();
    const { isDirty, setIsDirty } = useGeneralStore()

    const CustomNextArrow = ({ onClick, className }) => (
        <div className={`text-4xl bg-white rounded-full text-cBrand absolute right-[25px] top-1/2 transform -translate-y-1/2 z-10 ${className?.includes("slick-disabled") ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={onClick} isDisabled={className?.includes("slick-disabled")}>
            <FaChevronCircleRight color={className?.includes("slick-disabled") ?'gray' : ""} />
        </div>
    );

    const CustomPrevArrow = ({ onClick, className }) => (
        <div className={`text-4xl bg-white rounded-full text-cBrand absolute left-[-25px] top-1/2 transform -translate-y-1/2 z-10 ${className?.includes("slick-disabled") ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={onClick} isDisabled={className?.includes("slick-disabled")}>
            <FaChevronCircleLeft color={className?.includes("slick-disabled") ?'gray' : ""} />
        </div>
    );


    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        initialSlide: 0,
        responsive: [
            {
              breakpoint: 1440,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
              }
            }
          ]
    };

    return (
        <div className='w-full'>
            {
                allCarLicenseList?.length > 4 ? 
                <div className='slider-container ml-5'>
                    <Slider {...settings}>
                        {
                            allCarLicenseList?.map((item, index) =>
                                <div className="p-1" key={index}>
                                    <LicenseCard
                                        disabled={isNoLicenseApply}
                                        onClick={() => {
                                            if(isNoLicenseApply) return;
                                            if(selectedLicensePack != index) {
                                                if(!isDirty) setIsDirty(true)
                                            }
                                            setSelectedLicensePack(index);
                                            setLicenseAddUpdateForm({ ...licenseAddUpdateForm, license_id: item?.id });
                                        }}
                                        active={allCarLicenseList[selectedLicensePack]?.id === item?.id && !isNoLicenseApply}
                                        number={item?.number}
                                        amount={item?.price}
                                    />
                                </div>
                            )
                        }
                    </Slider>
                </div>
                : 
                <div className='flex flex-row gap-5'>
                    {allCarLicenseList?.map((item, index) =>
                        <div className="p-1" key={index}>
                            <LicenseCard
                                disabled={isNoLicenseApply}
                                onClick={() => {
                                    if(isNoLicenseApply) return;
                                    setSelectedLicensePack(index);
                                    setLicenseAddUpdateForm({ ...licenseAddUpdateForm, license_id: item?.id });
                                }}
                                active={allCarLicenseList[selectedLicensePack]?.id === item?.id && !isNoLicenseApply}
                                number={item?.number}
                                amount={item?.price}
                            />
                        </div>
                    )}
                </div>
            }
                
        </div>
    );
};

export default LicenseCarousel;