/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useShiftStore, { getShiftDetails } from '../../../../app/stores/company/shiftStore';
import useCreateRequestStore, { countAllStopsProducts, createRequestClear, saveRequest } from '../../../../app/stores/others/createRequestStore';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import useRequestStore from '../../../../app/stores/others/requestStore';
import { k_cr_actions, k_request_paths, k_shift_status, user_role as role } from '../../../../app/utility/const';
import { formatDate, formatTime } from '../../../../app/utility/utilityFunctions';
import SliderToSubmit from '../../../../components/input/SliderToSubmit';
import CommonModal from '../../../../components/modal/CommonModal';
import SummaryInfoItem from '../../../../components/utility/summary/components/SummaryInfoItem';
import { kuGetRequestsNew } from '../../../../app/urls/commonUrl';

export default function RequestConfirmModal() {

  const { is_show_request_confirm_modal, setShowRequestConfirmModal, cr_form, setSelectedCompanies } = useCreateRequestStore();
  const { user_role, setIsDirty } = useGeneralStore();
  const { available_shifts, setRequestApiUrl } = useRequestStore();
  const { setShiftDetailsData } = useShiftStore();
  const navigateTo = useNavigate();
  const { t } = useTranslation();

  const selectedShift = available_shifts?.find((i) => parseInt(i.id) === parseInt(cr_form?.shift_id))

  const [isShiftOngoing, setIsShiftOngoing] = useState(false);

  const fetchSelectedShiftDetails = async () => {

    let data = await getShiftDetails(cr_form?.shift_id, true);
    console.log("current Shift Details Data:::: data", data);
    setIsShiftOngoing(data?.status === k_shift_status.ongoing);

  }

  useEffect(() => {
    if (is_show_request_confirm_modal && cr_form?.shift_id) fetchSelectedShiftDetails();
  }, [is_show_request_confirm_modal]);

  return (
    <CommonModal
      showModal={is_show_request_confirm_modal}
      setShowModal={setShowRequestConfirmModal}
      widthClass='w-[65vw] max-w-[670px]'
      modalTitle={`${t('Create Request')}`}
      modalSpace={true}
      mainContent={
        <div onClick={() => {
          console.log("cr_form", cr_form);
          console.log("isShiftOngoing", isShiftOngoing);
        }}>
          <div className='mt-s12'>
            <div className='font-fw500 text-fs24 text-cMainBlack'>{t('Summary')}</div>

            <div className='w-full py-s10 flex flex-col justify-start space-y-[6px]'>
              <SummaryInfoItem title={t('Title')} description={cr_form?.title?.length > 0 ? cr_form?.title : "No title"} />
              <SummaryInfoItem title={t('Type')} description={cr_form?.transport_type} />
              <SummaryInfoItem title={t('Pickup Date')} description={formatDate(cr_form?.pickup?.date)} />
              <SummaryInfoItem title={t('Pickup Time')} description={(formatTime(cr_form?.pickup?.start_time) ?? '--') + ' - ' + (formatTime(cr_form?.pickup?.end_time) ?? '--')} />
              <SummaryInfoItem title={t('Delivery Overview')} description={`${cr_form?.deliveries?.length} ${t('Stops')} (${countAllStopsProducts(cr_form?.deliveries)} ${t('Packages')})`} />
              <hr />

              {user_role === role.company ? <>
                <SummaryInfoItem title={t('Driver Name')} description={selectedShift?.driver_name ?? ""} />
                <SummaryInfoItem title={t('Vehicle Number')} description={selectedShift?.car_name ?? ""} />
              </> : <></>}

              {user_role === role.customer ? <>
                <SummaryInfoItem title={t('Direct Invite')} description={cr_form?.company_user_ids?.length?.toString() ?? 0} />
                <SummaryInfoItem title={t('Global Invite')} description={cr_form?.is_global ? t('Submitted') : t('Not Submitted')} />
              </> : <></>}

            </div>

            <div className='mt-s6'>

              <SliderToSubmit width={630} onSubmission={async (data) => {
                if (data) {

                  const res = await saveRequest(k_cr_actions.submit);

                  if (res) {
                    setShowRequestConfirmModal(false);
                    setIsDirty(false);
                    createRequestClear();
                    setSelectedCompanies([]);
                    setShiftDetailsData(null);

                    if (user_role === role.company) {
                      if (isShiftOngoing) navigateTo(k_request_paths.ongoing);
                      else navigateTo(k_request_paths.awarded);
                    }
                    if (user_role === role.customer) {
                      setRequestApiUrl(kuGetRequestsNew)
                      navigateTo('/requests/in-bidding');
                    }

                    // setTimeout(() => {
                    //   window.scrollTo({
                    //     top: document.documentElement.scrollHeight - window.innerHeight,
                    //     behavior: "smooth"
                    //   });
                    // }, 1000);
                  }
                }

              }} />
            </div>
          </div>
        </div>
      }

    />
  )
}
