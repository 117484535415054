import React, { useState, useRef, useEffect } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

const Editor = ({
    value = '',
    onChange,
    onLengthChange,
    readOnly = false,
    placeholder = '',
    ...props
}) => {
    const [length, setLength] = useState(0);
    const containerRef = useRef(null);
    const quillRef = useRef(null);

    // Safe method to get text length
    const getTextLength = (quill) => {
        try {
            // Try multiple methods to get text length
            if (quill?.getText) {
                return quill.getText().trim().length;
            }
            if (quill?.root?.innerText) {
                return quill.root.innerText.trim().length;
            }
            return 0;
        } catch (error) {
            console.error('Error getting text length:', error);
            return 0;
        }
    };

    useEffect(() => {
        if (!containerRef.current) return;

        // Create editor container
        const editorContainer = containerRef.current.appendChild(
            containerRef.current.ownerDocument.createElement('div')
        );

        const toolbarOptions = [
            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
            ['blockquote',
                // 'code-block'
            ],
            ['link',
                // 'image',
                //  'video',
                //   'formula'
            ],

            // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
            // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
            // [{ 'direction': 'rtl' }],                         // text direction

            // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
            // [{ 'font': [] }],
            [{ 'align': [] }],

            // ['clean']                                         // remove formatting button
        ];

        // Initialize Quill
        const quill = new Quill(editorContainer, {
            theme: 'snow',
            readOnly,
            placeholder,
            modules: {
                toolbar: toolbarOptions
            },
            ...props
        });

        quillRef.current = quill;

        // Set initial content
        if (value) {
            quill.setContents(
                typeof value === 'string'
                    ? quill.clipboard.convert(value)
                    : value
            );

            // Set initial length
            const initialLength = getTextLength(quill);
            setLength(initialLength);
            onLengthChange?.(initialLength);
        }

        // Text change handler
        const handleTextChange = () => {
            const currentLength = getTextLength(quill);
            setLength(currentLength);
            onLengthChange?.(currentLength);

            if (onChange) {
                onChange(quill.root.innerHTML, quill.getContents());
            }
        };

        // Attach text change event
        quill.on('text-change', handleTextChange);

        return () => {
            quill.off('text-change', handleTextChange);
            quillRef.current = null;

            if (containerRef.current) {
                containerRef.current.innerHTML = '';
            }
        };
    }, []);

    // Update content if value changes
    useEffect(() => {
        const quill = quillRef.current;
        if (quill) {
            const currentContent = quill.root.innerHTML;
            const newContent = typeof value === 'string'
                ? value
                : quill.clipboard.convert(value);

            if (currentContent !== newContent) {
                quill.root.innerHTML = newContent;

                // Update length when value changes
                const newLength = getTextLength(quill);
                if (!newLength && value) {
                    setLength(newLength);
                    onLengthChange?.(newLength);
                }
            }
        }
    }, [value]);

    // Update read-only state
    useEffect(() => {
        const quill = quillRef.current;
        if (quill) {
            quill.enable(!readOnly);
        }
    }, [readOnly]);

    return <div ref={containerRef}></div>;
};

export default Editor;