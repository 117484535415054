/* eslint-disable react-hooks/exhaustive-deps */
import { t } from "i18next";
import { default as React, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useLocation, useParams } from "react-router-dom";
import useShiftStore, {
  advancedCalculationAuto,
} from "../../../../../app/stores/company/shiftStore";
import useCreateRequestStore, { countAllStopsProducts } from "../../../../../app/stores/others/createRequestStore";
import useRequestStore from "../../../../../app/stores/others/requestStore";
import useSettingsStore, {
  getProfileDetails,
} from "../../../../../app/stores/others/settingsStore";
import {
  k_shift_section,
  k_shift_status,
  request_details_types,
  user_role as role,
} from "../../../../../app/utility/const";
import {
  Toastr,
  calculateDistance,
  calculateTime,
  capitalizeFirstChar,
  formatDate,
} from "../../../../../app/utility/utilityFunctions";
import RequestAccordion from "../../../../../components/Accordion/RequestAccordion";
import CalculationStopCard from "./CalculationStopCard";

export default function AdvancedCalculation() {
  const { shiftDetailsData, shiftOptimizeRes } = useShiftStore();
  const { not_planned_stops, request_details, pickup_duration, setHasRequestDetailsChanged, hasRequestDetailsChanged } = useRequestStore();
  const { profileDetails } = useSettingsStore();
  const {
    shiftExpandedAccordion,
    setShiftExpandedAccordion,
    cr_form,
    savedCrFormRes,
  } = useCreateRequestStore();

  const params = useParams();
  const { type, request_id, shift_id } = params;

  const [items, setItems] = useState(shiftDetailsData?.stops ?? []);
  const [dragAndDropItems, setDragAndDropItems] = useState([]);
  const [isDraggingMode, setIsDraggingMode] = useState(false); //note: for manual drag and drop..
  const [targetRequestID, setTargetRequestID] = useState(null);

  const isValidDraggableStop = (oldIndex, newIndex) => {

    const targetItem = dragAndDropItems[oldIndex];

    console.log("item: ", targetItem);
    console.log("oldIndex: ", oldIndex);
    console.log("newIndex: ", newIndex);

    const targetItemType = targetItem?.stop_type ? targetItem?.stop_type === "default" ? targetItem?.type : targetItem?.stop_type : targetItem?.type

    console.log("targetItemType: ", targetItemType);

    //handle delivery stop restrictions
    if (targetItemType === "delivery") {
      //for moving up
      if (newIndex < oldIndex) {
        for (let i = newIndex; i < oldIndex; i++) {
          const checkingItem = dragAndDropItems[i];
          const checkingItemType = checkingItem?.stop_type ? checkingItem?.stop_type === "default" ? checkingItem?.type : checkingItem?.stop_type : checkingItem?.type
          if (checkingItemType.toLowerCase() === "pickup" && checkingItem?.req_id === targetItem?.req_id) {
            Toastr({ message: "Delivery can't go before Pickup!", type: "warning" });
            return false;
          }
        }
      } else {
        //for moving down
        for (let i = oldIndex; i < newIndex; i++) {
          const checkingItem = dragAndDropItems[i];
          const checkingItemType = checkingItem?.stop_type ? checkingItem?.stop_type === "default" ? checkingItem?.type : checkingItem?.stop_type : checkingItem?.type
          if (checkingItemType.toLowerCase() === "pickup" && checkingItem?.req_id === targetItem?.req_id) {
            Toastr({ message: "Pickup can't go after Delivery!", type: "warning" });
            return false;
          }
        }

      }
    }

    //handle pickup stop restrictions
    if (targetItemType === "pickup") {
      //for moving down
      if (newIndex > oldIndex) {
        for (let i = oldIndex; i < newIndex; i++) {
          const checkingItem = dragAndDropItems[i];
          const checkingItemType = checkingItem?.stop_type ? checkingItem?.stop_type === "default" ? checkingItem?.type : checkingItem?.stop_type : checkingItem?.type
          if (checkingItemType.toLowerCase() === "delivery" && checkingItem?.req_id === targetItem?.req_id) {
            Toastr({ message: "Pickup can't go after Delivery!", type: "warning" });
            return false;
          }
        }
      }
    }

    return true;
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // Check if the item was dropped outside the list
    if (!destination) return;

    // Check if the item was dropped in the correct location
    const validDraggable = isValidDraggableStop(source.index, destination.index);
    if (!validDraggable) return;

    // prevent if the source is a delivery and the destination is it's own request's pickup
    let sourceReqId = dragAndDropItems[source.index]?.request_id ?? dragAndDropItems[source.index]?.req?.id;
    let destinationReqId = dragAndDropItems[destination.index]?.request_id ?? dragAndDropItems[destination.index]?.req?.id;

    console.log("source REQ ID", sourceReqId);
    console.log(" _destination REQ ID", destinationReqId);

    let sourceType =
      dragAndDropItems[source.index]?.type?.toLowerCase() === "default"
        ? dragAndDropItems[source.index]?.stop_type?.toLowerCase()
        : dragAndDropItems[source.index]?.type?.toLowerCase();
    let destinationType =
      dragAndDropItems[destination.index]?.type?.toLowerCase() === "default"
        ? dragAndDropItems[destination.index]?.stop_type?.toLowerCase()
        : dragAndDropItems[destination.index]?.type?.toLowerCase();

    console.log("sourceType", sourceType);
    console.log("destinationType", destinationType);

    if (
      sourceReqId === destinationReqId &&
      destinationType === "pickup" &&
      sourceType === "delivery"
    )
      return Toastr({
        message: "Delivery can't come before Pickup!",
        type: "warning",
      });
    if (
      sourceReqId === destinationReqId &&
      sourceType === "pickup" &&
      destinationType === "delivery"
    )
      return Toastr({
        message: "Pickup can't come after Delivery!",
        type: "warning",
      });

    // Handle item reordering
    const reorderedItems = Array.from(dragAndDropItems);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    setDragAndDropItems(reorderedItems);
  };

  const location = useLocation();

  const checkForChangeInStops = (stops, currentStops) => {
    if(stops?.length === currentStops?.length && stops?.length) {
      let isChanged = false
       stops?.map((item, index) => {
        if((compareTwoStopsData(item?.date, currentStops[index]?.date))
          || compareTwoStopsData(item?.duration, currentStops[index]?.duration)
          || compareTwoStopsData(item?.start_time?.slice(0,5), currentStops[index]?.start_time?.slice(0,5))
          || compareTwoStopsData(item?.end_time?.slice(0,5), currentStops[index]?.end_time?.slice(0,5))) {
            isChanged = true;
          }
      })
      return isChanged;
    }
    else return true
  }

  const getDeliveryProducts = (stops) => {
    let totalProducts = [];

    stops.forEach(stop => {
      totalProducts = totalProducts.concat(stop.products);
    });

    return totalProducts;
  }

  const organizeCurrentRequestStops = (withoutSavedStops = false) => {
    let stopsOfThisShift = shiftDetailsData?.stops ?? [];
    let collectFromCr = location?.state?.referrer === request_details_types.saved || (location?.state?.referrer === request_details_types.awarded && request_details?.user?.user_role === role.company);
    let currentRequestStops = collectFromCr ? cr_form?.deliveries : request_details?.stops ?? [];

    let formattedCurrentReqStops = [];
    currentRequestStops?.forEach((stop) => {
      formattedCurrentReqStops.push({
        ...stop,
        type: "delivery",
        request_id: request_details?.request_id
      });
    });
    
    let finalStopList = [...stopsOfThisShift];
    let currentRequestTotalProduct = getDeliveryProducts(currentRequestStops);

    if(withoutSavedStops && (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") && location?.state?.isEditMode) {
      let stopsFromOtherRequest = shiftDetailsData?.stops?.filter(item => item?.req_id != request_details?.id)
      let stopsFromThisRequest = shiftDetailsData?.stops?.filter(item => item.req_id == request_details?.id);

      let hasChange = checkForChangeInStops(stopsFromThisRequest, [{ ...cr_form?.pickup}, ...formattedCurrentReqStops]);
      if(hasChange) {
        setHasRequestDetailsChanged(true)
      }

      if (hasChange || hasRequestDetailsChanged) {
        if (shiftDetailsData?.is_optimized) {
          finalStopList = [
            {
              ...cr_form?.pickup,
              products: currentRequestTotalProduct,
              type: "pickup",
              request_id: cr_form?.request_id,
            },
            ...formattedCurrentReqStops,
          ].concat(
            stopsFromOtherRequest?.map((item) => {
              return {
                ...item,
                plan_status: "un_optimized",
                distance: "",
                approx_time: "",
              };
            })
          );
        } else
          finalStopList = [
            {
              ...cr_form?.pickup,
              products: currentRequestTotalProduct,
              type: "pickup",
              request_id: cr_form?.request_id,
            },
            ...formattedCurrentReqStops,
          ].concat(stopsFromOtherRequest);
      } else finalStopList = [...stopsOfThisShift];
    }

    console.log("withoutSavedStops UPPER FN: ", withoutSavedStops);


    if (withoutSavedStops) return finalStopList;  //note: will only contain the stops from the shift data..

    finalStopList = formattedCurrentReqStops.concat(stopsOfThisShift); //note: putting the current request stops at the beginning of the list...

    if((location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") && location?.state?.isEditMode) {
      finalStopList = cr_form?.pickup
      ? [{ ...cr_form?.pickup, products: currentRequestTotalProduct, type: "pickup", request_id: cr_form?.request_id }, ...finalStopList]
      : finalStopList;
    }

    else finalStopList = request_details?.pickup_stop
      ? [{ ...request_details?.pickup_stop, products: request_details?.products, type: "pickup", request_id: request_details?.request_id, duration: request_details?.pickup_duration }, ...finalStopList]
      : finalStopList;
    return finalStopList;
  };
  
  const compareTwoStopsData = (data1, data2) => {
    if(data1 && data2) {
      return data1 != data2
    }
    else if(!data1 && !data2) return false;
    else return true;
  }

  const checkForChangeInDetails = (stops) => {
    if(request_details?.user?.user_role !== role.company && stops?.length === not_planned_stops?.length && stops?.length) {
      let isChanged = false
       stops?.map((item, index) => {
        if((compareTwoStopsData(item?.date, not_planned_stops[index]?.date))
          || compareTwoStopsData(item?.duration, not_planned_stops[index]?.duration)
          || compareTwoStopsData(item?.start_time, not_planned_stops[index]?.start_time)
          || compareTwoStopsData(item?.end_time, not_planned_stops[index]?.end_time)) {
            isChanged = true;
          }
      })
      return isChanged;
    }
    else return false
  }

  const organizeCurrentRequestStopsRequestMode = (withoutSavedStops = true, isRequestDetailsPage = true) => {
    let stopsOfThisShift = shiftDetailsData?.stops ?? [];
    const currentRequestData = isRequestDetailsPage ? request_details : request_details;
    let currentRequestStops = isRequestDetailsPage ? currentRequestData?.stops : request_details?.stops ?? [];
    let finalStopList = [...stopsOfThisShift];

    if(withoutSavedStops && location?.state?.referrer == "awarded" && location?.state?.isEditMode) {
      let stopsFromOtherRequest = shiftDetailsData?.stops?.filter(item => item?.req_id != request_details?.id)
      let stopsFromThisRequest = shiftDetailsData?.stops?.filter(item => item.req_id == request_details?.id);

      let hasChange = checkForChangeInDetails(stopsFromThisRequest, not_planned_stops);
      if(hasChange) setHasRequestDetailsChanged(true)

      if(hasChange || hasRequestDetailsChanged) {
        if(shiftDetailsData?.is_optimized) {
          finalStopList = not_planned_stops.concat(stopsFromOtherRequest?.map(item => {return {...item, plan_status: "un_optimized", distance: "", approx_time: ""}}));
        }
        else finalStopList = not_planned_stops.concat(stopsFromOtherRequest)
      }
      else finalStopList = [...stopsOfThisShift]
    }

    console.log("withoutSavedStops", withoutSavedStops);

    if (withoutSavedStops) return finalStopList;

    if(!withoutSavedStops && location?.state?.referrer == "awarded" && location?.state?.isEditMode) {
      return not_planned_stops.concat(stopsOfThisShift);
    }

    let formattedCurrentReqStops = [];
    currentRequestStops?.forEach((stop) => {
      formattedCurrentReqStops.push({
        ...stop,
        type: "delivery",
        request_id: currentRequestData?.request_id
      });
    });

    finalStopList = formattedCurrentReqStops.concat(finalStopList); //note: putting the current request stops at the beginning of the list...

    finalStopList = currentRequestData?.pickup_stop
      ? [{ ...currentRequestData?.pickup_stop, products: currentRequestData?.products, type: "pickup", request_id: currentRequestData?.request_id, duration: pickup_duration || currentRequestData?.pickup_duration }, ...finalStopList]
      : finalStopList;

    return finalStopList;
  };

  const showSecondOrThirdTitle = () => {
    if (items?.length === 0) return false;

    if (shiftDetailsData?.status !== k_shift_status.complete && location.pathname.includes("/request/create/shift/details/")) return true;
    if ((shiftDetailsData?.status === k_shift_status.complete || shiftDetailsData?.status === k_shift_status.ongoing) && location.pathname.includes("/shift-manager/details/")) return false;


    if (
      type === request_details_types?.ongoing ||
      type === request_details_types?.completed ||
      type === request_details_types?.history

    )
      return false;

    else return true;
  };

  useEffect(() => {
    if (
      type === request_details_types.invitation ||
      type === request_details_types.in_bidding ||
      type === request_details_types.not_planned ||
      location.pathname.includes("/global-request/details") ||
      location?.state?.referrer === "invitation" ||
      location?.state?.referrer === "not-planned" ||
      location?.state?.referrer === "global-request" || 
      location?.state?.referrer === "in-bidding"
    ) {
      if (shiftOptimizeRes?.steps?.length) {
        setItems(shiftOptimizeRes?.steps);
      } else {
        setItems(shiftDetailsData?.stops?.length ? not_planned_stops?.concat(shiftDetailsData?.stops) : not_planned_stops);
      }
    }
    else if (
      location.pathname.includes("/request/create/shift/details") &&
      shiftOptimizeRes?.steps?.length
    ) {
      console.log("SHIFT OPTIMIZE DETECTED");

      setItems(shiftOptimizeRes?.steps);
    }
    else if (location.pathname.includes("/request/create/shift/details") && savedCrFormRes?.id && location?.state?.referrer !== "awarded") {
      console.log("SHIFT SAVED DETECTED");
      setItems(organizeCurrentRequestStops(false));
    }
    else if (
      location.pathname.includes("/request/create/shift/details") &&
      location?.state?.referrer !== "invitation"
    ) {
      console.log("INITIAL SHIFT NOT OPTIMIZE DETECTED");
      setItems(organizeCurrentRequestStops(request_details?.shift_id == shiftDetailsData?.id ? true : false));
    }
    else if (
      location.pathname.includes("/shift-manager/details/") &&
      shiftOptimizeRes?.steps?.length
    ) {
      console.log("_MANAGER SHIFT OPTIMIZE DETECTED");
      setItems(shiftOptimizeRes?.steps);
    }
    else if (
      location.pathname.includes("/shift-manager/details/")
    ) {
      console.log("_MANAGER INITIAL SHIFT NOT OPTIMIZE DETECTED");
      setItems(shiftDetailsData?.stops);
    }

    //note: for invitation and in_bidding state only...

    else if (location.pathname.includes(`requests/awarded/details/${request_id}/edit/shift`)) {
      if (shiftOptimizeRes?.steps?.length) {
        setItems(shiftOptimizeRes?.steps);
      } else {
        setItems(organizeCurrentRequestStopsRequestMode(request_details?.shift_id == shift_id, true))
      }
    }

    else if (type === "saved") {
      if (!location.pathname.includes("edit")) {
        if (shiftOptimizeRes?.steps?.length) {
          setItems(shiftOptimizeRes?.steps);
        } else {
          setItems(organizeCurrentRequestStopsRequestMode(request_details?.shift_id == shift_id, true))
        }
      }
    }
    //note: default state to set...
    else if (shiftOptimizeRes?.steps?.length) setItems(shiftOptimizeRes?.steps);
    else setItems(shiftDetailsData?.stops ?? []);
  }, [shiftDetailsData, shiftOptimizeRes, savedCrFormRes, request_details]);

  useEffect(() => {
    //for request details page only..
    if (location.pathname.includes(`/requests/${type}/details/${request_id}`) && request_details?.id && type !== "global-request") {
      // setItems(organizeCurrentRequestStopsRequestMode((type === "on-going") || (type === "awarded" && !location.pathname.includes("edit")) ? true : false, true))
    }
  }, [request_details]);

  useEffect(() => {
    if (!profileDetails) getProfileDetails();
  }, []);

  const getApproxTimeString = (status, item) => {
    if (status === "complete" || status === "hold") { return "00:00"; }

    if (status === "hold" || status === "init" || status === "un_optimized") { return "00:00"; }

    return item?.approx_time;
  }

  const getIndexValue = (item, index) => {

    if (item?.q_index && (item?.plan_status !== "un_optimized" && item?.plan_status !== "hold")) return item?.q_index;

    if (item?.plan_status && item?.plan_status === "optimized") return (item?.q_index ?? index + 1)
    if (item?.status && item?.status === "optimized") return (item?.q_index ?? index + 1)
    return "NA";
  }

  const getIsTimeConflict = (item) => {
    if (
      item?.status !== "complete" &&
      item?.plan_status !== "complete" &&
      item?.status !== "hold" &&
      item?.plan_status !== "hold" &&
      item?.plan_status !== "init" &&
      item?.plan_status !== "un_optimized" &&
      item?.has_conflict
    ) return true;
    else return false;
  }

  const getTitleString = (item) => {
    if (item?.isCustom || item?.stop_type === "custom")
      return item?.title; //note: only show real title if it is custom stop
    else if (item?.request_id)
      return "Request ID - " + item?.request_id
    else if (item?.req?.request_id)
      return "Request ID - " + item?.req?.request_id
    else return capitalizeFirstChar(item?.type) + " Stop"
  }

  const getIndexType = (item) => {

    if (item?.stop_type === "pickup")
      return "pickup";
    else if (item?.stop_type === "delivery")
      return "delivery";
    else if (item?.stop_type === "custom")
      return "custom";
    else if (item?.type === "pickup")
      return "pickup";
    else if (item?.type === "delivery")
      return "delivery";
    else if (item?.type === "custom")
      return "custom";
    else
      return "";
  };

  function getStatusString(item) {
    if (item?.status === "delivered" || item?.stop_status === "delivered") {
      if (item?.type === "delivery") {
        return "Delivered";
      } else if (item?.type === "pickup") {
        return "Picked Up";
      } else {
        return "Completed";
      }
    }
     else {
      if (item?.type === "delivery") {
        return "Not Delivered";
      } else if (item?.type === "pickup") {
        return "Not Picked Up";
      } else {
        return "Not Completed";
      }
    }
  }


  const StopCard = (item, index) => {
    return (
      <CalculationStopCard
        withDragIcon
        key={index}
        index={getIndexValue(item, index)}
        isIndexRed={getIsTimeConflict(item)}
        isTimeConflict={getIsTimeConflict(item)}
        title={getTitleString(item)}
        address={item?.address}
        date={item?.date ? formatDate(item?.date) : ""}
        startTime={item?.start_time?.slice(0, 5)}
        endTime={item?.end_time?.slice(0, 5)}
        totalPackages={item?.products?.length ?? item?.req?.products?.length ?? 0}
        duration={item?.duration ? parseInt(item?.duration) + " mins" : "0 mins"}
        indexKm={item?.q_index !== null && item?.distance ? item?.distance + "" : "0"}
        indexTime={item?.q_index === null ? "00:00" : getApproxTimeString(item?.plan_status ?? item?.status, item)}
        indexType={getIndexType(item)}
        isDraggingMode={isDraggingMode}
        isOnHold={item?.status === "hold" || item?.plan_status === "hold"}
        isCompleted={item?.plan_status === "complete"}
        completeMessage={getStatusString(item)}
      />
    );
  };

  useEffect(() => {
    if (request_id) setTargetRequestID(request_id);
    else if (request_details?.id) setTargetRequestID(request_details?.id);
    else if (savedCrFormRes?.id) setTargetRequestID(savedCrFormRes?.id);
  }, [request_id, request_details, savedCrFormRes]);

  const sendStopDetails = () => {
    let requestType = type || location?.state?.referrer
    console.log(location)
    if(location?.state?.isEditMode && requestType) return true
    else if(request_details?.user?.user_role === role.company && !location?.state?.isEditMode) {
      return false
    }
    else return !!requestType;
  }

  const arrangedItems = () => {
    let onHoldItems = items?.filter(item => item?.status === "hold" || item?.plan_status === "hold")
    let normalItems = items?.filter(item => item?.status !== "hold" && item?.plan_status !== "hold")

    return onHoldItems?.concat(normalItems)
  }

  const getStops = () => {
    let requestType = type || location?.state?.referrer
    if(requestType === "saved" || (requestType === "awarded" && request_details?.user?.user_role === role.company)) {
      return items?.filter(item => item?.request_id == request_details?.request_id)
    }
    else if(!type && !location?.state?.referrer) return items
    else return not_planned_stops
  }

  const assignNegativeIdsToStops = (stops) => {
    let negativeId = -1;

    stops.forEach(obj => {
        if (obj?.id === undefined || obj?.id === null || !obj?.id) {
            obj.id = negativeId;
            negativeId--; 
        }
    });

    return stops;
}

  return (
    <div>
      <RequestAccordion
        showClearButton={false}
        title={t("Stops")}
        titleSecondaryText={
          shiftOptimizeRes?.distance && shiftOptimizeRes?.duration
            ? calculateDistance(shiftOptimizeRes?.distance)?.distance +
            "KM, " +
            calculateTime(shiftOptimizeRes?.duration)
            : shiftDetailsData?.approximate_distance ?
              calculateDistance(shiftDetailsData?.approximate_distance)?.distance + "KM, " + calculateTime(shiftDetailsData?.approximate_time_duration)
              : ""
        }
        showShift={true}
        isBorder={
          shiftExpandedAccordion === k_shift_section.advanced_calculation_stop
            ? false
            : true
        }
        isExpanded={
          shiftExpandedAccordion === k_shift_section.advanced_calculation_stop
        }
        subTitleOne={`${items?.length ?? 0} ${t("Stops")}`}
        onExpand={() => {
          setShiftExpandedAccordion(
            shiftExpandedAccordion === k_shift_section.advanced_calculation_stop
              ? null
              : k_shift_section.advanced_calculation_stop,
          );
        }}
        secondaryTitle={
          showSecondOrThirdTitle()
            ? isDraggingMode
              ? "Keep Changes"
              : "Manual"
            : null
        }
        handleSecondaryTitleClick={() => {
          setIsDraggingMode(!isDraggingMode);

          if (isDraggingMode) {
            let manualStopsArray = [];
            dragAndDropItems?.map((item, index) =>
              manualStopsArray.push({
                id: item?.stop_id ?? item?.id,
                type:
                  item?.type?.toLowerCase() === "custom" ? "custom" : "default",
              }),
            );
            let stopDetailsArray = dragAndDropItems?.map((item) => ({
              date: item?.date,
              duration: parseInt(item?.duration || 0),
              end_time: item?.end_time,
              id: item?.stop_id ?? item?.id,
              start_time: item?.start_time,
              address: item?.address,
              lat: item?.lat ? parseFloat(item?.lat) : item?.address_lat ? item?.address_lat : null,
              lng: item?.lng ? parseFloat(item?.lng) : item?.address_lng ? item?.address_lng : null, 
              products: item?.products,
              title: item?.title
            }));
            const formData = {
              is_web: 1,
              stops_details: assignNegativeIdsToStops(stopDetailsArray),
              stops: assignNegativeIdsToStops(manualStopsArray),
              start_location: {
                address: profileDetails?.address,
                lat: parseFloat(profileDetails?.lat),
                lng: parseFloat(profileDetails?.lng),
              },
            };

            console.log("starting MANUAL - OPTIMIZE!  _formData", formData);
            advancedCalculationAuto(
              formData,
              shiftDetailsData?.id,
              location.pathname.includes("/shift-manager/details/")
                ? null
                : targetRequestID,
              true,
            );
          }
          setDragAndDropItems(arrangedItems());
        }}
        thirdTitle={
          showSecondOrThirdTitle()
            ? isDraggingMode
              ? "Discard Changes"
              : "Optimize"
            : null
        }
        handleThirdTitleClick={() => {
          if (isDraggingMode) setIsDraggingMode(false);
          else {
            console.log("starting auto OPTIMIZE!");
            advancedCalculationAuto(
              {
                stops_details: getStops()?.map((item) => ({
                    date: item?.date,
                    duration: parseInt(item?.duration || 0),
                    end_time: item?.end_time,
                    id: item?.stop_id ? item?.stop_id : item?.id || null,
                    start_time: item?.start_time,
                    address: item?.address,
                    lat: item?.lat ? parseFloat(item?.lat) : item?.address_lat ? item?.address_lat : null,
                    lng: item?.lng ? parseFloat(item?.lng) : item?.address_lng ? item?.address_lng : null, 
                    products: item?.products,
                    title: item?.title
                  })),
                start_location: {
                  address: profileDetails?.address,
                  lat: parseFloat(profileDetails?.lat),
                  lng: parseFloat(profileDetails?.lng),
                },
                is_web: 1,
              },
              shiftDetailsData?.id,
              location.pathname.includes("/shift-manager/details/")
                ? null
                : targetRequestID,
            );
          }
        }}
        isThirdTitleRed={false}
      >
        {isDraggingMode ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-2"
                >
                  {dragAndDropItems.map((item, index) => (
                    <Draggable
                      key={`d_${index}`}
                      draggableId={`d_${index}`}
                      index={index}
                      isDragDisabled={item?.status === "complete" || item?.status === "hold" || item?.pllan_status === "hold"}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="draggable-item"
                          onClick={() => {
                            console.log("cr_form", cr_form);
                            console.log("dragAndDropItems", dragAndDropItems);
                            console.log(
                              " shiftDetailsData?.stops",
                              shiftDetailsData?.stops,
                            );
                          }}
                        >
                          {StopCard(item, index)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div
            onClick={() => {
              console.log("items", items);
              console.log("shiftOptimizeRes", shiftOptimizeRes);
              console.log("savedCrFormRes", savedCrFormRes);
              console.log(" shiftDetailsData", shiftDetailsData);
            }}
            className="space-y-2"
          >
            {arrangedItems()?.length > 0
              ? arrangedItems()?.map((item, index) => StopCard(item, index))
              : "No stop data found"}
          </div>
        )}
      </RequestAccordion>
    </div>
  );
}
