import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSettingsStore, { editProfile, getProfileDetails } from '../../../../../app/stores/others/settingsStore';
import { BUTTON_WIDTH, k_settings_paths } from '../../../../../app/utility/const';
import { Toastr } from '../../../../../app/utility/utilityFunctions';
import CommonButton from '../../../../../components/button/CommonButton';
import CommonButtonOutlined from '../../../../../components/button/CommonButtonOutlined';
import SquareImageUploader from '../../../../../components/imageUpload/SquareImageUploader';
import AddressAutoComplete from '../../../../../components/input/AddressAutoComplete';
import CommonInput from '../../../../../components/input/CommonInput';
import CommonTitle from '../../../../../components/title/CommonTitle';
import useGeneralStore from '../../../../../app/stores/others/generalStore';

const EditCustomerProfile = () => {

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const { setShowProfileEditModal, profileDetails } = useSettingsStore();
    const { isDirty, setIsDirty } = useGeneralStore()

    const [doSearch, setDoSearch] = useState(false);

    const [addAddressLabel, setAddAddressLabel] = useState("");

    const [lat, setLet] = useState("");

    const [editForm, setEditForm] = useState({
        id: "",
        name: "",
        email: "",
        phone: "",
        lng: ""
    });

    useEffect(() => {
        fetchProfileDetails()
    }, [])

    const fetchProfileDetails = async () => {
        const success = await getProfileDetails();
        if (success) window.scrollTo(0, 0);
    }
    const handleChange = async (name, value) => {
        if (!isDirty) setIsDirty(true);
        if (name === "address") {
            setAddAddressLabel(value);
        } else if (name === "lat") {
            setLet(value);
        } else if (name === "lng") {
            setEditForm({ ...editForm, lng: value });
        }
    };

    const handleCustomerEdit = async (e) => {
        e.preventDefault();

        const { id, name, email, phone, lng, image } = editForm;

        let body = { id, name, email, phone, image };

        if (addAddressLabel !== "" && lat && lng) {
            body = { ...body, address: addAddressLabel, lat, lng };
        } else if (addAddressLabel !== "") {
            Toastr({ message: t("Invalid Address"), type: "warning" });
            return;
        }

        const profileEditSuccess = await editProfile(body);

        if (profileEditSuccess) {
            setIsDirty(false);
            navigateTo(k_settings_paths.profile);
        }
    };

    useEffect(() => {
        setEditForm({
            id: profileDetails?.id,
            name: profileDetails?.name ?? "",
            email: profileDetails?.email ?? "",
            phone: profileDetails?.phone ?? "",
            address: profileDetails?.address ?? "",
            lng: profileDetails?.lng ?? ""
        });
        setAddAddressLabel(profileDetails?.address ?? "")
        setLet(profileDetails?.lat ?? "")
    }, [profileDetails])

    const handleInputChange = (event) => {
        if (!isDirty) setIsDirty(true);
        const input = event.target.value;
        const sanitizedInput = input.replace(/[^0-9+]/g, '');
        const sanitizedWithSinglePlus = sanitizedInput.replace(/\++/g, '+');
        const sanitizedWithMaxTwoPlus = sanitizedWithSinglePlus.replace(/\+/g, (match, index) => index === 0 ? match : '');
        setEditForm({ ...editForm, phone: sanitizedWithMaxTwoPlus });
    };

    return (
        <form onSubmit={handleCustomerEdit}>
            <div className='flex justify-between'>
                <CommonTitle icon={true} link={k_settings_paths.profile} title={t("Edit Profile")}
                    withConfirm={true} />
                <div className='flex items-center justify-end space-x-4'>
                    <CommonButtonOutlined
                        onClick={() => {
                            const normalClickFunction = () => {
                                setIsDirty(false)
                                setShowProfileEditModal(false)
                                navigateTo(k_settings_paths.profile);
                            }

                            let confirm = false;
                            if (isDirty && !confirm) {
                                confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?')
                            }

                            if (isDirty && confirm) {
                                setIsDirty(false);
                                normalClickFunction();
                            }

                            else if (!isDirty) {
                                normalClickFunction();
                            }

                        }}
                        width={BUTTON_WIDTH}
                        btnLabel={t("Cancel")}
                        colorType='danger'
                    />
                    <CommonButton
                        width={BUTTON_WIDTH}
                        type='submit'
                        btnLabel={t('Update')}
                    />
                </div>
            </div>

            <div className='mt-4'>
                <SquareImageUploader
                    iImage={profileDetails?.image}
                    onChange={(e) => {
                        if (!isDirty) setIsDirty(true);
                        setEditForm({ ...editForm, image: e })
                    }}
                    clearImage={() => { setEditForm({ ...editForm, image: null }) }}
                    rounded='!rounded-full'
                />

                <div className='grid grid-cols-12 gap-6 md:gap-5 2xl:gap-5 mt-s2'>
                    <div className='col-span-8'>
                        <CommonInput
                            value={editForm.name}
                            labelText={t('Name')}
                            type='text'
                            max_input={55}
                            required={true}
                            onChange={(e) => {
                                if (!isDirty) setIsDirty(true);
                                setEditForm({ ...editForm, name: e.target.value });
                            }}
                        />

                        <CommonInput
                            disabled={true}
                            type='email'
                            required={true}
                            value={editForm.email}
                            labelText={t('Email')}
                        />

                        <CommonInput
                            type="tel"
                            labelText={t('Phone')}
                            max_input={15}
                            value={editForm.phone}
                            onChange={handleInputChange}
                        />

                        <AddressAutoComplete
                            label={t("Address")}
                            placeholder={t("Type Address..")}
                            name={"address"}
                            address={addAddressLabel}
                            latName={"lat"}
                            lngName={"lng"}
                            changeValue={handleChange}
                            doSearch={doSearch}
                            setDoSearch={setDoSearch}
                            icon={false}
                            isInvalid={addAddressLabel && (!lat || !editForm?.lng)}
                        />
                    </div>
                </div>
            </div>
        </form>
    )
}

export default EditCustomerProfile