import React, { useEffect, useRef } from 'react'
import onResize from "simple-element-resize-detector";
import useMapStore from '../../app/stores/company/mapStore';
import CommonModal from '../modal/CommonModal';
import LimadiMap from './LimadiMap';
import OpenStreetMap from '../OpenStreetMap/OpenStreetMap';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import { iCrossWhite } from '../../app/utility/imageImports';

export default function MapModal({
  showModal,
  setShowModal,
  markerPoints = [], }) {

  const initZoomLevel = 10, centerLat = 55.747781, centerLng = 12.3043962;

  const { setMap, H, setMarker } = useMapStore()
  const mapRef = useRef(null);

  const platform = new H.service.Platform({ apikey: process.env.REACT_APP_HERE_API_KEY });
  const defaultLayers = platform.createDefaultLayers();


  useEffect(() => {
    setMarker([])
    if (mapRef.current) {
      let map = new H.Map(mapRef.current, defaultLayers.vector.normal.map, {
        center: { lat: centerLat, lng: centerLng },
        zoom: initZoomLevel,
        pixelRatio: window.devicePixelRatio || 1,
      });

      setMap(map);
      onResize(mapRef.current, () => map.getViewPort().resize())
      const ui = H.ui.UI.createDefault(map, defaultLayers);
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map)); // on scroll zoom

      return () => {
        map.dispose();
        ui.dispose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    // <CommonModal
    //   showModal={showModal}
    //   setShowModal={setShowModal}
    //   modalSpace={true}
    //   modalTitle=""
    //   widthClass="w-[1000px]"
    //   closeIconPosition="top-0 right-0"
    //   mainContent={
    //     <div className='h-[500px]'>
    //       {/* <LimadiMap is_on_modal={true} /> */}
    //     </div>
    //   }
    //   />

    <Modal
      open={!!showModal}
      onClose={() => {
        setShowModal(false);
      }}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(255, 255, 255, 1)",
        },
      }}   >

      <ModalDialog
        aria-labelledby="modal-dialog-overflow" layout={'fullscreen'}>
        <button onClick={() => setShowModal(false)} className="absolute top-[26px] right-[26px] z-[8888] rounded-full bg-white p-1.5">
          <img className="w-[12px] h-[12px]" src={iCrossWhite} alt="" />
        </button>
        {showModal &&
          <React.Fragment>
            <OpenStreetMap width={'100%'} height={'100%'} markerPoints={markerPoints} scrollWheelZoom={true} />
          </React.Fragment>
        }
      </ModalDialog>
    </Modal>
  )
}
