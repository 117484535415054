import React from 'react'
import { useNavigate } from 'react-router-dom'
import { iLeftArrow } from '../../app/utility/imageImports'
import CommonReloader from '../reloader/CommonReloader'
import useGeneralStore from '../../app/stores/others/generalStore'

const CommonTopTitleSection = ({
    title = '',
    counter = null,
    rightSideComponent,
    withBackLink = "",
    withReloader = false,
    onReload = () => { },
    PaddingComponent = <> </>
}) => {
    const navigateTo = useNavigate();
    const { setRequestDetailsPath, isDirty, setIsDirty } = useGeneralStore();

    const handleClick = (e) => {
        e.preventDefault();
        let confirm = false
        if (isDirty && !confirm) {
            confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?')
        }

        if (isDirty && confirm) {
            setIsDirty(false);
            setRequestDetailsPath(null)
            navigateTo(withBackLink ?? '/')
        }

        else if (!isDirty) {
            setRequestDetailsPath(null)
            navigateTo(withBackLink ?? '/')
        }

    }

    return (
        <div className='mb-4'>
            <div className="flex items-baseline justify-between bg-white xl:items-center">
                <div className='title flex items-center space-x-2.5'>
                    {withBackLink ? <div className='cursor-pointer' onClick={handleClick}>< img src={iLeftArrow} alt="" /> </div> : ""}
                    <div className='capitalize'>{title}</div>
                    <div>{counter !== null ? `( ${counter} )` : ""}</div>
                    {withReloader ?
                        <div className='p-2 ml-4 rounded-full shadow-sm bg-cBgSideBar'>
                            <CommonReloader onClick={onReload} />
                        </div>
                        : ""}
                </div>
                <div>{rightSideComponent}</div>
            </div>
            {PaddingComponent}
        </div>
    )
}

export default CommonTopTitleSection