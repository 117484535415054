/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore from "../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import { user_role as role } from "../../../../../../../app/utility/const";
import { iEdit } from "../../../../../../../app/utility/imageImports";
import { addMinutesToTime, checkForCurrentDate, formatDate, formatDateToApiDate, getStringFromDateObject, isFutureDateTime, isPastTime, isStartDateValid, isWhitespaceOnly, removeSecFromDeliveryTime } from "../../../../../../../app/utility/utilityFunctions";

import { Tooltip } from "antd";
import { k_create_request_paths } from "../../../../../../../app/utility/AllRoute";
import Dropdown from "../../../../../../../components/dropdown/Dropdown";
import MassImportAddressAutoComplete from "../../../../../../../components/input/MassImportAddressAutoComplete";
import MassImportDatePicker from "../../../../../../../components/input/MassImportDatePicker";
import TimePickerNew from "../../../../../../../components/input/TimePickerNew";
import { InputField } from "./InputField";
import { TableViewElement } from "./TableViewElement";
import {
  address_width,
  comment_width,
  date_width,
  floor_width,
  index_width,
  product_width,
  time_width,
} from "./tableViewFildWidth";
import CancelImageBtn from "../../../../../../../components/image/CancelImageBtn";

export default function StopRow({
  transformedArray,
  data,
  index,
  productArray,
}) {
  const {
    tableCurrentPage,
    setTableCurrentPage,
    cr_form,
    table_cr_form,
    setTableCrFullForm,
    updateTableStopsForm,
    resetProduct,
    setResetProduct,
    setShowEditDeliveryModal,
    setDeliverySelectIndex,
    setSelectStop,
    addProduct,
    setAddProduct,
    table_view_invalid_index,
    updateCrField,
    addDeliveryTable,
  } = useCreateRequestStore();

  const { user_role, setIsDirty, isDirty, isMassUploadDirty, setIsMassUploadDirty } = useGeneralStore();

  const [product, setProduct] = useState(productArray);
  const [validStopDate, setValidStopDate] = useState(true);

  const [doSearch, setDoSearch] = useState(false);

  let curr_hour = (new Date()).getHours();
  let curr_min = (new Date()).getMinutes();

  const setDirtyMode = ( ) => {
    if(!isDirty) setIsDirty(true)
    if(!isMassUploadDirty) setIsMassUploadDirty(true)
  }

  const deleteRow = async (index) => {
    console.log("deleteRow: ", index);
    const updatedStops = table_cr_form.filter(
      (item, i) => i !== index
    );
    await setTableCrFullForm(updatedStops);
    addDeliveryTable();
  }

  useEffect(() => {
    setProduct(productArray);
    console.log("setProduct INDEX AND PROD_ARRAY PROD LOGIC");

    return;
  }, [index, productArray]); // [productArray]

  useEffect(() => {
    if (resetProduct) {
      setProduct("");
      setResetProduct(false);
    }
    return;
  }, [resetProduct]);

  useEffect(() => {
    if (addProduct && table_cr_form?.length > 0) {
      if (table_cr_form?.length > 1) {
        console.log("setProduct ADD PROD LOGIC 1");
        setProduct(table_cr_form[index].products?.map((item) => item.text ? item?.text : "Product").join(", "));
      }
      else {
        console.log("setProduct ADD PROD LOGIC 2");
        setProduct(table_cr_form[index].products?.map((item) => item.text).join(", "));
      }
      setAddProduct(false);
    }
    return;
  }, [addProduct]);

  useEffect(() => {
    if (!product && data?.products?.length > 0) {
      setProduct(data?.products?.map((item) => item.text).join(", "));
      console.log("setProduct ADDRESS CHECK LOGIC");

      // updateTableStopsForm(index, "products", data?.products);
    }
  }, [data?.address]);

  const productItem = cr_form?.deliveries?.length > 0 && cr_form?.deliveries[index]?.products?.map((i) => { return i?.id && i?.id })

  const productChangeHandler = (value = "") => {
    console.log("productChangeHandler: ", value);
    setDirtyMode()

    if (window.location.pathname === k_create_request_paths.create) {
      const pd = value?.split(",");
      const updatedProducts = pd?.filter((item) => item?.trim() !== "")?.map((item) => ({ text: item.trim() }));
      console.log("updatedProducts: ", updatedProducts);

      updateTableStopsForm(index, "products", updatedProducts);
    } else {
      // if (table_cr_form?.[index]?.address) {
      if (!!productItem && productItem?.length > 0 && productItem?.[0] !== undefined) {
        console.log("setting productItem: ", productItem);

        updateTableStopsForm(index, "removed_products_ids", productItem !== undefined ? productItem : []);
      }

      const pd = value?.split(",");
      const updatedProducts = pd?.filter((item) => item?.trim() !== "")?.map((item) => ({ text: item.trim() }));
      updateTableStopsForm(index, "products", updatedProducts);
      // }
    }
  }

  const isTimeValidationRequired = (data) => {

    if (cr_form?.pickup?.date) {
      const pickupDate = new Date(cr_form?.pickup?.date);
      const deliveryDate = new Date(data?.date);
      const isFutureDeliveryDate = deliveryDate > pickupDate;

      if (deliveryDate?.getTime() == pickupDate?.getTime()) {
        return !!cr_form?.pickup?.start_time;
      }

      if (!isFutureDeliveryDate) {
        return true;
      } else {
        return false;
      }
    }
    if (data?.date) {
      console.log("isTimeValidationRequired: ", data?.date, isFutureDateTime(data?.date, true));

      if (!isFutureDateTime(data?.date, true)) return true;
      else return false;
    }

  }

  const isValidStartTime = () => {
    if (data?.date && !data?.start_time) {
      return false;
    } else if (!isFutureDateTime(data?.date, true) && isPastTime(data?.start_time, data?.date)) {
      return false;
    } else {
      return true;
    }
  }



  const { t } = useTranslation();

  const onTimeSelect = (time, endTime) => {
    setDirtyMode()
    updateTableStopsForm(index, 'start_time', time);
    updateTableStopsForm(index, 'end_time', endTime);
  }

  let comparableDate = cr_form?.pickup?.date ? new Date(cr_form?.pickup?.date) : new Date();
  // Subtract one day from the current date
  comparableDate.setDate(comparableDate.getDate() - 1);

  // const isValidDate = data?.date ? new Date(data.date) !== "Invalid Date" && new Date(data.date) > comparableDate : true;

  const isValidStopDate = () => {
    if (!data?.date && !data?.start_time) {
      // console.log("LOGIC date field empty");
      return true;
    }

    if (cr_form?.pickup?.date) {
      // console.log("LOGIC pickup start date time");
      if (cr_form?.pickup?.start_time && cr_form?.pickup?.end_time) {
        let startDateTimeString = `${cr_form?.pickup?.date}T${cr_form?.pickup?.start_time}:00`;
        let endDateTimeString;
        if (data?.start_time) endDateTimeString = `${data?.date}T${data?.start_time}:00`;
        else endDateTimeString = `${data?.date}T00:00:00`;
        return isStartDateValid(startDateTimeString, endDateTimeString);
      } else return isStartDateValid(cr_form?.pickup?.date, data?.date);
    }
    else if (data?.date && data?.start_time) {
      // console.log("LOGIC present date time");
      let dateTimeString = `${data?.date}T${data?.start_time}:00`;
      // console.log("PRESENT date time LOGIC RESULT::::", isFutureDateTime(dateTimeString, true));

      return isFutureDateTime(dateTimeString, true);
    }
    else if (!data?.date && data?.start_time) {
      return false;
    }
    else if (data?.date) {
      // console.log("LOGIC present DATE ONLY");
      let dateTimeString = `${data?.date}T00:00:00`;
      // console.log("PRESENT date LOGIC RESULT::::", isFutureDateTime(dateTimeString, true));

      return isFutureDateTime(dateTimeString, true);
    }

    else {
      // console.log("DEFAULT LOGIC TRUE");

      return true;
    }
  }

  useEffect(() => {
    setValidStopDate(isValidStopDate());
  }, [data]);

  const isValidAddressField = () => {
    if (table_cr_form?.[index]?.address !== "") { //note: if the address field has a value....
      if (data?.lat && data?.lng) {
        return true;
      } else if (data?.address_lat && data?.address_lng) {
        return true;
      } else {
        return false;
      }
    } else {
      if (!table_cr_form?.[index]?.address && isCrossVisible) {
        return false;
      } else if (!table_cr_form?.[index]?.address && !!product) {
        return false;
      }
      else {
        return true;
      }
    }
  };


  const parseDate = (dateString) => {
    const parsedDate = new Date(dateString);

    // Check if the date is valid
    if (isNaN(parsedDate)) {
      return false; // Handle invalid date format
    }

    return true;
  };

  const invalidDateFormat = !parseDate(data.date);
  if (invalidDateFormat) {
    updateTableStopsForm(index, "date", "");
  }

  const isCrossVisible =
    !!data.date ||
    !!data.start_time ||
    !!data?.duration ||
    !!data.end_time ||
    !!data.delivery_time ||
    !!data.floor_number ||
    !!data?.address ||
    !!data.comment ||
    !!data.attachment ||
    data?.products.length > 1 ||
    !!data.products.filter((item) => item.text).length;

  // useEffect(() => {
  //   if (table_cr_form[index]?.address) {
  //     if (isCrossVisible && data?.products?.length === 0) {
  //       // updateTableStopsForm(index, "products", [{ text: "Product" }]);
  //     }
  //   } else {
  //     if (data?.products?.length === 1 && data?.products[0]?.text === "Product"
  //       && !(!!data.date ||
  //         !!data.start_time ||
  //         !!data?.duration ||
  //         !!data.end_time ||
  //         !!data.delivery_time ||
  //         !!data.floor_number ||
  //         !!data?.address ||
  //         !!data.comment ||
  //         !!data.attachment)
  //     ) {
  //       // updateTableStopsForm(index, "products", []);
  //       // setProduct("");
  //     }
  //   }
  // }, [data]);

  return (
    <div
      onClick={() => {
        console.log("table_cr_form: ", table_cr_form);
        console.log("data: ", data);
      }}
      className="flex flex-row items-center space-x-2 rounded text-fs14 font-fw500"
    >
      <div
        className={`${index_width} text-center table-info flex justify-center items-center`}
      >
        {(index + 1).toString().padStart(2, "0")}
      </div>

      <div
        className={`${address_width} `}
      >

        <MassImportAddressAutoComplete
          width={"min-w-[200px]"}
          name={'address'}
          address={table_cr_form?.[index]?.address}
          latName={'lat'}
          lngName={'lng'}
          changeValue={(name, value) => {
            setDirtyMode()
            if (name === "address") {
              updateTableStopsForm(index, 'address', value?.toString());
            } else if (name === "lat") {
              // console.log("LAT:", value);

              updateTableStopsForm(index, 'lat', value ? value?.toString() : null);
            } else if (name === "lng") {
              // console.log("LNG:", value);

              updateTableStopsForm(index, 'lng', value ? value?.toString() : null);
            }
          }}
          index={index}
          doSearch={doSearch}
          setDoSearch={setDoSearch}

          is_valid={isValidAddressField()}
        />
      </div>
      <div className={`${floor_width}`}>
        <InputField
          is_textarea={true}
          name={"floor_number"}
          value={data?.floor_number ?? ""}
          onChange={(e) => {
            setDirtyMode()
            updateTableStopsForm(index, "floor_number", e.target.value);
          }}
          width={floor_width}
          maxLength={20}
        />
      </div>
      {user_role === role.company && (
        <>
          <div className={`${date_width}`}>

            <MassImportDatePicker
              value={data?.date ?? ""}
              startDate={cr_form?.pickup?.date ? new Date(cr_form?.pickup?.date) : new Date()}
              shouldUpdateValue={true}
              onChange={(date) => {
                // console.log("cr_form : ", cr_form);
                setDirtyMode()
                updateTableStopsForm(index, "formatted_date", getStringFromDateObject(date));
                updateTableStopsForm(index, "date", formatDateToApiDate(date));

                updateTableStopsForm(index, 'start_time', "");
                updateTableStopsForm(index, 'end_time', "");
              }}
              button={
                <div className="cursor-pointer">
                  <TableViewElement
                    width={date_width}

                    // is_valid={(data?.start_time && !data?.date) ? false : (data?.date && !isFutureDateTime(data?.date, true)) ? false : (data?.date && !isStartDateValid(cr_form?.pickup?.date, data?.date)) ? false : true}
                    is_valid={validStopDate}
                    description={data?.date === null || invalidDateFormat ? "" : formatDate(data?.date)}
                  />
                </div>}
            />
          </div>

          <div className={`${time_width}`}>
            <Dropdown
              button={
                <TableViewElement
                  description={
                    data?.start_time
                      ? `${data?.start_time}`
                      : ""
                  }
                  is_valid={isValidStartTime()}
                // is_valid={(data?.date && !data?.start_time) ? false : !isFutureDateTime(data?.date, true) && isPastTime(data?.start_time) ? false : true}
                />}
              body={
                <TimePickerNew
                  selectAction={onTimeSelect}
                  size="small"
                  acceptSmallEndTime={true}

                  init_date={(data?.date) ? data?.date : cr_form?.pickup?.date ? cr_form?.pickup?.date : new Date()}
                  isCustomMinTimeValidation={isTimeValidationRequired(data)}
                  isCurrentTimeValidation={data?.date && checkForCurrentDate(data?.date)}
                  customMinTimeValue={cr_form?.pickup?.start_time ? addMinutesToTime(cr_form?.pickup?.start_time, 15) : curr_hour + ":" + curr_min}

                  init_time={data?.start_time === null ? '' : removeSecFromDeliveryTime(data?.start_time)}
                  endTime={data?.end_time === null ? '' : removeSecFromDeliveryTime(data?.end_time)}
                />}
            />
          </div>
        </>
      )}

      <div className={`${product_width}`}>
        <InputField
          name={'product'}
          // value={productArray?.length === 1 && productArray[0] === "Product" ? "" : product ?? ""}
          value={product}
          onChange={async (e) => {
            productChangeHandler(e.target.value);
            setProduct(e.target.value);
          }}
          width={product_width}
          // is_valid={data?.address ? !product ? false : true : true}
          is_textarea={true}

        />
      </div>

      <div className={`${comment_width}`}>
        <InputField
          is_textarea={true}
          width={comment_width}
          name={"comment"}
          value={isWhitespaceOnly(data?.comment) ? "" : data?.comment ?? ""}
          maxLength={255}
          onChange={(e) => {
            setDirtyMode()
            updateTableStopsForm(index, "comment", e.target.value);
          }}
          rows={1}
          type="text"
        />
      </div>
      <div className="flex items-center space-x-1">
        <div
          onClick={() => {
            setDeliverySelectIndex(index);
            setSelectStop(table_cr_form);
            setShowEditDeliveryModal(true);
          }}
          className="p-2 rounded-full cursor-pointer hover:bg-cButtonHoverColor"
        >
          <img
            className="cursor-pointer max-w-[20px] min-w-[20px] h-[20px]"
            src={iEdit}
            alt=""
          />
        </div>

        <Tooltip
          color={"#242826"}
          title={isCrossVisible ? t("Delete Row") : ""}
          className={`${isCrossVisible ? "" : "cursor-not-allowed"} p-0`}
        >
          <div
            onClick={async () => {
              setDirtyMode()
              if (table_cr_form?.length < 31) {
                deleteRow(index);
              } else {

                let updatedRemovedProductsIds = [];
                if (cr_form?.removed_stops_ids?.length) {
                  updatedRemovedProductsIds = [...cr_form?.removed_stops_ids, data?.id];
                } else updatedRemovedProductsIds.push(data?.id);
                const isValidArray = Array.isArray(updatedRemovedProductsIds) &&
                  updatedRemovedProductsIds.every(id => Number.isInteger(id));

                updateCrField("removed_stops_ids", isValidArray ? updatedRemovedProductsIds : []);

                if (transformedArray < 2) {
                  setTableCurrentPage(tableCurrentPage - 1);
                }
                if (table_cr_form?.length < 31) {
                  setProduct("");
                  const updatedArray = table_cr_form?.map(
                    (item, ItemIndex) => {
                      if (ItemIndex === index) {
                        return {
                          date: "",
                          start_time: "",
                          end_time: "",
                          delivery_time: "",
                          address: "",
                          lat: "",
                          lng: "",
                          comment: "",
                          attachment: "",
                          attachment_url: "",
                          products: [],
                          removed_products_ids: [],
                          floor_number: "",
                          duration: "",
                        };
                      } else {
                        return item;
                      }
                    }
                  );

                  setTableCrFullForm(updatedArray);
                } else {
                  // setProduct("");
                  const updatedStops = table_cr_form.filter(
                    (item, i) => i !== index
                  );
                  await setTableCrFullForm(updatedStops);
                }
              }
            }}
            className="flex items-center justify-end p-2 rounded-full cursor-pointer hover:bg-cButtonHoverColor"
          >
            <CancelImageBtn hoverSize="w-9 h-9"/>
          </div>
        </Tooltip>
      </div>
    </div>
  );
}





