import React, { } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteRequest } from '../../../../../../../../app/stores/others/requestStore';
import CommonButton from '../../../../../../../../components/button/CommonButton';
import CommonModal from '../../../../../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import useCreateRequestStore from '../../../../../../../../app/stores/others/createRequestStore';
import { create_request_steps } from '../../../../../../../../app/utility/const';
import useGeneralStore from '../../../../../../../../app/stores/others/generalStore';

const RequestDeleteConfirmModal = ({ navigate = "", request_id, showModal, setShowModal }) => {

  const navigateTo = useNavigate();
  const { setIsDirty } = useGeneralStore();

  const { t } = useTranslation();

  const { type } = useParams();

  const {
    resetCreateRequest,
    setCurrentSetup,
    setExpandedAccordion,
    resetStopData,
    setSelectedCompanies,
    setFavSelected,
    setRate,
    setSearchCompanyKey,
    setCrFormSelectedShift
  } = useCreateRequestStore();

  return (
    <>
      <CommonModal
        showModal={showModal}
        setShowModal={setShowModal}
        modalTitle={t("Delete Request")}
        widthClass="w-[50vw]"
        mainContent={
          <>
            <div className='mt-s20 '>{t("Are you sure you want to delete this request?")}</div>
            <div className='mt-1'>{t("If you delete any request, you can't get it back in the system.")}</div>

            <div className='flex justify-end mt-s20'>

              <CommonButton onClick={async () => {
                const success = await deleteRequest(request_id);
                if (success) {
                  setIsDirty(false);
                  setSelectedCompanies([]);
                  setFavSelected(false);
                  setSearchCompanyKey("");
                  setRate();
                  resetCreateRequest();
                  resetStopData();
                  setShowModal(false);
                  setCrFormSelectedShift(null)
                }
                if (navigate) {
                  navigateTo(navigate);
                } else {
                  navigateTo(`/requests/${type}`);
                }
                setExpandedAccordion(null)

                setCurrentSetup(create_request_steps.pickup);
              }}
                btnLabel={t('Delete')}
                colorType='danger'
                width='w-[100px]'
              />

            </div>
          </>
        }
      />
    </>
  );
};

export default RequestDeleteConfirmModal;