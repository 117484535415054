/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import { useRequestDetailsPathChecks } from "../../../../../../../app/hooks/useRequestDetailsPathChecks";
import useCreateRequestStore from "../../../../../../../app/stores/others/createRequestStore";
import useRequestStore, {
  checkIsCustomerProfileShow,
  getCustomerName
} from "../../../../../../../app/stores/others/requestStore";
import {
  create_request_steps,
  request_details_types,
  user_role as role
} from "../../../../../../../app/utility/const";
import RequestAccordion from "../../../../../../../components/Accordion/RequestAccordion";
import CommonInput from "../../../../../../../components/input/CommonInput";
import CommonCompanyDetailsModal from "../../../../../../../components/modal/CommonCompanyDetailsModal";
import CommonViewComponent from "../../../../../../../components/viewer/CommonViewComponent";
import CustomerProfile from "../../mainContents/components/deliveries/components/CustomerProfile";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";

export default function ProposalInfo() {
  const {
    place_bid_form,
    setPlaceBidForm,
    setPlaceBidChange,
    request_details,
    resetPlaceBidForm,
    setGlobalReqMode,
    fetchReqDetails
  } = useRequestStore();
  const { setShowCustomerDetailsModal, user_role, isDirty, setIsDirty } = useGeneralStore();

  const location = useLocation();
  const { t } = useTranslation();
  const params = useParams();
  const { request_id, type } = params;

  const [showCompanyDetailsModal, setShowCompanyDetailsModal] = useState(false);
  const [customerName, setCustomerName] = useState("");

  useEffect(() => {
    if(location?.state?.is_fetch_req_details === false && fetchReqDetails === false) return;
    resetPlaceBidForm();
    if (
      window.location.pathname ===
      "/global-request/details/" + request_id + "/placeBid"
    ) {
      setPlaceBidChange({ ...place_bid_form, customer_name: "Global" });
    } else {
      setPlaceBidChange({
        ...place_bid_form,
        customer_name: request_details?.user?.name,
      });
    }
  }, [request_details, window.location.pathname]);

  useEffect(() => {
    if (location.pathname === "/global-request/details/" + request_id) setCustomerName("");
    else if (request_details?.id != request_id) setCustomerName("");
    else setCustomerName(getCustomerName(request_details));
  }, [request_details?.id, request_id])

  useEffect(() => {
    if (window.location.pathname === "/global-request/details/" + request_id)
      setGlobalReqMode(true);
    else setGlobalReqMode(false);
  }, [window.location.pathname]);

  const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();
  const { isEditPath } = useRequestDetailsPathChecks(type, request_id);

  const hideGlobalCustomerDetails = () => {
    if( type === request_details_types.in_bidding || type === request_details_types?.invitation ) {
      return request_details?.is_global && !request_details?.is_from_invite
    }
    else if(location?.pathname?.includes("/global-request/details")) {
      return request_details?.is_global;
    }
  }

  const getSubtitleOne = () => {
    if(type === request_details_types.invitation || type === request_details_types.in_bidding || location.pathname.includes("/global-request/details")){
      return place_bid_form?.budget ? "DKK " + parseInt(place_bid_form?.budget)?.toLocaleString("da-Dk") : ""
    }
    else if(request_details?.my_bid?.budget) {
      return "DKK " + parseInt(request_details?.my_bid?.budget)?.toLocaleString("da-DK")
    }
    else return ""
  }

  const getSubtitleTwo = () => {
    if(location.pathname.includes("global-request/details")) {
      return "Global"
    }
    else if(type === request_details_types.invitation || type === request_details_types.in_bidding) {
      return request_details?.is_global && !request_details?.is_from_invite ? "Global" : request_details?.user?.name
    }
    return (request_details?.user?.user_role !== "company" ? request_details?.user?.name : request_details?.my_bid?.customer_name ?? "Created by company")
  }

  return (
    <>
      <RequestAccordion
        showClearButton={window.location.pathname.includes("/global-request/details") && (place_bid_form?.budget || place_bid_form?.description) ? true : false}
        clearHandler={() => {
          let budgetEvent = {
            target: {
              name: "budget",
              value: "",
            },
          }
          setPlaceBidForm(budgetEvent);

          let descriptionEvent = {
            target: {
              name: "description",
              value: "",
            },
          }
          setPlaceBidForm(descriptionEvent);
        }}
        title={t("Proposal Info")}
        isTable={true}
        subTitleOne={getSubtitleOne()}
        subTitleTwo={getSubtitleTwo()}
        isExpanded={expandedAccordion === create_request_steps.proposal}
        onExpand={() => {
          setExpandedAccordion(
            expandedAccordion === create_request_steps.proposal
              ? null
              : create_request_steps.proposal
          );
        }}
      >
        <>
          {type === request_details_types?.invitation ||
            (type === request_details_types?.in_bidding && isEditPath) || window.location.pathname.includes("/global-request/details") ? (
            <>
              <div className="grid grid-cols-2 gap-x-6">
                <CommonInput
                  required={true}
                  name={"budget"}
                  value={place_bid_form?.budget}
                  onChange={(e) => {
                    if(!isDirty) setIsDirty(true);
                    setPlaceBidForm(e)
                  }}
                  labelText={t("Amount")}
                  className={"mb-2 mt-0"}
                  type={"number"}
                  max_input={8}
                  show_asterisk={true}
                />

                {
                  !window.location.pathname.includes("/global-request/details")
                  && type !== request_details_types.invitation
                  && type !== request_details_types.in_bidding
                  &&
                  <CommonInput
                    disabled={type === request_details_types.invitation}
                    show_asterisk={false}
                    required={true}
                    className={"mb-2 mt-0"}
                    labelText={t("Customer")}
                    type="text"
                    name={"customer_name"}
                    value={place_bid_form?.customer_name ?? "Created by company"}
                    onChange={(e) =>{
                      if(!isDirty) setIsDirty(true);
                      setPlaceBidForm(e)
                    }}
                  />}
              </div>

              <CommonInput
                required={true}
                className={"mt-2"}
                name={"description"}
                value={place_bid_form?.description}
                onChange={(e) => {
                  console.log(e.target.value)
                  if(!isDirty) setIsDirty(true);
                  setPlaceBidChange({
                    ...place_bid_form,
                    description: e.target.value,
                  });
                }}
                labelText={t("Bidding Description")}
                textarea={true}
                type={"text"}
                max_input={255}
                show_asterisk={true}
              />
            </>
          ) : (
            <div className="border-[1px] rounded border-cGray300 p-4">
              <CommonCompanyDetailsModal showModal={showCompanyDetailsModal} setShowModal={setShowCompanyDetailsModal} company_id={request_details?.user?.id} bidding_details={false} />
              <div className="space-y-2">
                <CommonViewComponent
                  labelText={t("Budget")}
                  value={
                    request_details?.my_bid?.budget
                      ? `DKK ${request_details?.my_bid?.budget?.toLocaleString(
                        "da-DK"
                      )}`
                      : "No budget"
                  }
                />


                {((type === "saved" || type === "awarded" || type === "on-going" || type === "completed" || type === "history") && (request_details?.user?.user_role === "company")) ?
                  <CommonViewComponent
                    labelText={t("Customer")}
                    value={request_details?.user?.user_role !== "company" ? request_details?.user?.name : request_details?.my_bid?.customer_name ?? "Created by company"}
                  /> :
                  <></>
                }

                <CommonViewComponent
                  labelText={t("Bidding Description")}
                  value={request_details?.my_bid?.details ?? "No description"}
                />


              </div>
            </div>
          )}
          <div
            onClick={() => {

              if (!(hideGlobalCustomerDetails())) {
                if (request_details?.user?.user_role !== "company") return console.log("This is not a company.");                  //todo: setShowCustomerDetailsModal(true);
                setShowCompanyDetailsModal(true);
              }

            }}
            className={`${type === request_details_types?.invitation ||
              (type === request_details_types?.in_bidding && isEditPath) || window.location.pathname.includes("/global-request/details") ? "mt-9" : "mt-5"} ${user_role === role.customer ? "cursor-pointer" : "cursor-default"}`}>
            {(checkIsCustomerProfileShow(type) ||
              request_details?.is_global) && request_details?.user?.user_role !== "company" ? <CustomerProfile data={request_details?.user} isGlobal={hideGlobalCustomerDetails()} /> : <></>
            }
          </div>
        </>
      </RequestAccordion>
    </>
  );
}
