import React from 'react';
import Image from '../image/Image';
import RatingFiveStar from '../rating/RatingFiveStar';
import { iFavoriteIcon } from '../../app/utility/imageImports'
import CommonCheckbox from '../input/CommonCheckbox';

const CompanyListItem = ({
    thickBordered = false,
    withCheckbox = true,
    withRadioButton = false,
    image = "",
    dummyImage = "",
    selected = false,
    title = "ONE",
    onClick = () => { },
    rating = 2,
    is_favorite = false,
    topRightComponent = <></>,
    onActionBtnClick = () => { },

}) => {

    return (
        <div
            onClick={(e) => { e.stopPropagation(); onClick(); }}
            className={` ${withRadioButton ? "max-w-[320px] min-w-[320px]" : ""}  flex items-center space-x-2 p-3 rounded-br5 cursor-pointer transition-all
                duration-300 ease-in-out relative bg-white border-cGray200
                ${thickBordered ? "border-[1px]" : "border-[1px]"}  overflow-hidden`}>

            {
                withCheckbox ? <div onClick={(e) => { e.stopPropagation(); onActionBtnClick(); }}>
                    <CommonCheckbox
                        checked={selected}
                    />
                </div>
                    :
                    ""
            }

            <div className='flex items-center w-full cursor-pointer'>
                <div className={`flex items-center justify-center rounded-full border border-cGray300 transition-all duration-300 ease-in-out overflow-hidden min-w-[90px] min-h-[90px]`}>
                    <Image src={image} dummyImage={dummyImage} alt="" className='w-[90px] h-[90px]' />
                </div>

                {/* b  main titles and other texts */}
                <div className={` ${withRadioButton ? "max-w-[170px]" : ""} ml-2 space-y-2 w-full`}>
                    <div title={title} className={`text-left font-medium capitalize truncate text-cMainBlack`}>{title}</div>

                    {/*g rating component */}
                    <div className='flex flex-row items-center justify-between w-full space-x-3'>
                        <div className={`duration-300 ease-in-out mt-s0`}>
                            <RatingFiveStar rating={rating} />
                        </div>
                    </div>

                    <div className='flex items-start justify-start w-full'>
                        {is_favorite ? <img src={iFavoriteIcon} alt="" /> : <></>}
                    </div>

                </div>
            </div>

            {
                topRightComponent ?
                    <div className="flex items-center justify-end w-full ">
                        {topRightComponent}
                    </div>
                    :
                    ""
            }

        </div>
    )
}

export default CompanyListItem


