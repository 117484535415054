import React, { useEffect, useRef, useState } from 'react';
import { iClockGray } from '../../app/utility/imageImports';
import FreeDropDown from '../dropdown/FreeDropDown';
import TimePickerNew from './TimePickerNew';
import { Tooltip } from 'antd'
import CancelImageBtn from '../image/CancelImageBtn';

const CommonTimePicker = ({
    selectAction = () => { },
    onChange = () => { },
    disabled = false,
    endTime,
    init_time,
    init_date,
    label = "Select Time",
    showExtendedTimeUi = true,
    required,
    heightClass = "h-s48",
    show_asterisk = true,
    showClear = false,
    handleClear = () => { },
    clearIconTooltipText = "",
    marginClass = "",
    isInvalid = false,
    onBlurEvent = () => { },
    isCurrentTimeValidation = false,
    isCustomMinTimeValidation = false,
    customMinTimeValue = null,
    position = ""
}) => {
    const [value, setValue] = useState('');
    const [showingTimePickerUi, setShowingTimePickerUi] = useState(false);
    const [hovered, setHovered] = useState(false)
    const hiddenRef = useRef()

    useEffect(() => {
        setValue(init_time ?? '');
    }, [init_time]);

    return (
        <div onClick={(e) => { if (disabled) e.stopPropagation() }} className={`relative w-full ${heightClass} outline-none ${marginClass} ${disabled ? "opacity-50" : ""}`}>
            <input type="hidden" name="hidden input"/>
            <div className={`outline-visible outline-hidden absolute outline-none  bg-white ${heightClass} w-full z-10`}  id='hidden-input' ref={hiddenRef}>
                <FreeDropDown
                    onUiUpdate={(data) => setShowingTimePickerUi(data)}
                    width={500}
                    position={position}
                    body={
                        !disabled ?
                            <TimePickerNew
                                customMinTimeValue={customMinTimeValue}
                                isCurrentTimeValidation={isCurrentTimeValidation}
                                isCustomMinTimeValidation={isCustomMinTimeValidation}
                                endTime={endTime}
                                init_time={value ? value : init_time}
                                init_date={init_date}
                                showExtendedTimeUi={showExtendedTimeUi}
                                selectAction={(e, f) => {
                                    setValue(e);
                                    selectAction(e, f);
                                    onChange(e, f);
                                }}
                            /> : <></>
                    }
                    button={
                        <div
                            className={`border-b-[1px]  pb-[1px] ${disabled ? "cursor-not-allowed" : "hover:border-b-[#949494] hover:border-b-2 hover:pb-0 cursor-pointer"} font-normal outline-none mt-[10px] h-[26px] w-full flex justify-between items-center  relative
                                ${isInvalid ? 'border-b-[#F0554B]' : showingTimePickerUi && !disabled ? 'border-b-[#BCC2BF] border-b-2' : 'border-b-[#949494]'}
                            `}
                            onMouseEnter={() => {
                                if(value){
                                    setHovered(true);
                                }
                            }}
                            onMouseLeave={() => {
                                if(value){
                                    setHovered(false);
                                }
                            }}
                            >

                            <div
                                className={`
                                absolute left-0 transition-all ease-in-out duration-300 text-cSideBarNormalText capitalize
                                ${value !== '' ? '-top-4 text-xs font-normal ' : 'top-0 text-base font-normal'}`}>
                                {(required && show_asterisk) ? <div className="">{label}<span className={`text-cSideBarNormalText ml-s2`}>*</span> </div> : label}</div>


                            <div className='text-sm text-black font-normal'>{value ?? ""}</div>
                            <div className='flex space-x-1 items-center'>
                                {
                                    showClear && init_time && hovered && <Tooltip title={clearIconTooltipText} color={"#242826"} >
                                        <CancelImageBtn 
                                            onClick={
                                                async (e) => {
                                                    if(!showingTimePickerUi) {e.stopPropagation()}
                                                    setValue("");
                                                    hiddenRef?.current.click();
                                                    await handleClear()
                                            }}
                                        />
                                    </Tooltip>
                                }
                                <img className={`w-5 h-5 ${disabled ? "cursor-not-allowed" : "cursor-pointer"}`} src={iClockGray} alt="" />
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default CommonTimePicker