import * as React from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";

function usePrompt(message, { beforeUnload } = {}) {

    // Warn before unloading the page (browser close, refresh)
    useBeforeUnload(
        React.useCallback(
            (event) => {
                if (beforeUnload && message) {
                    event.preventDefault();
                }
            },
            [message, beforeUnload]
        )
    );
}

function Prompt({ when, message, ...props }) {
    // Only use usePrompt when the when condition is true
    usePrompt(when ? message : null, props);
    return null;
}

export default Prompt;