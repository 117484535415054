/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import useCreateRequestStore from "../../../../../app/stores/others/createRequestStore";
import {
  all_shift_path,
  create_request_steps,
  global_request_path,
  k_request_paths,
} from "../../../../../app/utility/const";
import Actions from "./components/Actions";
import { useTranslation } from "react-i18next";
import BackLink from "../../../../../components/backLink/BackLink";
import CommonTitle from "../../../../../components/title/CommonTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableViewHeader from "./components/TableViewHeader";
import { k_create_request_paths } from "../../../../../app/utility/AllRoute";
import { useEffect } from "react";
import useRequestStore from "../../../../../app/stores/others/requestStore";

export default function TitleAndActions() {

  const { cr_form, current_step, setCurrentSetup } = useCreateRequestStore();
  const { setFetchReqDetails } = useRequestStore()
  const { t } = useTranslation();
  const params = useParams();
  const { request_id, shift_id } = params;
  const location = useLocation();
  const navigateTo = useNavigate();

  const [isTableView, setIsTableView] = useState(false);

  useEffect(() => {
    if ((location?.pathname === k_create_request_paths.shift_details + shift_id) && !cr_form?.pickup?.date && !location?.state?.referrer) {
      navigateTo(k_create_request_paths.create)
    }
  }, [])

  const getReqTypeLabelFromReferrer = (referrer) => {
    if (referrer === "awarded") {
      return t("Upcoming");
    }
    if (referrer === "global-request") {
      return t("Global Request");
    }
    if (referrer === "not-planned") {
      return t("Not Planned");
    }
    if (referrer === "in-bidding") {
      return t("In Bidding");
    }
    return referrer;
  };

  const getLinksArray = () => {
    if (location?.state?.referrer) {
      return [
        {
          label: getReqTypeLabelFromReferrer(location?.state?.referrer),
          isActive: false,
          linkTo: location?.state?.referrer === "global-request" ? global_request_path : `/requests/${location?.state?.referrer}`,
          cursorPointer: true,
          onClick: () => { setCurrentSetup(""); navigateTo(k_create_request_paths.create) },
          checkDirtyFunction: true
        },
        {
          label: t("Request Details"),
          isActive: false,
          linkTo: location?.state?.referrer === "global-request" ? `/global-request/details/${request_id ?? location?.state?.request_id}` : `/requests/${location?.state?.referrer}/details/${request_id ?? location?.state?.request_id}`,
          cursorPointer: true,
          onClick: () => { setCurrentSetup(""); navigateTo(k_create_request_paths.create); setFetchReqDetails(location?.state?.referrer !== "invitation" && location?.state?.referrer !== "global-request" && !location?.pathname?.includes("/global-request")) },
          state: {...location.pathname.state, is_fetch_req_details: false, isAllShiftExists: false},
          checkDirtyFunction: true
        },
        {
          label: (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded" || location?.state?.referrer === "not-planned") ? location?.state?.isEditMode ? t("Edit")
            : location.pathname?.includes(`/request/edit/`) ? t("Edit") : ""
            : location?.state?.isEditMode ? t("Edit")
              : "",
          isActive: (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded" || location?.state?.referrer === "not-planned") && (location.pathname?.includes(`/request/create/shift/details/`) || location?.pathname?.includes("/request/create/all-shift")) ? false : true,
          linkTo: (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") ? `/request/edit/${request_id ?? location?.state?.request_id}` : `/requests/${location?.state?.referrer}/details/${request_id ?? location?.state?.request_id}/edit`,
          cursorPointer: (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") && location.pathname?.includes(`/request/create/shift/details/`) ? true : false,
          onClick: () => {
            // setCurrentSetup("");
            setCurrentSetup("");
            setFetchReqDetails(false)
            if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") navigateTo(`/request/edit/${request_id ?? location?.state?.request_id}`, { state: location?.state })
          },
          state: { ...location?.state, isAllShiftExists: false, is_fetch_req_details: false }
        },

        {
          label: (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded" || location?.state?.referrer === "global-request" || location?.state?.referrer === "invitation" || location?.state?.referrer === "not-planned" || location?.state?.referrer === "in-bidding") && location.pathname?.includes(`/request/create/all-shift`) ? "All Shifts" : location?.state?.isAllShiftExists ? "All Shifts" : "",
          isActive: location.pathname?.includes(`/request/create/all-shift`),
          linkTo: `/request/create/all-shift`,
          cursorPointer: false,
          onClick: () => {
            setCurrentSetup(create_request_steps?.allShifts);
            // if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") navigateTo(`/request/edit/${request_id ?? location?.state?.request_id}`, { state: location?.state })
          }
        },

        {
          label: (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded" || location?.state?.referrer === "not-planned" || location?.state?.referrer === "global-request" || location?.state?.referrer === "invitation" || location?.state?.referrer === "in-bidding") && location.pathname?.includes(`/request/create/shift/details/`) ? "Shift details" : "",
          isActive: true,
          linkTo: null,
          cursorPointer: false,
          onClick: () => {
            // setCurrentSetup("");
            // if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") navigateTo(`/request/edit/${request_id ?? location?.state?.request_id}`, { state: location?.state })
          }
        },

      ]
    }

    if (location?.pathname === k_create_request_paths.shift_details + shift_id) {
      if (current_step === create_request_steps.allShifts || location?.state?.isAllShiftExists) {
        return [
          {
            label: t("Create Request"),
            isActive: false,
            linkTo: k_create_request_paths.create,
            cursorPointer: true,
            onClick: () => { setCurrentSetup(""); navigateTo(k_create_request_paths.create) }
          },
          {
            label: t("All Shifts"),
            cursorPointer: true,
            isActive: false,
            onClick: () => { setCurrentSetup(create_request_steps.allShifts); navigateTo(all_shift_path, { state: { ...location?.state } }) },
          },
          {
            label: t("Shift Details"),
            linkTo: -1,
            isActive: true,
          },
        ];
      } else {
        return [
          {
            label: t("Create Request"),
            isActive: false,
            cursorPointer: true,
            onClick: () => { setCurrentSetup(""); navigateTo(k_create_request_paths.create) }
          },

          {
            label: t("Shift Details"),
            linkTo: -1,
            isActive: true,
          },
        ];
      }

    } else if (current_step === create_request_steps.allShifts) {
      return [
        {
          label: t("Create Request"),
          isActive: false,
          linkFn: "",
          cursorPointer: true,
          onClick: () => {
            setCurrentSetup("");
            navigateTo(k_create_request_paths.create)
          }
        },
        {
          label: t("All Shifts"),
          linkTo: "",
          isActive: true,
        },
      ];
    }

    else {
      return []
    }

  };

  // const  = ;
  const requestEditPath = `/request/edit/${request_id}`;

  const getTableViewStatus = () => {
    if (
      (current_step === create_request_steps.tableView)
      && (location?.pathname === `/request/create` || requestEditPath)
    ) return true
    else return false
  }

  useEffect(() => {
    setIsTableView(getTableViewStatus());
  }, [current_step, location?.pathname]);

  return (
    <>
      {
        !isTableView ? (
          <div
            onClick={() => {
              console.log("location?.state: ", location?.state ?? "no location state");
            }}
            className="bg-white h-auto flex flex-row justify-between space-x-10 z-[11]">
            <div>
              <CommonTitle
                withConfirm={true}
                title={
                  location?.state?.referrer ?
                    location?.state?.referrer === "awarded" ? "Upcoming"
                      : location?.state?.referrer === "global-request" ? "Global Request"
                        : location?.state?.referrer === "not-planned" ? "Not Planned"
                          : location?.state?.referrer === "in-bidding" ? "In Bidding"
                            : location?.state?.referrer
                    : isTableView ? t("Back to request") : t("Create Request")}
                icon={true}
                link={
                  location?.state?.referrer ?
                    location?.state?.referrer === "global-request" ? global_request_path
                      : `/requests/${location?.state?.referrer}`
                    : null}
              />

              {
                (!isTableView && getLinksArray()?.length > 0) ? (
                  <div className="my-4">
                    <BackLink
                      cursorPointer={false}
                      linksArray={getLinksArray()}
                    />
                  </div>
                ) :
                  <div className="mt-4"></div>
              }
            </div>

            {
              (
                (
                  location?.state?.referrer === "saved"
                  || location?.state?.referrer === "awarded"
                  || location?.state?.referrer === "global-request"
                  || location?.state?.referrer === "invitation"
                  || location?.state?.referrer === "not-planned"
                  || location?.state?.referrer === "in-bidding"
                )
                && (location.pathname?.includes(`/request/create/all-shift`) || location.pathname?.includes(`request/create/shift/details/${request_id}`))
              )
                ? <></>
                : (location?.pathname.includes(`/request/create/shift/details/${request_id}`) && location?.state?.referrer === "invitation" && !location?.state?.isEditMode) ? <></>
                  : <Actions />
            }

          </div>
        ) : (
          <TableViewHeader />
        )
      }
    </>
  );
}
