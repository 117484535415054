import i18next from "i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminSideBarList from "../../components/layout/SidebarItemArrays/AdminSideBarList";
import CompanySideBarList from "../../components/layout/SidebarItemArrays/CompanySideBarList";
import CustomerSideBarList from "../../components/layout/SidebarItemArrays/CustomerSideBarList";
import useGeneralStore, { setFirebaseDeviceToken } from "../stores/others/generalStore";
import useLayoutStore from "../stores/others/layoutStore";
import useSettingsStore from "../stores/others/settingsStore";
import AxiosHeader from "./AxiosHeader";
import { k_requests_status, user_role as role } from "./const";
import { iBottomSorted, iNotSorted, iTopSorted } from "./imageImports";
import { createTheme } from "@mui/material";
import { t } from "i18next";
import { format } from 'date-fns';

export const changePageTitle = (newTitle) => {
  return (document.title = newTitle);
};

export const unauthorisedPages = () => {
  return (
    window?.location.pathname === "/login" ||
    window.location.pathname === "/register" ||
    window.location.pathname === "/forgot-password" ||
    window.location.pathname === "/set-new-password" ||
    window.location.pathname === "/otp-verification"
  );
};

export const Toastr = ({ message = "", type = "error", id = "123", autoClose = 2000 }) => {
  if(type === "error" && !localStorage.getItem("limadi_token") && !unauthorisedPages() ) {
    window?.location?.replace("/login");
    return;
  }
  if(!message && type === "error" && !unauthorisedPages()) {
    window?.location?.reload();
    return
  }
  toast(message, {
    position: "bottom-right",
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    type: type,
    toastId: id
  });
};

export const handleNullValue = (value) => value === "null" || value === null ? 'NA' : value;

export const formatDate = (date, withTime = false) => {
  const { app_lang_code } = useSettingsStore.getState();
  if (!date) return null;
  const months = [];
  months["en"] = [
    ". Jan",
    ". Feb",
    ". Mar",
    ". Apr",
    ". May",
    ". Jun",
    ". Jul",
    ". Aug",
    ". Sep",
    ". Oct",
    ". Nov",
    ". Dec",
  ];
  months["da"] = [
    ". jan.",
    ". feb.",
    ". mar.",
    ". apr.",
    ". maj.",
    ". jun.",
    ". jul.",
    ". aug.",
    ". sept.",
    ". okt.",
    ". nov.",
    ". dec.",
  ];
  const targetDate = new Date(date);
  const hours = targetDate.getHours();
  const minutes = targetDate.getMinutes();

  if (withTime)
    return `${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}${months[app_lang_code][new Date(date).getMonth()]} ${new Date(date).getFullYear()}, ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
  else
    return `${new Date(date).getDate() < 10 ? '0' + new Date(date).getDate() : new Date(date).getDate()}${months[app_lang_code][new Date(date).getMonth()]} ${new Date(date).getFullYear()}`;
};

export const formatDateV2 = (date = new Date()) => {
  const year = date?.getFullYear().toString().padStart(4, '0');
  const month = (date?.getMonth() + 1).toString().padStart(2, '0');
  const day = date?.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const formatDateOrTime = (dateTime, type = 'time') => {
  if (!dateTime) return null;
  const date = new Date(
    new Date(dateTime).toLocaleString('en-US', {
      timeZone: 'Europe/Berlin',
    }));
  if (type === "date") {
    return `${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}. ${date.getMonth() < 10 ? "0" + date.getMonth() : date.getMonth()
      }. ${date.getFullYear()}`;
  } else {
    return `${date.getHours() < 10 ? '0' + date.getHours() : date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`;
  }
};

export function convertATime(date) {
  if (date) {
    const targetDate = new Date(date);
    const hours = targetDate.getHours();
    const minutes = targetDate.getMinutes();

    // Use template literals to format the time
    return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  } else {
    return "00:00";
  }
}

export function formatSchedule(date = "", startTime = "", endTime = "", emptyText = " ", endDate = false, showTime=true) {
  if (date || startTime || endTime || endDate) {
    if (endDate) {
      endDate = addDaysToDate(date, 1);
    }

    const formattedDate = date ? `${formatDate(date)}` : "";
    const formattedEndDate = endDate ? `${formatDate(endDate)}` : "";
    const formattedStartTime = startTime ? formatTime(startTime) : "";
    const formattedEndTime = endTime ? formatTime(endTime) : "";
    const formattedTimeRange = startTime ? `${formattedStartTime} - ${formattedEndTime}` : "";
    if(showTime) {
      return formattedDate ? `${formattedDate} ${endDate ? `- ${formattedEndDate}` : ""} ${formattedDate && formattedTimeRange ? ", " : ""} ${formattedTimeRange}` : formattedTimeRange;
    }
    else return formattedDate ? `${formattedDate} ${endDate ? `- ${formattedEndDate}` : ""}` : ""
  } else {
    return emptyText
  }
}

export const formatDateTime = (date = "", time = "", timeFormat = true) => {
  if (date || time) {
    const formattedDate = date ? `${formatDate(date)}` : "";
    const formattedStartTime = time ? timeFormat ? formatTime(time) : formatDateOrTime(time) : "";

    const formattedTimeRange = time ? `${formattedStartTime}` : "";
    return formattedDate ? `${formattedDate}${formattedDate && formattedTimeRange ? ", " : ""} ${formattedTimeRange}` : formattedTimeRange;
  } else {
    return ""
  }
}

export const getNextDate = (formattedDate) => {
  let date = new Date(formattedDate); // Note: months are 0-indexed

  // Add 1 day
  date.setDate(date.getDate() + 1);

  // Format the next day's date to YYYY-MM-DD
  let nextDay = date.getDate().toString().padStart(2, '0');
  let nextMonth = (date.getMonth() + 1).toString().padStart(2, '0');
  let nextYear = date.getFullYear();

  let nextDate = `${nextYear}-${nextMonth}-${nextDay}`;
  return nextDate
}


export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result)
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
}

export const getImageExtension = (imageUrl) => {
  if (!imageUrl) {
    return null
  }
  return imageUrl?.split('.')?.pop()?.toLowerCase();
}

export const getImageExtensionByBase64 = (file) => {
  if (!file) return null;
  let fileType = file?.split(";")[0];
  console.log(fileType);
  return fileType?.split("/")?.pop()?.toLowerCase();
}

export const dateDifference = (date) => {
  const date1 = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: 'Europe/Berlin',
    }));
  const date2 = new Date(
    new Date(date).toLocaleString('en-US', {
      timeZone: 'Europe/Berlin',
    }));
  const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
  const DifferenceDay = Math.abs(diffDays)
  const value2 = DifferenceDay === 0 ? 'Today' : Math.abs(diffDays)
  return value2
};

export const dateDifference2 = (startDate, endDate) => {
  const date1 = new Date(
    new Date(startDate).toLocaleString('en-US', {
      timeZone: 'Europe/Berlin',
    }));
  const date2 = new Date(
    new Date(endDate).toLocaleString('en-US', {
      timeZone: 'Europe/Berlin',
    }));
  const diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
  return Math.abs(diffDays);
};

export const isLettersOnly = (string) => {
  return /^[a-zA-Z]+$/.test(string);
};


export const extractDate = (date) => {
  const t_date = new Date(date);
  let month = (t_date.getMonth() + 1)
  if (month < 10) month = "0" + month.toString();

  let day = (t_date.getDate());
  if (day < 10) day = "0" + day.toString();
  return t_date.getFullYear() + "-" + month + "-" + day;
}


export const getFormatedStringFromDays = (numberOfDays) => {
  var years = Math.floor(numberOfDays / 365);
  var months = Math.floor(numberOfDays % 365 / 30);
  var days = Math.floor(numberOfDays % 365 % 30);

  var yearsDisplay = years > 0 ? years + (years === 1 ? " year " : " years ") : "";
  var monthsDisplay = months > 0 ? months + (months === 1 ? " month " : " months ") : "";
  var daysDisplay = days > 0 ? days + (days === 1 ? " day" : " days") : "0 days";
  return yearsDisplay + monthsDisplay + daysDisplay;
}

export const smartFormattedDateDiff = (startDateInput = "", endDateInput) => {

  if (startDateInput === "") startDateInput = new Date(startDateInput);
  else startDateInput = new Date();
  endDateInput = new Date(endDateInput);

  startDateInput.setHours(0, 0, 0, 0);
  // console.log("startDateInput::::", startDateInput);
  endDateInput.setHours(0, 0, 0, 0);
  // console.log("endDateInput::::", endDateInput);

  let dateTimeToFormat = endDateInput.getTime() - startDateInput.getTime();

  if (dateTimeToFormat < 0) dateTimeToFormat = dateTimeToFormat * (-1);
  // if (dateTimeToFormat < 0) return "0 Hours 0 Minutes ";

  // const diffDuration = moment.duration(dateTimeToFormat);

  // console.log("diffDuration ::::::", diffDuration, "\n\n dateTimeToFormat", dateTimeToFormat); 

  // let yearString = diffDuration.years() > 1 ? " Years" : " Year";
  // let monthString = diffDuration.months() > 1 ? " Months" : " Month";
  // let dayString = diffDuration.days() > 1 ? " Days" : " Day";
  // let hourString = diffDuration.hours() > 1 ? " Hours" : " Hour";
  // let minuteString = diffDuration.minutes() > 1 ? " Minutes" : " Minute";

  // if (diffDuration.years() > 0) {
  //   if (diffDuration.months() === 0) return diffDuration.years() + yearString + ", " + diffDuration.days() + dayString + ", " + diffDuration.hours() + hourString + ", " + diffDuration.minutes() + minuteString;
  //   else return diffDuration.years() + yearString + ", " + diffDuration.months() + monthString + ", " + diffDuration.days() + dayString + ", " + diffDuration.hours() + hourString + ", " + diffDuration.minutes() + minuteString;
  // }

  // if (diffDuration.years() === 0 && diffDuration.months() > 0) return diffDuration.months() + monthString + ", " + diffDuration.days() + dayString + ", " + diffDuration.hours() + hourString + ", " + diffDuration.minutes() + minuteString;
  // if (diffDuration.years() === 0 && diffDuration.months() === 0 && diffDuration.days() > 0) return diffDuration.days() + dayString + ", " + diffDuration.hours() + hourString + ", " + diffDuration.minutes() + minuteString;
  // if (diffDuration.years() === 0 && diffDuration.months() === 0 && diffDuration.days() === 0) return diffDuration.hours() + hourString + ", " + diffDuration.minutes() + minuteString;

  return "00 [NEW VERSION NEEDED] ";

}
export const toHoursAndMinutes = (totalMinutes) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return `${padTo2Digits(hours)}.${padTo2Digits(minutes)} hr`;
}
function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const dateDiffCalendar = (startingDateInput, endingDateInput, lastAction = false) => {

  if (lastAction) startingDateInput = startingDateInput.setDate(startingDateInput.getDate() + 1);

  let startingDate = new Date(startingDateInput).setHours(0, 0, 0, 0);


  let endingDate = new Date(endingDateInput).setHours(0, 0, 0, 0);

  let startDate = new Date(new Date(startingDate).toISOString().substr(0, 10));
  if (!endingDate) {
    endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
  }
  let endDate = new Date(endingDate);
  if (startDate > endDate) {
    const swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  const startYear = startDate.getFullYear();
  const february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  let yearDiff = endDate.getFullYear() - startYear;
  let monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  let dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  let yearString = yearDiff > 1 ? " years " : " year ";
  let monthString = monthDiff > 1 ? " months " : " month ";
  let dayString = dayDiff > 1 ? " days" : " day";

  return (yearDiff ? (yearDiff + yearString) : "") + (monthDiff ? (monthDiff + monthString) : "") + (dayDiff ? (dayDiff + dayString) : '0 day ');
}

export const calculateEndDateCalendar = (months, startDate = new Date()) => {
  let date = new Date(startDate);

  date.setHours(0, 0, 0, 0);
  date.setMonth(date.getMonth() + months)
  date.setDate(date.getDate() - 1);

  return date;
}

export const roughLicenseDurationFormatter = (days = 0) => {


  let monthCounter = parseInt(days / 30);
  let yearCounter = 0;


  if (monthCounter > 11) {
    yearCounter = parseInt(monthCounter / 12);
    monthCounter = parseInt(monthCounter - (12 * yearCounter));
  }
  //console.log("roughLicenseDurationFormatter::: monthCounter: " + monthCounter + " yearCounter: " + yearCounter);

  let yearString = yearCounter > 1 ? " Years " : " Year ";
  let monthString = monthCounter > 1 ? " Months " : " Month ";

  return (yearCounter ? (yearCounter + yearString) : "") + (monthCounter ? (monthCounter + monthString) : "");

}

export function isNullDirty(value) {
  if (value === null || value === undefined || value === "" || value === "null") return true;
  else return false;
}


export function LogDanger(message, value) {
  console.log('%c' + message, 'background: #f40e44; color: #ffffff; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogToDo(message, value) {
  console.log('%c' + message, 'background: #f4ef4b; color: #000000; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogSuccess(message, value) {
  console.log('%c' + message, 'background: #47ff90; color: #000000; font-weight: bold; padding:15px; border-radius: 1500px', value);
}

export function LogWarning(message, value) {
  console.log('%c' + message, 'background: #FC4C02; color: #ffffff; font-weight: bold; padding:15px; border-radius: 1500px', value);
}


export const formatSearchAddress = (addresses = []) => {
  let formatSearchAddress = []
  addresses.forEach((address) => {
    let x = address?.title?.split(',')
    let y = ''
    for (let i = (x.length - 1); i >= 0; i--) {
      i === 0 && (y += x[i])
      i > 0 && (y += x[i] + ", ")
    }
    formatSearchAddress.push({ ...address, title: y })
  })
  return formatSearchAddress
}

export const formatSearchAddressV2 = (data = [], has_postal_code = true) => {
  // console.log('formatSearchAddressV2', data);
  let temp_address = [];
  data.forEach((d) => {
    // let x = d.address;
    // let y = '';
    // x?.street ? y += x.street + (x?.houseNumber ? ' ' : ', ') : y += '';
    // x?.houseNumber ? y += x.houseNumber + ', ' : y += '';
    // (has_postal_code && x?.postalCode) ? y += x.postalCode + ', ' : y += '';
    // x?.city ? y += x.city + ', ' : y += '';
    // x?.countryName ? y += x.countryName + ', ' : y += '';
    // let count = y?.split(',').length - 1;
    // if (count > 0) y = y.substring(0, y.length - 2);
    // (y.length !== 0) && 
    temp_address.push({ ...d, title: d.title });
  })
  return temp_address;
}

export const suggestionFormat = (x) => {
  let y = '';
  x?.street ? y += x.street + (x?.houseNumber ? ' ' : ', ') : y += '';
  x?.houseNumber ? y += x.houseNumber + ', ' : y += '';
  x?.city ? y += x.city + ', ' : y += '';
  x?.countryName ? y += x.countryName + ', ' : y += '';
  (x?.postalCode) ? y += x.postalCode + ', ' : y += '';
  let count = y?.split(',').length - 1;
  if (count > 0) y = y.substring(0, y.length - 2);
  return y;
}

export function getBrowserWidth() {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth
  );
}

export const setUserRole = (role = role.customer) => {
  const { setUserRole } = useGeneralStore.getState();

  if (role === 'private') {
    setUserRole(role.customer);
  } else if (role === role.company) {
    setUserRole(role.company);
  } else if (role === role.admin || role === 'admin') {
    setUserRole(role.admin);
  }
}

export const setAppSidebarList = (role) => {
  const { setSidebarItemsList } = useLayoutStore.getState();
  switch (role) {
    case "company":
      setSidebarItemsList([...CompanySideBarList]);
      break;
    case "private":
      setSidebarItemsList([...CustomerSideBarList]);
      break;
    case "admin":
      setSidebarItemsList([...AdminSideBarList]);
      break;
    case "sa":
      setSidebarItemsList([...AdminSideBarList]);
      break;

    default:
      setSidebarItemsList([...CompanySideBarList]);
      break;
  }
}

export const setAxiosHeaders = (role) => {
  if (localStorage.limadi_token) {
    AxiosHeader(localStorage.limadi_token, role);
  } else if (sessionStorage.limadi_token) {
    AxiosHeader(sessionStorage.limadi_token, role);
  } else {
    AxiosHeader();
  }
}


export const htmlToPlainText = (html) => {

  // Create a new div element
  var tempDivElement = document.createElement("div");

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element 
  return tempDivElement.textContent || tempDivElement.innerText || "";
}
export const removeSecFromDeliveryTime = (time) => {
  if (!time) return null;

  if (typeof time !== 'string') {
    console.log("Invalid time format");
    return null;
  }

  const time_array = time.split(":");
  const hour = time_array[0] ?? '';
  const min = time_array[1] ?? '';

  if (hour === '' || min === '') return '';
  return `${hour}:${min}`;
}


export const formatDateForMui = (date) => {

  // "2017-05-24T10:30"

  date = new Date(date);
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let year = date.getFullYear();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (month < 10) month = "0" + month;
  if (day < 10) day = "0" + day;
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;

  return year + "-" + month + "-" + day + "T" + hours + ":" + minutes;
}

export const initializeFirebase = (is_logged_in, firebase) => {
  if (is_logged_in) {
    // initialize firebase
    let messaging = null;
    if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging();
    }
    messaging.requestPermission().then(() => {
      return messaging.getToken()
    }).then(token => {
      setFirebaseDeviceToken(token)
      // console.log('Token : ', token)
    }).catch((err) => {
      console.error(err);
    })
  }
}

export const setAppLanguage = (lang_code) => {
  useSettingsStore.getState().setAppLangCode(lang_code);
  i18next.changeLanguage(lang_code);
  localStorage.setItem("lang_code", lang_code);
}

export function countNewLines(str) {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str[i] === "\n") {
      count++;
    }
  }
  return count + 1;  //to pre fetch rows of textarea input
}

export function getStringFromDateObject(date) {
  if (!date) return null;
  date = new Date(date);
  const year = date?.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const getCurrentDateTime = () => {
  const now = new Date();

  // Get the date in yyyy-mm-dd format
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(now.getDate()).padStart(2, '0');

  const currentDate = `${year}-${month}-${day}`;

  // Get the time in hh:mm format
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  const currentTime = `${hours}:${minutes}`;

  return { currentDate, currentTime };
}

export const checkForCurrentDate = (date) => {
  const {currentDate} = getCurrentDateTime();
  return date == currentDate
}

export const checkForFutureDate = (date) => {
  const { currentDate } = getCurrentDateTime();

  return date ?? currentDate;
}

export const checkForPastDate = (date) => {
  const { currentDate } = getCurrentDateTime();
  if (!date) return currentDate
  else if (date < currentDate) return date;
  else return currentDate;
}

export const checkForFutureTime = (time) => {
  const { currentTime } = getCurrentDateTime();
  if (!time) return currentTime
  else if (time < currentTime) return currentTime;
  else return time;
}

export const checkForPastTime = (time) => {
  const { currentTime } = getCurrentDateTime();
  if (!time) return currentTime
  else if (time > currentTime) return currentTime;
  else return time;
}

export const checkForFutureDateTime = (date, time, onlyTimeCheck = false) => {
  //pass the date in yyyy-mm-dd format
  //pass the time in 24 hr format hh:mm
  const { currentDate, currentTime } = getCurrentDateTime();

  if (!date) {
    return;
  }

  if (date < currentDate) {
    return false;
  }

  else if (date > currentDate) {
    return true
  }
  else {
    return (time > currentTime)
  }
}

export const checkForPastDateTime = (date, time, onlyTimeCheck = false) => {
  //pass the date in yyyy-mm-dd format
  //pass the time in 24 hr format hh:mm
  const { currentDate, currentTime } = getCurrentDateTime();

  if (!date) {
    return;
  }

  if (date > currentDate) {
    return false;
  }

  else if (date < currentDate) {
    return true
  }
  else {
    return (time < currentTime)
  }
}

export const isDateInPast = (dateString) => {
  if(!dateString) return;
  const today = new Date(); // Current date and time
  const givenDate = new Date(dateString); // Convert the input date string to a Date object

  // Compare dates: only consider the date part (ignore time)
  return givenDate < today.setHours(0, 0, 0, 0); // Return true if givenDate is before today
}

export const isLessTime = (time1, time2) => {
  const [hours1, minutes1] = time1.split(':');
  const [hours2, minutes2] = time2.split(':');

    // First compare the hours
  if (parseInt(hours1) < parseInt(hours2)) return true;
  if (parseInt(hours1) > parseInt(hours2)) return false;

  // If hours are the same, compare the minutes
  return parseInt(minutes1) < parseInt(minutes2);
} 

export const isWithinTimeLimit = (givenDateStr, givenTimeStr) => {
  // Parse the given date and time
  if(!givenDateStr || !givenTimeStr) return;
  const givenDate = new Date(givenDateStr); // Given date string, e.g., "2024-11-23"
  const [hours, minutes] = givenTimeStr?.slice(0,5)?.split(':')?.map(Number);

  // Set the time part for the given date
  givenDate.setHours(hours, minutes, 0, 0);

  // Get the current date and time
  const now = new Date();

  // Check if the given date is in the future
  if (givenDate > now) {
      return true;
  }

  // Check if the given date is in the past
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const givenDay = new Date(givenDate.getFullYear(), givenDate.getMonth(), givenDate.getDate());
  if (givenDay < today) {
      return false;
  }

  // If the given date is today, check the time condition
  const twoHoursLater = new Date(givenDate.getTime() + 2 * 60 * 60 * 1000);
  return now < twoHoursLater;
}

export const isTimeExpired = (givenDateStr, givenTimeStr) => {
  // Parse the given date and time
  if(!givenDateStr || !givenTimeStr) return;
  const givenDate = new Date(givenDateStr); // Given date string, e.g., "2024-11-23"
  const [hours, minutes] = givenTimeStr?.slice(0,5)?.split(':')?.map(Number);

  // Set the time part for the given date
  givenDate.setHours(hours, minutes, 0, 0);

  // Get the current date and time
  const now = new Date();

  // Check if the given date is in the future
  if (givenDate > now) {
      return true;
  }

  // Check if the given date is in the past
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const givenDay = new Date(givenDate.getFullYear(), givenDate.getMonth(), givenDate.getDate());
  if (givenDay < today) {
      return false;
  }

  // If the given date is today, check the time condition
  const isTimeExpired = new Date(givenDate.getTime() + 0 * 60 * 60 * 1000);
  return now < isTimeExpired;
}

export function isValidUrl(url) {
  // Regular expression pattern for a valid website URL
  var pattern = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,}(\/.*)?$/i;
  // Test the input URL against the pattern
  return pattern.test(url);
}

export function setLocalDataWithExpiry(key, value, ttl) {
  const now = new Date(); // get current date and time
  const item = {
    value: value, // data value
    expiry: now.getTime() + ttl // expiry time in milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item)); // store as JSON string
}

export function getLocalDataWithExpiry(key) {
  const itemStr = localStorage.getItem(key); // get JSON string
  if (!itemStr) { // if no item found return null
    return null;
  }
  const item = JSON.parse(itemStr); // parse as object
  const now = new Date(); // get current date and time
  if (now.getTime() > item.expiry) { // compare with expiry time
    localStorage.removeItem(key); // remove expired item
    return null;
  }
  return item.value; // return data value
}



// const secretKey = "some-super-secret-key";

export const encryptNumber = (number) => {
  // Generate a random number
  var num = Math.random();
  // Convert it to a string in base 36 (alphadecimal)
  var str = num.toString(36);
  // Remove the leading "0." and keep only 5 characters
  str = str.slice(2, 7);
  // Display the result
  console.log(str)
  return str;

};

export const decryptNumber = (str) => {
  // Convert the string back to a number in base 36
  var num2 = parseInt(str, 36);
  // Divide it by Math.pow(36,5) to get back the original number
  num2 = num2 / Math.pow(36, 5);
  // Display the result
  console.log(num2);
  return num2;
};

export function addDaysToDate(dateString, duration = 0) {
  if (!dateString) return null;
  const start_Date = new Date(dateString);
  start_Date.setDate(start_Date.getDate() + duration); // Use the duration parameter here
  return format(start_Date, 'yyyy/MM/dd');
}



export const formatTime = (time) => {
  if (!time) return time
  const [hours, minutes] = time?.split(":");
  const formattedTime = `${hours}:${minutes}`;
  return formattedTime
}
export function formatTimeHourMinutes(timeString) {
  // Split the time string into hours, minutes, and seconds
  if (timeString) {
    const [hours, minutes] = timeString?.split(':');

    // Return the formatted time string
    return `${hours}:${minutes}`;
  }
}

export function secondsToHms(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const hoursString = hours.toString().padStart(2, '0');
  const minutesString = minutes.toString().padStart(2, '0');

  return `${hoursString}:${minutesString}`;
}

export function secondsToHmsFormat(data) {
  if (data == null || data === "null" || data === "0" || data === 0) {
    return "0 hr";
  }
  
  let time = parseInt(data, 10);
  let hours = Math.floor(time / 3600);
  let remain = time % 3600;
  let mins = Math.floor(remain / 60);
  let secs = remain % 60;
  
  if (secs > 30) {
    mins += 1;
  }
  
  if (hours === 0) {
    return `${mins} min`;
  }
  
  if (mins === 0) {
    return `${hours} hrs`;
  } else {
    return `${hours} hrs ${mins} min`;
  }
}

export function removeEmpty(obj) {
  for (let prop in obj) {
    if (!obj[prop] || obj[prop]?.length === 0) {
      delete obj[prop];
    }
  }
  return obj;
}
export function getOrdinalNumber(n) {
  if (n === 0) {
    return "0";
  }
  const suffixes = ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"];
  const lastTwoDigits = n % 100;
  const lastDigit = n % 10;
  const suffix = suffixes[lastTwoDigits] || suffixes[lastDigit] || suffixes[0];
  if (n >= 10 && n <= 20)
    return `${n}${suffixes[0]}`;
  else
    return `${n}${suffix}`;
}

export function checkPastTime(time, inputDate = new Date()) {
  if (time) {
    const timeArray = time?.split(":");
    var nowDate = new Date();
    inputDate = new Date(inputDate);

    inputDate.setHours(timeArray[0]);
    inputDate.setMinutes(timeArray[1]);

    if (nowDate > inputDate) return true;   //this means the input time is a past time along with the date
    else return false;
  }
}

export function insertElementAtIndex(arr, index, element) {
  arr.splice(index, 0, element);
  return arr;
}
export function getTimeFromDate(date) {
  date = new Date(date);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}


export const calculateDistance = (data) => {
  data = parseInt(data);
  if (typeof data === 'number' && !isNaN(data)) {
    if (data >= 1000) {
      const distanceInKM = (data / 1000).toLocaleString('da-DK', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
      const formattedDistance = distanceInKM
      return {
        distance: formattedDistance,
        unit: "KM"
      };
    } else {
      const distanceInM = data.toLocaleString('da-DK', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
      const formattedDistance = distanceInM
      return {
        distance: parseInt(formattedDistance),
        unit: data > 0 ? "M" : "KM"
      };
    }
  }
  return {
    distance: '0',
    unit: "KM"
  };
};



export const calculateTime = (data) => {
  if (parseInt(data) === 0) {
    return "0 min"
  } else {
    const hours = Math.floor(parseInt(data) / 3600);
    const minutes = Math.floor((parseInt(data) % 3600) / 60);

    return `${hours > 0 ? `${hours} hrs` : ""} ${minutes > 0 ? `${minutes} min` : ""} `;
  }

};


export function secondsToHoursFormatted(seconds) {
  if (typeof parseInt(seconds) !== 'number') {
    console.log("Invalid input. Please provide a number of seconds.");
    return null;
  }

  const hours = Math.floor(parseInt(seconds) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = hours + minutes / 60 + remainingSeconds / 3600;

  return `${formattedHours?.toFixed(2)} Hrs`;
}

export function objectToArray(obj, twoDeeArray = false) {
  const keys = Object.keys(obj);
  const values = Object.values(obj);
  const result = [];

  for (let i = 0; i < keys.length; i++) {
    if (twoDeeArray) {
      result.push([keys[i], values[i]]);
    } else {
      result.push(...values[i]);
    }
  }

  return result;
}

export function removeAnArrayItemOnce(arr, value) {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function titleCaseJS(string) {
  if (string?.length > 1)
    return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export const formatDateToApiDate = (data) => {
  console.log('formatDateToApiDate DATA::::', data);

  // create a Date object from the given string
  const date = new Date(data);

  // extract the year, month, and day from the Date object
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);

  // concatenate the year, month, and day with dashes to form the desired format
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;

}

// Define a reusable onChange handler function
export const handleInputChange = (updateFunction, fieldPath) => (e) => {
  const { value } = e.target;
  // Trim the value and replace multiple spaces with a single space
  const trimmedValue = value.trim().replace(/\s+/g, ' ');

  // Update the state using the provided update function and field path
  updateFunction(fieldPath, value);
};


export const defineTableSortIcon = (status, isActive) => {
  // console.log('defineTableSortIcon', status, isActive);
  if (status && isActive) return iTopSorted;
  else if (!status && isActive) return iBottomSorted;
  else return iNotSorted;
}

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#fff",
          "&$error": {
            color: "#fff",
          },
        },
      },
    },
  },
});

export const theme2 = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "#FF6368",
          "&$error": {
            color: "#FF6368",
          },
        },
      },
    },
  },
});

export const forwardDate = (date) => {
  const x = new Date(date);
  x.setDate(x.getDate() + 1);
  return x.toISOString().slice(0, 10);
}

export const backwardDate = (date) => {
  const x = new Date(date);
  x.setDate(x.getDate() - 1);
  return x.toISOString().slice(0, 10);
}

export const validatePhoneNumber = (input) => {
  const regex = /^(?:\+\d)?\d+$/;
  return regex.test(input);
};


export function isDateFormatValid(dateString) {
  // const dateInput1 = "12-30-2023"; // Valid format
  // const dateInput2 = "12.30.2023"; // Valid format
  // const dateInput3 = "12/30/2023"; // Valid format

  // Check if the date is a valid Date object and the year is not NaN
  // return date instanceof Date && !isNaN(date.getFullYear());

  const dateFormats = ["MM-DD-YYYY", "MM.DD.YYYY", "MM/DD/YYYY", "DD-MMM-YY", "YYYY-MM-DD"];

  for (const format of dateFormats) {
    if (format === "DD-MMM-YY") {
      const parts = dateString.split('-'); // Split the date string by hyphens
      if (parts.length === 3) {
        const day = parseInt(parts[0], 10);
        const monthAbbreviation = parts[1];
        const year = parseInt(parts[2], 10);

        if (!isNaN(day) && !isNaN(year)) {
          const monthAbbreviations = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
          const monthFullNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

          const normalizedMonthName = monthAbbreviation.charAt(0).toUpperCase() + monthAbbreviation.slice(1).toLowerCase(); // Normalize the input

          return monthAbbreviations.includes(normalizedMonthName) || monthFullNames.includes(normalizedMonthName);
        }
      }
    } else {
      const parts = dateString.split(/[-./]/);
      if (parts.length === 3) {
        const month = parseInt(parts[0], 10);
        const day = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);

        if (!isNaN(month) && !isNaN(day) && !isNaN(year)) {
          if (month >= 1 && month <= 12 && day >= 1 && day <= 31) {
            return true; // Valid date format for other formats
          }
        }
      }
    }
  }

  return false; // Invalid date format
}

export async function isTimeFormatValid(timeString) {
  if (!timeString) return false;

  // Match the "HH:mm" format

  if (/^(\d|[01]\d|2[0-3]):(\d|[0-5]\d)$/.test(timeString)) {
    const parts = timeString.split(":");

    if (parts.length === 2) {
      const hour = parseInt(parts[0], 10);
      const minute = parseInt(parts[1], 10);

      if (!isNaN(hour) && !isNaN(minute)) {
        if (hour >= 0 && hour <= 23) {
          if (minute >= 0 && minute <= 59) {
            return true; // Valid time format
          } else {
            return Toastr({ message: t('Invalid time format: Minutes should be between 0 and 59.'), type: 'warning' });
          }
        } else {
          return Toastr({ message: t('Invalid time format: Hours should be between 0 and 23.'), type: 'warning' });
        }
      } else {
        return Toastr({ message: t('Invalid time format: Please use the format HH:mm.'), type: 'warning' });
      }
    }
    return Toastr({ message: t('Invalid time format: Please use the format HH:mm.'), type: 'warning' });

  } else {
    const parts = timeString.split(":");
    const hour = parseInt(parts[0], 10);
    const minute = parseInt(parts[1], 10);

    if (!isNaN(hour) && !isNaN(minute)) {
      if (hour >= 0 && hour <= 23) {
        if (!minute >= 0 && !minute <= 59) {
          return Toastr({ message: t('Minutes should be between 0 and 59.'), type: 'warning' });
        }
      } else {
        return Toastr({ message: t('Hours should be between 0 and 23.'), type: 'warning' });
      }
    } else {
      return Toastr({ message: t(' Please use the format HH:mm.'), type: 'warning' });
    }
  }
}

export function countEmptySpaces(inputString) {
  // Use a regular expression to match strings that only contain spaces or multiple consecutive spaces
  const spaceRegex = /^[\s]+$/;

  if (spaceRegex.test(inputString)) {
    return true; // Allow all other strings
  } else {
    return false; // Disallow strings that only consist of spaces or contain multiple consecutive spaces
  }
}


export function ConvertToMidnight(dateTime) {
  const midnightDate = new Date(dateTime);
  midnightDate.setHours(0, 0, 0, 0);
  return midnightDate;
}

export function isObjectEmpty(obj) {
  return typeof obj === 'object' && Object.keys(obj).length === 0;
}


/**
 * This function is used to format the timestamp of chat messages.
 * If the provided `createdAt` date is the same as the current date,
 * it only returns the time part. Otherwise, it returns both the date and time.
 *
 * @param {string} createdAt - The original timestamp of the chat message.
 * @returns {string|null} - The formatted timestamp or null if `createdAt` is not provided.
 */
export const formatChatTimestamp = (createdAt) => {
  const currentDateTime = new Date();
  if (!createdAt) return null;
  const date = new Date(createdAt);
  return date.toDateString() === currentDateTime.toDateString() ?
    formatDateOrTime(createdAt) : `${formatDate(createdAt)}, ${formatDateOrTime(createdAt)}`;
};

export const formatChatTimestampMessage = (createdAt) => {
  if (!createdAt) return null;
  const date = new Date(createdAt);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${parseInt(hours) < 10 ? '0' : ''}${hours}:${parseInt(minutes) < 10 ? '0' : ''}${minutes}`;
};

// Function to validate a password
export const validatePassword = (password, password_confirmation) => {
  // Check if password has at least one uppercase letter
  const hasUppercase = /[A-Z]/.test(password);
  // Check if password has at least one lowercase letter
  const hasLowercase = /[a-z]/.test(password);
  // Check if password has at least one digit
  const hasDigit = /\d/.test(password);
  // Check if password has at least one special character
  const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);
  // Check if password is at least 6 characters long
  const isLongEnough = password.length >= 8;

  // If password doesn't have an uppercase letter, return an error message
  if (!hasUppercase) {
    return { valid: false, message: "Password must contain at least one uppercase letter." };
  }

  // If password doesn't have a lowercase letter, return an error message
  if (!hasLowercase) {
    return { valid: false, message: "Password must contain at least one lowercase letter." };
  }

  // If password doesn't have a digit, return an error message
  if (!hasDigit) {
    return { valid: false, message: "Password must contain at least one digit." };
  }

  // If password doesn't have a special character, return an error message
  if (!hasSpecialChar) {
    return { valid: false, message: "Password must contain at least one special character." };
  }

  // If password is not long enough, return an error message
  if (!isLongEnough) {
    return { valid: false, message: "Password must be at least 6 characters long." };
  }

  // If password and password confirmation don't match, return an error message
  if (password !== password_confirmation) {
    return { valid: false, message: "Password don't match" };
  }

  // If all checks pass, return valid
  return { valid: true };
}

export function formatRequestDateTime(date, time) {
  if (date && !time) {
    return formatDate(date);
  } else {
    const f_date = formatDate(date) ?? '';
    const f_time = formatTime(time) ?? '';
    const separator = time ? ", " : "";

    return `${f_date}${separator}${f_time}`;
  }
}

export const getRequestCounter = (counter, title) => {
  const { user_role } = useGeneralStore.getState()

  const counterMap = {
    [k_requests_status.saved]: 'saved_count',
    [k_requests_status.history]: null,
    [k_requests_status.in_bidding]: 'in_bidding_count',
    [k_requests_status.ongoing]: 'ongoing_count',
    [k_requests_status.awarded]: 'upcoming_count',
    [k_requests_status.invitation]: 'invitation_count',
    [k_requests_status.not_planned]: 'not_planned_count',
    [k_requests_status.completed]: 'complete_count',
  };

  if (user_role === role.customer) {
    delete counterMap[k_requests_status.invitation];
    delete counterMap[k_requests_status.not_planned];
    delete counterMap[k_requests_status.completed];
  }

  return counterMap[title] ? counter[counterMap[title]] : null;
}


/**
 * This function calculates a date range based on the provided dates.
 * 
 * @param {Object} args - The arguments object.
 * @param {string} args.date_from - The start date of the range.
 * @param {string} args.date_to - The end date of the range.
 * 
 * @returns {Object} An object containing the calculated date range.
 */
export function calculateDateRange(date_from, date_to) {
  // If both dates are provided, return them as they are.
  if (date_from && date_to) {
    return { date_from, date_to };
  }

  // If only the start date is provided...
  if (date_from) {
    const dateFrom = new Date(date_from);

    // If the start date is in the past, set the end date to today.
    if (dateFrom < new Date()) {
      date_to = new Date().toISOString().split('T')[0];
    }
    // If the start date is in the future, set the end date to 10 years from the start date.
    else {
      dateFrom.setFullYear(dateFrom.getFullYear() + 10);
      date_to = dateFrom.toISOString().split('T')[0];
    }

    return { date_from, date_to };
  }

  // If no dates are provided, set the start date to January 1, 2020, and the end date to the start date.
  date_from = formatDateToApiDate(new Date("2020-01-01"));
  date_to = date_to || date_from;

  return { date_from, date_to };
}

export const isDirty = (initialState, currentState) => {
  return JSON.stringify(initialState) !== JSON.stringify(currentState)
}

export const sortKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(item => sortKeys(item));
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).sort().reduce((acc, key) => {
      acc[key] = sortKeys(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};


export function isWhitespaceOnly(str = "") {
  return str?.length > 0 && /^\s*$/.test(str);
}

export function removeHyphensFromString(str = "", addSpace = true) {
  const parts = str.split('-'); // Split the date string by hyphens
  let res = "";
  if (!addSpace) {
    parts.map(part => res = res + part)
  }
  else {
    parts.map(part => res = res + " " + part)
  }
  return res;
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function capitalizeFirstChar(str) {
  if (str.length === 0) return str; // Handle empty strings
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isFutureDateTime(dateTimeString = "", acceptPresentDate = false) {
  // Example usage:
  // console.log(isFutureDateTime('2095-01-01T00:00:00')); // true
  // console.log(isFutureDateTime('2020-01-01T00:00:00')); // false
  // console.log(isFutureDateTime('2024-10-16', true)); // true (if today's date is 2024-10-16)

  const inputDate = new Date(dateTimeString);
  const currentDate = new Date();

  // Check if the input is a valid date
  if (isNaN(inputDate.getTime())) {
    return false;
  }

  if (acceptPresentDate) {
    // Set the time of inputDate to the current system time
    const currentTime = new Date();
    inputDate.setHours(currentTime.getHours());
    inputDate.setMinutes(currentTime.getMinutes());
    inputDate.setSeconds(currentTime.getSeconds());
    inputDate.setMilliseconds(currentTime.getMilliseconds());

    // Accept both future and present dates
    return inputDate >= currentDate;
  } else {
    // Only accept future dates
    return inputDate > currentDate;
  }
}



export function formatNumber(num) {
  if (num < 10) {
    return '0' + num.toString();
  }
  else return num
}

// Utility function for extracting and printing the first error message from an error object
export function printFirstError(errorObject = {}, isDataMode = false) {
  const responseData = errorObject?.response?.data;

  if (!responseData || !responseData.errors || !responseData?.data) {
    console.log({ errorObject });
    return "An error occurred.";
  }

  let errorCategories = Object.keys(responseData.errors);
  if (isDataMode) {
    errorCategories = Object.keys(responseData.data);
  }
  if (errorCategories.length === 0) {
    return "An error occurred.";
  }

  const firstError = responseData.errors[errorCategories[0]][0];
  console.log({ firstError });

  return firstError || "An error occurred."; // Fallback if firstError is undefined
}

export function compareTime(min_time = "", input_time = "") {
  console.log(`min_time: ${min_time}, input_time: ${input_time}`);

  // Validate input times
  if (typeof min_time !== 'string' || typeof input_time !== 'string') {
    throw new Error("Both min_time and input_time should be strings in 'HH:mm' format.");
  }

  // Check if either time is empty
  if (!min_time || !input_time) return false;

  // Split the time strings into hours and minutes, while safely parsing integers
  const [minHours, minMinutes] = min_time.split(':').map(Number);
  const [inputHours, inputMinutes] = input_time.split(':').map(Number);

  // Validate parsed results
  if (isNaN(minHours) || isNaN(minMinutes) || isNaN(inputHours) || isNaN(inputMinutes)) {
    throw new Error("Invalid time format. Please use 'HH:mm'.");
  }

  // Convert both times to total minutes since midnight
  const minTotalMinutes = minHours * 60 + minMinutes;
  const inputTotalMinutes = inputHours * 60 + inputMinutes;

  // Compare the total minutes
  return minTotalMinutes < inputTotalMinutes;
}


export const extModifierDateTime = (section, status, expectedDate, expectedTime, date, time) => {

  const ksNotAvailable = "NA";
  const ksPickedUp = "Picked up";
  const ksNotPickedUp = "Not picked up";
  const ksExpectedPickup = "Expected pickup";
  const ksDelivered = "Delivered";
  const ksNotDelivered = "Not delivered";
  const ksExpectedDelivery = "Expected delivery";



  const dateTimeNullCheck = (date, time) => {

    const dateString = date ? formatDate(date) : "";
    const timeString = time ? time.slice(0, 5) : "";


    if (!date && time) {
      return `${ksNotAvailable}, ${timeString}`;
    } else if (date && !time) {
      return `${dateString}, ${ksNotAvailable}`;
    } else if (!date && !time) {
      return ksNotAvailable;
    }
    return `${dateString}, ${timeString}`;

  };
  let prefix = '';

  if (section === "pickup") {
    if (status === "delivered") {
      prefix = ksPickedUp;
      return `${prefix}: ${dateTimeNullCheck(date, time)}`;
    } else if (status === "not_delivered") {
      prefix = ksNotPickedUp;
      return `${prefix}: ${dateTimeNullCheck(date, time)}`;
    } else {
      prefix = ksExpectedPickup;
      return `${prefix}: ${dateTimeNullCheck(expectedDate, expectedTime)}`;
    }
  } else {
    if (status === "delivered") {
      prefix = ksDelivered;
      return `${prefix}: ${dateTimeNullCheck(date, time)}`;
    } else if (status === "not_delivered") {
      prefix = ksNotDelivered;
      return `${prefix}: ${dateTimeNullCheck(date, time)}`;
    } else {
      prefix = ksExpectedDelivery;
      return `${prefix}: ${dateTimeNullCheck(expectedDate, expectedTime)}`;
    }
  }
};
export function addMinutesToTime(time, minutesToAdd) {

  if (time === null || typeof time !== 'string' || !/^([01]\d|2[0-3]):([0-5]\d)$/.test(time)) {
    return// console.log("Invalid time format. Please use 'HH:mm'.");
  }

  if (typeof minutesToAdd !== 'number') {
    return console.log("minutesToAdd must be a number.");
  }

  // Parse the input time
  const [hours, minutes] = time.split(':').map(Number);

  // Calculate totalMinutes directly with added minutes
  const totalMinutes = (hours * 60 + minutes + minutesToAdd) % (24 * 60);

  // Calculate new hours and minutes
  const newHours = Math.floor(totalMinutes / 60);
  const newMinutes = totalMinutes % 60;

  // Format the result as a string
  const newTime = `${String(newHours).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;

  return newTime;
}

export function convertToDDMMYYYY(dateStr = "") {
  if (!dateStr) return;
  // Use a regular expression to match the different formats
  const regex = /(\d{1,2})[.\-\/](\d{1,2})[.\-\/](\d{4})/;
  const match = dateStr.match(regex);

  if (match) {
    const day = match[1].padStart(2, '0');   // Pad day to 2 digits
    const month = match[2].padStart(2, '0'); // Pad month to 2 digits
    const year = match[3];

    return `${day}/${month}/${year}`;
  } else {
    console.log('Invalid date format');
  }
}

export function isPastTime(inputTime, comparableDateString = "") {
  if (!inputTime) return;
  // Get the current time
  const currentTime = new Date(comparableDateString);

  // Split the input time string (e.g., "06:45") into hours and minutes
  const [inputHours, inputMinutes] = inputTime?.split(':').map(Number);

  // Create a new Date object for the input time (using today's date)
  const inputDate = new Date();
  inputDate.setHours(inputHours, inputMinutes, 0, 0); // hours, minutes, seconds, milliseconds

  // Compare the input time with the current time
  return inputDate < currentTime;
}

export function isStartDateValid(startDate = "", endDate = "", isEqualMode = false) {
  if (!startDate || !endDate) return;

  // Parse the input dates
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (isNaN(start) || isNaN(end)) return console.log("Invalid date format");


  const startDateF = start?.toISOString()?.slice(0, 10);
  const endDateF = end?.toISOString()?.slice(0, 10);

  console.log(startDateF === endDateF); // true

  if (isEqualMode) return startDateF === endDateF;

  // Check if start date is less than or equal to end date
  return start <= end;
}

export const checkImageFileSize = (file, maxSizeMB = 5) => {
  const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes
  const fileSize = file.size; // Get file size in bytes

  if (fileSize <= maxSizeBytes) {
    return true;
  } else {
    return false;
  }
}

export function isPastDate(dateString = "") {
  // Parse the input date string to create a Date object
  const inputDate = new Date(dateString)

  if (!!inputDate) {

    // Get today's date and set time to 00:00:00 to only compare dates
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Check if the input date is less than today's date
    return inputDate < today;
  }
}

export function correctTimeString(timeString) {
  // Check if timeString is null, undefined, or not a string
  if (!timeString || typeof timeString !== 'string') {
    return console.log('Invalid time string');
  }

  // Split the timeString by the colon
  let [hours, minutes] = timeString.split(':');

  // If minutes is undefined or less than 2 characters, pad it with a 0
  if (!minutes || minutes.length < 2) {
    minutes = minutes ? minutes.padStart(2, '0') : '00';
  }

  // Ensure hours is at least 2 characters long
  hours = hours.padStart(2, '0');

  // Return the formatted time in HH:MM format
  return `${hours}:${minutes}`;
}


export function isEndTimeSmaller(startTime, endTime) {
  // Validate inputs: Check if both inputs are provided
  const timePattern = /^(?:(?:[01]\d|2[0-3]):[0-5]\d(?:\:\d{2})?)$/;

  if (!startTime || !endTime) {
    console.log({ valid: false, message: "Both startTime and endTime must be provided." });
    return false;
  }

  if (!timePattern.test(startTime)) {
    console.log({ valid: false, message: "Invalid format for startTime. Must be in HH:MM or HH:MM:SS 24-hour format." });
    return false;
  }

  if (!timePattern.test(endTime)) {
    console.log({ valid: false, message: "Invalid format for endTime. Must be in HH:MM or HH:MM:SS 24-hour format." });
    return false;
  }

  // Split the time strings into hours and minutes (ignore seconds if present)
  const [startHours, startMinutes] = startTime.split(":").slice(0, 2).map(Number);
  const [endHours, endMinutes] = endTime.split(":").slice(0, 2).map(Number);

  // Check if the end time is smaller than the start time
  if (endHours < startHours || (endHours === startHours && endMinutes < startMinutes)) {
    return true;
  }

  return false;
}

export const checkContent = (element) => {
  if (!element || !(element instanceof HTMLElement)) {
    return false; // Return false if element is null, undefined, or not an HTMLElement
  }

  return Array.from(element?.childNodes).some(node => {
    if (node?.nodeType === Node.ELEMENT_NODE) {
      // Recursively check child elements
      if (node?.tagName?.toLowerCase() === 'br') {
        return false; // Ignore <br> tags
      }
      if (node?.tagName?.toLowerCase() === 'img') {
        return node?.hasAttribute('src') && node?.getAttribute('src').trim() !== '';
      }
      return checkContent(node);
    } else if (node?.nodeType === Node.TEXT_NODE) {
      // Check if there's any non-whitespace text
      return node?.nodeValue?.trim() !== '';
    }
    return false;
  });
}

export const specialCharacters = ["e", "E", "+", "-", "/", "*", "#", "<", ">", "-", ".", ",", "e", ".e"]
