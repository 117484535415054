/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore, { getInitData, isCrFormValidationWithAddress, loadCreateRequestData } from "../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../app/stores/others/generalStore";
import {
  create_request_steps,
  user_role as role,
} from "../../../../../../../app/utility/const";
import { Toastr } from "../../../../../../../app/utility/utilityFunctions";
import DeliveryAccordion from "../../../../../../../components/Accordion/DeliveryAccordion";
import RequestAccordion from "../../../../../../../components/Accordion/RequestAccordion";
import CommonButton from "../../../../../../../components/button/CommonButton";
import DeliveryDetails from "./DeliveryDetails";
import DeliveryForm from "./DeliveryForm";
import { useLocation, useParams } from "react-router-dom";
import useRequestStore, { getNewRequestDetails } from "../../../../../../../app/stores/others/requestStore";

export default function Delivery() {
  const {
    current_step,
    setShowTableViewModal,
    cr_form,
    addDelivery,
    stopData,
    resetStopData,
    expandedAccordion,
    setExpandedAccordion,
    isApplyGenerate,
    setTableCurrentPage,
    setTableViewInvalidIndex,
    setTableCrFullForm,
    setCurrentSetup,
    setDeliveryExpandedAccordion,
    setIsInvalidProduct,
    setIsInvalidProductFrom,
    setStopData,
  } = useCreateRequestStore();
  const { fetchReqDetails } = useRequestStore();
  const { setIsMassUploadDirty } = useGeneralStore();
  const { deliveries } = cr_form;

  const location = useLocation();
  const { t } = useTranslation();
  const { request_id } = useParams();

  const [expandedIndex, setExpandedIndex] = useState();
  const itemsPerLoad = 5;
  const [extraStops, setExtraStops] = useState([]);
  const [visibleStops, setVisibleStops] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const [lastItemRequiredField, setLastItemRequiredField] = useState([]);
  const [visitedIndices, setVisitedIndices] = useState([]);
  const { request_history } = useRequestStore.getState();

  const handleAddDelivery = () => {
    setIsInvalidProduct(false);

    if (useGeneralStore.getState().user_role === role.company) {
      let lastStop = stopData;
      if (!stopData?.address) {
        let tempArray = [];
        tempArray.push("address");
        setLastItemRequiredField(tempArray);
        return Toastr({ message: t("Please fill in the address field!"), type: "warning" })
      };
      if (stopData?.date) {
        if (!stopData?.start_time) return Toastr({ message: t("Please fill in the time field!"), type: "warning" });
        if (!stopData?.end_time) return Toastr({ message: t("Please fill in the range field!"), type: "warning" });
      }
      if (stopData?.duration && stopData?.duration < 3) {
        return Toastr({ message: t("Duration can not be less than 3 minutes!"), type: "warning" });
      }
      if (lastStop?.address && lastStop?.lat && lastStop?.lng) {
        setLastItemRequiredField([]);

        let stopDataToSave = { ...stopData };
        stopDataToSave.floor_number = stopDataToSave?.floor_number?.trim()

        stopDataToSave.products = stopDataToSave?.products?.map((item) => {
          return {
            ...item, // Spread the existing properties
            text: item.text?.trim() || "Product" // Replace empty text with "Product"
          };
        });

        if (stopDataToSave?.products?.length === 0 || !stopDataToSave?.products) {
          stopDataToSave.products = [{ text: "Product" }];
          console.log("LOGIC: before adding a delivery ", stopDataToSave?.products);
        }
        addDelivery({ deliveryData: stopDataToSave });
        setDeliveryExpandedAccordion(null);
        setIsInvalidProductFrom(false)
        resetStopData();
      } else {
        let tempArray = [];
        if (!(lastStop?.address && lastStop?.lat && lastStop?.lng)) {
          tempArray.push("address");
        }
        setLastItemRequiredField(tempArray);
        if (lastStop?.address && !lastStop?.lat && !lastStop?.lng) {
          return Toastr({ message: t("Address is invalid. Select a valid address from the suggestions."), type: "warning" });
        }
        return Toastr({ message: t("Please enter a valid address!"), type: "warning" });
      }
    } else {
      let lastStop = stopData;
      if (
        !!lastStop?.address && !!lastStop?.lat && !!lastStop?.lng
      ) {
        setLastItemRequiredField([]);

        let stopDataToSave = { ...stopData };
        stopDataToSave.floor_number = stopDataToSave?.floor_number?.trim()
        // stopDataToSave.products = stopDataToSave?.products?.filter((item) => !!item.text?.trim());
        stopDataToSave.products = stopDataToSave?.products?.map((item) => {
          return {
            ...item, // Spread the existing properties
            text: item.text?.trim() || "Product" // Replace empty text with "Product"
          };
        });

        if (stopDataToSave?.products?.length === 0 || stopDataToSave?.products?.text?.trim() === "" || !stopDataToSave?.products) {
          stopDataToSave.products = [{ text: "Product" }];
          console.log("LOGIC: before adding a delivery ", stopDataToSave?.products);
        }

        addDelivery({ deliveryData: stopDataToSave });
        setIsInvalidProductFrom(false)
        resetStopData();
      } else {
        let tempArray = [];
        if (!lastStop?.address) {
          tempArray.push("address");
        }
        setLastItemRequiredField(tempArray);
        if (!!lastStop?.address && !lastStop?.lat && !lastStop?.lng) {
          return Toastr({ message: t("Address is invalid. Select a valid address from the suggestions."), type: "warning" });
        }
        return Toastr({
          message: t("Please fill in the address field!"),
          type: "warning",
        });
      }
    }
  };

  const checkAllFieldData = (item) => {
    let itemProductsLength = item?.products?.length;
    let filledProductInputLength = item?.products?.filter(
      (item) => !!item.text
    ).length;
    return (
      !!item.address &&
      !!item.formatted_date &&
      !!item.start_time &&
      !!item.end_time &&
      itemProductsLength === filledProductInputLength
    );
  };

  useEffect(() => {
    loadRequest()
  }, [])

  useEffect(() => {
    setShowTableViewModal(false);
  }, [current_step]);

  useEffect(() => {
    if (extraStops?.length > visibleStops?.length) {
      loadMoreItems();
    }
  }, [extraStops]);

  const getDeliveryArray = () => {

    return cr_form?.deliveries?.map((item, index) => {
      return {
        stop: item,
        address: item?.address,
        lat: item?.lat?.toString(),
        lng: item?.lng?.toString(),
        products: item?.products,
        date: item?.date,
        children: (
          <DeliveryDetails
            index={index}
            item={item}
            lastItemRequiredField={[]}
            hasVisited={visitedIndices?.includes(item?.serial_id)}
          />
        ),
        id: item?.id,
        ref: index === visibleStops?.length - 1 ? lastItemObserver : null,
        noError: checkAllFieldData(item),
        borderStyle:
          visitedIndices.includes(item?.serial_id) && !checkAllFieldData(item)
            ? "1px solid #F0554B"
            : "",
      };
    });
  };

  const handleDeliveryPointVisiting = (id) => {
    if (visitedIndices.indexOf(id) === -1) {
      setVisitedIndices([...visitedIndices, id]);
    }
  };

  const loadMoreItems = () => {
    setHasMore(visibleStops?.length < extraStops?.length);
    const newItems = extraStops.slice(
      visibleStops?.length,
      visibleStops?.length + itemsPerLoad
    );
    setVisibleStops([...visibleStops, ...newItems]);
  };

  const lastItemObserver = useCallback((node) => {
    if (observer?.current) observer.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0]?.isIntersecting && hasMore) {
        loadMoreItems();
      }
    });

    if (node) observer?.current?.observe(node);
  });

  const totalProducts = deliveries?.reduce(
    (total, item) => total + item?.products?.length,
    0
  );

  let invalidDateIndices = cr_form?.deliveries?.length > 0 && cr_form?.deliveries?.map((delivery, index) => {
    if (new Date(delivery?.date) < new Date(cr_form?.pickup?.date)) {
      return index;
    }
  })
    .filter(index => index !== undefined);

  const isCrossVisible =
    !!stopData.date ||
    !!stopData.start_time ||
    !!stopData?.duration ||
    !!stopData.end_time ||
    !!stopData.delivery_time ||
    !!stopData.floor_number ||
    !!stopData?.address ||
    !!stopData.comment ||
    !!stopData.attachment ||
    (stopData?.products.length > 0 && !!stopData.products.filter((item) => !!item.text).length);

  const loadRequest = async () => {
    if ((location?.state?.is_fetch_req_details === false && fetchReqDetails === false) || request_history?.id == request_id) return;
    if (request_id) {
      await getNewRequestDetails(request_id);
      await loadCreateRequestData(request_history);
    }
    getInitData();
  };

  return (
    <RequestAccordion
      isInvalid={isApplyGenerate && ((!isCrFormValidationWithAddress()?.deliveries) || invalidDateIndices?.length > 0)}
      showClearButton={isCrossVisible}
      clearHandler={resetStopData}
      clearButtonTooltipMessage="Clear"
      title={t("Delivery")}
      isForm={true}
      subTitleOne={deliveries?.length > 0 ? `${deliveries?.length} Deliveries` : ""}
      isExpanded={expandedAccordion === create_request_steps.mass_import}
      onExpand={() => {
        setLastItemRequiredField([]);
        setDeliveryExpandedAccordion(null)
        setIsInvalidProduct(false)
        setExpandedAccordion(
          expandedAccordion === create_request_steps.mass_import ? null : create_request_steps.mass_import
        );
      }}
      subTitleTwo={totalProducts > 0 ? totalProducts + t(" Products") : ""}
      secondaryTitle={t("Mass Upload")}
      handleSecondaryTitleClick={() => {
        setTableCurrentPage(1);
        setTableViewInvalidIndex([]);
        setIsMassUploadDirty(false)

        console.log("cr_form", cr_form);

        setTableCrFullForm(cr_form?.deliveries);
        setCurrentSetup(create_request_steps?.tableView);
      }}

    >
      <>
        <DeliveryForm
          lastItemRequiredField={lastItemRequiredField}
          setLastItemRequiredField={setLastItemRequiredField}
        />

        <div className="flex justify-center mt-5">
          <CommonButton
            width="w-[200px]"
            isDisabled={deliveries?.length >= 200}
            btnLabel={t("Add Delivery")}
            mediumSize={true}
            onClick={handleAddDelivery}
          />
        </div>

        <h3 className="leading-6 text-fs20 font-fw500 text-cGray800 mt-9">
          {t("Added Delivery Stops")} ({deliveries?.length})
        </h3>

        {cr_form?.deliveries?.length > 0 ? (
          <div className="mt-5">
            <DeliveryAccordion
              expandedIndex={expandedIndex}
              optionArray={getDeliveryArray()}
              setExpandedIndex={setExpandedIndex}
              errorIndex={1}
              onChangeEvent={handleDeliveryPointVisiting}
            />
          </div>
        ) : (
          <div className="mt-3 bg-cButtonHoverColor h-[76px] w-full flex items-center justify-center rounded">
            <p className="leading-6 text-fs18 font-fw500 text-cGray500">
              {t("You have not added any delivery stop yet")}
            </p>
          </div>
        )}
      </>
    </RequestAccordion>
  );
}
