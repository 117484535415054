/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore, {
    getAddress,
} from "../../../../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../../../../app/stores/others/generalStore";
import { user_role as role } from "../../../../../../../../../app/utility/const";
import { iPast } from "../../../../../../../../../app/utility/imageImports";
import {
    convertToDDMMYYYY,
    countEmptySpaces,
    isTimeFormatValid,
    Toastr,
} from "../../../../../../../../../app/utility/utilityFunctions";
import CommonButton from "../../../../../../../../../components/button/CommonButton";
import CommonModal from "../../../../../../../../../components/modal/CommonModal";
import {
    address_width,
    comment_width,
    date_width,
    floor_width,
    product_width,
    time_width,
} from "../../tableViewFildWidth";
import { HEADER_KEYS } from "./massUploadConst";

export const Header = () => {
    const {
        cr_form,
        table_cr_form,
        updateTableStopsForm,
        setShowAdditionStopAlertModal,
        setAddProduct,
        setTableCrFullForm,
        setPasteDataLength,
        massUpClearTriggered,
        setMassUpClearTriggered
    } = useCreateRequestStore();

    const {isDirty, setIsDirty, isMassUploadDirty, setIsMassUploadDirty} = useGeneralStore();

    const [propertyData, setProperty] = useState(null);

    const pasteDataLengthRef = useRef(0);
    const additionalStopsRef = useRef(0);
    const totalTableRowsRef = useRef(0);

    const remainingTableRowsRef = useRef(table_cr_form.length > 0 ? table_cr_form.length : 30);
    const remainingCommentRowsRef = useRef(table_cr_form.length > 0 ? table_cr_form.length : 30);
    const remainingFloorRowsRef = useRef(table_cr_form.length > 0 ? table_cr_form.length : 30);
    const remainingAddressRowsRef = useRef(table_cr_form.length > 0 ? table_cr_form.length : 30);
    const remainingDateRowsRef = useRef(table_cr_form.length > 0 ? table_cr_form.length : 30);
    const remainingTimeRowsRef = useRef(table_cr_form.length > 0 ? table_cr_form.length : 30);
    const remainingProductRowsRef = useRef(table_cr_form.length > 0 ? table_cr_form.length : 30);


    const additionalCommentRowsRef = useRef(0);
    const additionalFloorRowsRef = useRef(0);
    const additionalAddressRowsRef = useRef(0);
    const additionalDateRowsRef = useRef(0);
    const additionalTimeRowsRef = useRef(0);
    const additionalProductRowsRef = useRef(0);

    const firstEmptyDateIndexRef = useRef(0);
    const firstEmptyTimeIndexRef = useRef(0);
    const firstEmptyFloorIndexRef = useRef(0);
    const firstEmptyCommentIndexRef = useRef(0);
    const firstEmptyAddressIndexRef = useRef(0);
    const firstEmptyProductIndexRef = useRef(0);

    const { setLoading, user_role } = useGeneralStore();
    const { t } = useTranslation();

    const setDirtyMode = ( ) => {
        console.log("calling dirty mode from header")
        if(!isDirty) setIsDirty(true)
        if(!isMassUploadDirty) setIsMassUploadDirty(true)
      }

    const resetAllTableData = () => {
        remainingTableRowsRef.current = 30;
        remainingCommentRowsRef.current = 30;
        remainingFloorRowsRef.current = 30;
        remainingAddressRowsRef.current = 30;
        remainingDateRowsRef.current = 30;
        remainingTimeRowsRef.current = 30;
        remainingProductRowsRef.current = 30;

        additionalCommentRowsRef.current = 0;
        additionalFloorRowsRef.current = 0;
        additionalAddressRowsRef.current = 0;
        additionalDateRowsRef.current = 0;
        additionalTimeRowsRef.current = 0;
        additionalProductRowsRef.current = 0;

        firstEmptyDateIndexRef.current = 0;
        firstEmptyTimeIndexRef.current = 0;
        firstEmptyFloorIndexRef.current = 0;
        firstEmptyCommentIndexRef.current = 0;
        firstEmptyAddressIndexRef.current = 0;
        firstEmptyProductIndexRef.current = 0;
    }

    useEffect(() => {
        if (massUpClearTriggered) {
            resetAllTableData();

            setMassUpClearTriggered(false);
        }
    }, [massUpClearTriggered]);

    const getAdditionalRowsByProperty = (propertyName = "") => {
        // propertyName = propertyName || propertyData;
        propertyName = localStorage.getItem("propertyData");
        // console.log("getAdditionalRowsByProperty: ", propertyName);

        if (propertyName === HEADER_KEYS.ADDRESS) return parseInt(additionalAddressRowsRef.current);
        if (propertyName === HEADER_KEYS.DATE) return parseInt(additionalDateRowsRef.current);
        if (propertyName === HEADER_KEYS.START_TIME) return parseInt(additionalTimeRowsRef.current);
        if (propertyName === HEADER_KEYS.FLOOR_NUMBER) return parseInt(additionalFloorRowsRef.current);
        if (propertyName === HEADER_KEYS.PRODUCT) return parseInt(additionalProductRowsRef.current);
        if (propertyName === HEADER_KEYS.COMMENT) return parseInt(additionalCommentRowsRef.current);
    }

    const getRemainingRowsByProperty = (propertyName = "") => {
        // propertyName = propertyName || propertyData;
        propertyName = localStorage.getItem("propertyData");
        // console.log("getRemainingRowsByProperty: ", propertyName);

        if (propertyName === HEADER_KEYS.ADDRESS) return parseInt(remainingAddressRowsRef.current);
        if (propertyName === HEADER_KEYS.DATE) return parseInt(remainingDateRowsRef.current);
        if (propertyName === HEADER_KEYS.START_TIME) return parseInt(remainingTimeRowsRef.current);
        if (propertyName === HEADER_KEYS.FLOOR_NUMBER) return parseInt(remainingFloorRowsRef.current);
        if (propertyName === HEADER_KEYS.PRODUCT) return parseInt(remainingProductRowsRef.current);
        if (propertyName === HEADER_KEYS.COMMENT) return parseInt(remainingCommentRowsRef.current);
    }

    const getReadClipboardText = async () => {
        try {
            // Attempt to read clipboard content
            const text = await navigator.clipboard.readText();
            // console.log('Read clipboard: ', text);
            return text;
        } catch (error) {
            console.error('Failed to read clipboard: ', error);
            //   alert('Failed to read clipboard. Please ensure you have copied text and try again.');
        }
    };

    const pasteInfo = async (property, makeEmptyCellZero = null) => {
        // console.log("started pasting with property: ", property);
        const textData = await getReadClipboardText();

        setDirtyMode();

        // check permission first
        const permission = await navigator.permissions.query({
            name: "clipboard-read",
        });

        if (!textData && permission.state === "denied") {
            // console.log("permission denied");
            alert(t("You need to allow clipboard access to paste data"));
            return;
        }

        // prevent auto suggestion on paste
        setLoading(true);
        const text = textData ?? await navigator.clipboard.readText();

        // console.log("RECEIVED pasted text: ", text);

        // convert text to array of lines
        let data = text.split("\n");
        setPasteDataLength(data?.length);
        pasteDataLengthRef.current = data?.length;
        // console.log('paste data: ', data);

        // prevent use from multiple colum paste
        if (data[0].includes("\t")) {
            Toastr({
                message: t("You cannot paste multiple column"),
                type: "warning",
            });
            setLoading(false);
            return;
        }
        if (countEmptySpaces(data[0].replace(/\[|\]| /g, ""))) {
            return Toastr({
                message: t("Please remove extra space"),
                type: "warning",
            });
        }

        useCreateRequestStore.getState().setPasteData(data);
        setProperty(property);
        localStorage.setItem("propertyData", property);

        // console.log("remainingRows: getRemainingRowsByProperty()", getRemainingRowsByProperty(property));
        // console.log("data.length: ", data?.length);



        if ((data.length > getRemainingRowsByProperty(property))
            // || (parseInt(data?.length) > remainingCommentRowsRef.current)
            // || (parseInt(data?.length) > remainingFloorRowsRef.current)
            // || (parseInt(data?.length) > remainingAddressRowsRef.current)
            // || (parseInt(data?.length) > remainingDateRowsRef.current)
            // || (parseInt(data?.length) > remainingTimeRowsRef.current)   
        ) {

            //note: check for max 200 and if extra row creation required...
            if (parseInt(data?.length) < 1) {
                Toastr({
                    message: t("Please enter a number between 1 and 200."),
                    type: "warning",
                });
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                return;
            }

            if (parseInt(data?.length) > 200) {
                Toastr({ message: t("Maximum 200 items allowed."), type: "warning" });
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                return;
            }

            // console.log("200 STOPS REACHED (parseInt(data?.length) + parseInt(getRemainingRowsByProperty(property))): ", parseInt(data?.length) + parseInt(getRemainingRowsByProperty(property)));
            // console.log("getRemainingRowsByProperty(): ", getRemainingRowsByProperty(property));

            let totalFillUpDataToBe = (parseInt(data?.length) + (table_cr_form?.length - parseInt(getRemainingRowsByProperty(property))));

            if (totalFillUpDataToBe > 200) {

                Toastr({ message: t("Maximum 200 items allowed."), type: "warning" });
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                return;
            }

            if (parseInt(table_cr_form?.length) > 200) {
                Toastr({ message: t("Maximum 200 rows allowed."), type: "warning" });
                setTimeout(() => {
                    setLoading(false);
                }, 500);
                return;
            }
            else {
                if (property === HEADER_KEYS.COMMENT) {
                    // console.log("REMAINING COMMENT ROWS: ", remainingCommentRowsRef.current);

                    additionalCommentRowsRef.current = parseInt(data?.length) - remainingCommentRowsRef.current;
                    additionalCommentRowsRef.current = Math.abs(additionalCommentRowsRef.current);
                    // console.log("POPUP LOGIC TRIGGERED WITH: ", additionalCommentRowsRef.current);
                }
                else if (property === HEADER_KEYS.FLOOR_NUMBER) {
                    additionalFloorRowsRef.current = parseInt(data?.length) - remainingFloorRowsRef.current;
                    additionalFloorRowsRef.current = Math.abs(additionalFloorRowsRef.current);
                    // console.log("POPUP LOGIC TRIGGERED WITH: ", additionalFloorRowsRef.current);
                }
                else if (property === HEADER_KEYS.DATE) {
                    additionalDateRowsRef.current = parseInt(data?.length) - remainingDateRowsRef.current;
                    additionalDateRowsRef.current = Math.abs(additionalDateRowsRef.current);
                    // console.log("POPUP LOGIC TRIGGERED WITH: ", additionalDateRowsRef.current);
                }
                else if (property === HEADER_KEYS.START_TIME) {
                    additionalTimeRowsRef.current = parseInt(data?.length) - remainingTimeRowsRef.current;
                    additionalTimeRowsRef.current = Math.abs(additionalTimeRowsRef.current);
                    // console.log("POPUP LOGIC TRIGGERED WITH: ", additionalTimeRowsRef.current);
                }
                else if (property === HEADER_KEYS.ADDRESS) {
                    additionalAddressRowsRef.current = parseInt(data?.length) - remainingAddressRowsRef.current;
                    additionalAddressRowsRef.current = Math.abs(additionalAddressRowsRef.current);
                    // console.log("POPUP LOGIC TRIGGERED WITH: ", additionalAddressRowsRef.current);
                }
                else if (property === HEADER_KEYS.PRODUCT) {
                    additionalProductRowsRef.current = parseInt(data?.length) - remainingProductRowsRef.current;
                    additionalProductRowsRef.current = Math.abs(additionalProductRowsRef.current);
                    // console.log("POPUP LOGIC TRIGGERED WITH: ", additionalAddressRowsRef.current);
                }

                // additionalStopsRef.current = Math.abs(additionalStopsRef.current);
                // console.log("POPUP LOGIC TRIGGERED WITH: ", additionalStopsRef.current);

                // setTimeout(() => {
                //     console.log("OK additional stops: ", additionalStopsRef.current);
                // }, 2000);
                setLoading(false);
                setShowAdditionStopAlertModal(true);

                return;
            }
        } else {
            //note: main pasting data processing starts here...

            const isDateFormatValid = (date) => {
                // This regex matches "DD/MM/YYYY", "DD.MM.YYYY", and "DD-MM-YYYY" formats
                const regex =
                    /^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[012])[-/.](19|20)\d\d$/;
                return regex.test(date);
            };

            const convertDateFormat = (date) => {
                const [day, month, year] = date.split("/");
                return `${year}-${month}-${day}`;
            };

            if (property === HEADER_KEYS.DATE) {
                const { firstEmptyIndex, emptyCount } = countFullyEmptyEntities("date");

                remainingDateRowsRef.current = makeEmptyCellZero === HEADER_KEYS.DATE ? 0 : parseInt(remainingDateRowsRef.current) - parseInt(data?.length);
                // console.log("date emptyCount", remainingDateRowsRef.current);

                // console.log("firstEmptyDateIndexRef.current", firstEmptyDateIndexRef.current);
                // console.log("totalTableRowsRef.current", totalTableRowsRef.current);


                // remainingTableRowsRef.current = emptyCount;
                firstEmptyDateIndexRef.current = firstEmptyIndex;

                const valid = await isDateFormatValid(data[0]);
                if (valid) {
                    data?.map((item, index) => {
                        let convertedDateString = convertToDDMMYYYY(item);
                        updateTableStopsForm((index + parseInt(firstEmptyDateIndexRef.current)), property, convertDateFormat(convertedDateString));
                    }
                    );
                    setLoading(false);
                } else {
                    setLoading(false);
                    Toastr({
                        message: t(
                            'Invalid date format. Please use the format DD/MM/YYYY, DD.MM.YYYY, or DD-MM-YYYY"'
                        ),
                        type: "warning",
                    });
                    return;
                }
            }

            if (property === HEADER_KEYS.START_TIME) {
                let totalValidTimeData = 0;
                const allTimesValid = await Promise.all(
                    data.map((time) => {
                        if (isTimeFormatValid(time.replace(/\[|\]| /g, ""))) totalValidTimeData = totalValidTimeData + 1;
                        return isTimeFormatValid(time.replace(/\[|\]| /g, ""));
                    })
                );
                data?.map((item, index) => {
                    if (!!allTimesValid[index]) {
                        let [hours, minutes] = item.split(":").map(Number);

                        let curr_min = parseInt(minutes);
                        let curr_hour = parseInt(hours);
                        curr_min = Math.ceil(curr_min / 15) * 15;

                        if (curr_min === 60) {
                            curr_min = 0;
                            curr_hour += 1;
                        }

                        if (curr_hour === 24) {
                            curr_hour = 0;
                        }

                        if (curr_hour.toString().length < 2) curr_hour = '0' + curr_hour;
                        if (curr_min.toString().length < 2) curr_min = '0' + curr_min;

                        hours = curr_hour == "00" ? 23 : curr_hour;
                        minutes = curr_min;


                        const end_time = new Date();
                        // Assuming table_cr_form.stops[0].date contains a string representing the date
                        const originalDate = new Date(table_cr_form[0]?.date);

                        // Add one day to the original date
                        originalDate.setDate(originalDate.getDate() + 1);

                        // if (hours > 22) {
                        //   updateTableStopsForm(index, "date", originalDate);
                        // }
                        end_time.setHours(hours + 2, minutes);

                        const end_time_string = `${end_time
                            .getHours()
                            .toString()
                            .padStart(2, "0")}:${end_time
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")}`;

                        const { firstEmptyIndex, emptyCount } = countFullyEmptyEntities(HEADER_KEYS.START_TIME);



                        // console.log("time emptyCount", remainingTimeRowsRef.current);

                        // console.log("firstEmptyTimeIndexRef.current", firstEmptyTimeIndexRef.current);
                        // console.log("totalTableRowsRef.current", totalTableRowsRef.current);

                        remainingTimeRowsRef.current = makeEmptyCellZero === HEADER_KEYS.START_TIME ? 0 : parseInt(remainingTimeRowsRef.current) - 1;
                        firstEmptyTimeIndexRef.current = firstEmptyIndex;

                        updateTableStopsForm((index + parseInt(firstEmptyTimeIndexRef.current)), property, `${hours}:${minutes}`);
                        updateTableStopsForm((index + parseInt(firstEmptyTimeIndexRef.current)), "end_time", end_time_string);
                    }
                });
                // remainingTimeRowsRef.current = makeEmptyCellZero === HEADER_KEYS.START_TIME ? 0 : parseInt(remainingTimeRowsRef.current) - parseInt(totalValidTimeData);
                console.log("totalValidTimeData", totalValidTimeData);

                setLoading(false);
            }

            if (property === HEADER_KEYS.PRODUCT) {
                if (countEmptySpaces(data[0])) {
                    Toastr({ message: t("Please remove extra space"), type: "warning" });
                    setLoading(false);
                    return;
                } else {
                    data?.map((item, productIndex) => {
                        // const productItem = table_cr_form[productIndex];

                        // Check if productItem exists and has products
                        // for replacing old product with new pasted data.
                        // if (productItem && Array.isArray(productItem?.products) && productItem?.products.length > 0) {
                        //     const removedIds = productItem.products
                        //         .map((i) => i?.id)
                        //         .filter((id) => id !== undefined); // Filter out undefined ids

                        //     // Set removed_products_ids to an empty array if no valid ids are found
                        //     updateTableStopsForm(productIndex, "removed_products_ids", removedIds.length > 0 ? removedIds : []);
                        // }

                        const pd = item?.split(",");
                        // console.log("BEFORE UPDATE table_cr_form", table_cr_form);


                        updateTableStopsForm(
                            (productIndex + parseInt(firstEmptyProductIndexRef.current)),
                            "products",
                            pd?.filter((item) => item.trim() !== "").map((item) => ({ text: item }))
                        );

                        // console.log("AFTER UPDATE table_cr_form", table_cr_form);
                    });

                    // const { firstEmptyIndex, emptyCount } = countFullyEmptyEntities(property);
                    // firstEmptyProductIndexRef.current = firstEmptyIndex;                    

                    remainingProductRowsRef.current = makeEmptyCellZero === HEADER_KEYS.PRODUCT ? 0 : parseInt(remainingProductRowsRef.current) - parseInt(data?.length);


                    setLoading(false);
                }
                setAddProduct(true);
            }


            if (property === HEADER_KEYS.ADDRESS) data = data.map((item) => item.trim());

            if (property === HEADER_KEYS.ADDRESS) {
                const { firstEmptyIndex, emptyCount } = countFullyEmptyEntities(property);

                // console.log("emptyCount", emptyCount);


                // remainingTableRowsRef.current = emptyCount;                
                firstEmptyAddressIndexRef.current = firstEmptyIndex;

                const body = { addresses: data }
                const response = await getAddress(body, parseInt(firstEmptyAddressIndexRef.current));

                remainingAddressRowsRef.current = makeEmptyCellZero ? 0 : parseInt(remainingAddressRowsRef.current) - parseInt(data?.length);
                // console.log("ADDRESS emptyCount", remainingAddressRowsRef.current);

                // console.log("firstEmptyAddressIndexRef.current", firstEmptyAddressIndexRef.current);
                // console.log("totalTableRowsRef.current", totalTableRowsRef.current);

                await setTableCrFullForm([...response]);
            }

            if (
                property === HEADER_KEYS.FLOOR_NUMBER ||
                property === HEADER_KEYS.COMMENT ||
                property === HEADER_KEYS.DURATION
            ) {
                const { firstEmptyIndex, emptyCount } = countFullyEmptyEntities(property);

                // remainingTableRowsRef.current = emptyCount;
                if (property === HEADER_KEYS.FLOOR_NUMBER) {
                    firstEmptyFloorIndexRef.current = firstEmptyIndex;

                    remainingFloorRowsRef.current = makeEmptyCellZero === HEADER_KEYS.FLOOR_NUMBER ? 0 : parseInt(remainingFloorRowsRef.current) - parseInt(data?.length);
                    // console.log("floor emptyCount", remainingFloorRowsRef.current);

                    // console.log("firstEmptyFloorIndexRef.current", firstEmptyFloorIndexRef.current);
                    // console.log("totalTableRowsRef.current", totalTableRowsRef.current);


                    data?.map((item, index) => {
                        const trimmedItem = item.slice(0, 20);
                        updateTableStopsForm((index + parseInt(firstEmptyFloorIndexRef.current)), property, trimmedItem);
                    });
                } else if (property === HEADER_KEYS.COMMENT) {
                    firstEmptyCommentIndexRef.current = firstEmptyIndex;
                    // console.log("COMMENT PASTE: makeEmptyCellZero", makeEmptyCellZero);

                    remainingCommentRowsRef.current = makeEmptyCellZero === HEADER_KEYS.COMMENT ? 0 : parseInt(remainingCommentRowsRef.current) - parseInt(data?.length);
                    // console.log("comment emptyCount", remainingCommentRowsRef.current);

                    // console.log("firstEmptyCommentIndexRef.current", firstEmptyCommentIndexRef.current);
                    // console.log("totalTableRowsRef.current", totalTableRowsRef.current);


                    data?.map((item, index) => {
                        const trimmedItem = item.slice(0, 255);
                        updateTableStopsForm((index + parseInt(firstEmptyCommentIndexRef.current)), property, trimmedItem);
                    });
                }
            }
        }
        setTimeout(() => {
            setLoading(false);
        }, 700);
    };

    function countFullyEmptyEntities(parameterName) {
        let lastNonEmptyIndex = -1;

        // Loop through each object in the array
        for (let i = 0; i < table_cr_form.length; i++) {
            const entity = table_cr_form[i];

            // Check if the current row is not empty based on the specified parameter or all parameters
            const isNonEmpty = parameterName
                ? entity[parameterName] && entity[parameterName].length > 0
                : entity.date || entity.start_time || (entity.products && entity.products.length > 0) ||
                entity.address || entity.floor_number || entity.comment;

            // Update the last non-empty index if the current row is not empty
            if (isNonEmpty) {
                lastNonEmptyIndex = i;
            }
        }

        // Count fully empty entities after the last non-empty index
        let fullyEmptyCount = 0;

        for (let i = lastNonEmptyIndex + 1; i < table_cr_form.length; i++) {
            const entity = table_cr_form[i];

            // Check if the current row is fully empty for the specified parameter or all parameters
            const isFullyEmpty = parameterName
                ? !entity[parameterName] || entity[parameterName].length === 0
                : !entity.date && !entity.start_time && (!entity.products || entity.products.length === 0) &&
                !entity.address && !entity.floor_number && !entity.comment;

            if (isFullyEmpty) {
                fullyEmptyCount++;
            }
        }

        // Return the count of fully empty entities and the index of the first empty entity
        return {
            emptyCount: fullyEmptyCount,
            firstEmptyIndex: (lastNonEmptyIndex + 1)
        };
    }



    useEffect(() => {
        totalTableRowsRef.current = table_cr_form?.length;
        const targetProperty = localStorage.getItem('propertyData');
        const { firstEmptyIndex, emptyCount } = countFullyEmptyEntities();

        // console.log("USE_EFFECT emptyCount: ", emptyCount);
        // console.log("firstEmptyIndex: ", firstEmptyIndex);

        // if (targetProperty === HEADER_KEYS.PRODUCT) {
        //     firstEmptyProductIndexRef.current = firstEmptyIndex;
        //     remainingProductRowsRef.current = emptyCount;
        //     console.log("product first empty INDEX:::", firstEmptyProductIndexRef.current);
        // }

        remainingTableRowsRef.current = emptyCount;
        firstEmptyDateIndexRef.current = firstEmptyIndex;

        const { firstEmptyIndex: firstEmptyAddressIndex, emptyCount: remainingAddressCount } = countFullyEmptyEntities(HEADER_KEYS.ADDRESS);
        firstEmptyAddressIndexRef.current = firstEmptyAddressIndex;
        remainingAddressRowsRef.current = remainingAddressCount;

        const { firstEmptyIndex: firstEmptyFloorIndex, emptyCount: remainingFloorCount } = countFullyEmptyEntities(HEADER_KEYS.FLOOR_NUMBER);
        firstEmptyFloorIndexRef.current = firstEmptyFloorIndex;
        remainingFloorRowsRef.current = remainingFloorCount;

        const { firstEmptyIndex: firstEmptyDateIndex, emptyCount: remainingDateCount } = countFullyEmptyEntities(HEADER_KEYS.DATE);
        firstEmptyDateIndexRef.current = firstEmptyDateIndex;
        remainingDateRowsRef.current = remainingDateCount;

        const { firstEmptyIndex: firstEmptyStartTimeIndex, emptyCount: remainingStartTimeCount } = countFullyEmptyEntities(HEADER_KEYS.START_TIME);
        firstEmptyTimeIndexRef.current = firstEmptyStartTimeIndex;
        remainingTimeRowsRef.current = remainingStartTimeCount;

        const { firstEmptyIndex: firstEmptyProductIndex, emptyCount: remainingProductCount } = countFullyEmptyEntities("products");
        firstEmptyProductIndexRef.current = firstEmptyProductIndex;
        remainingProductRowsRef.current = remainingProductCount;
        // console.log("product first empty INDEX:::", firstEmptyProductIndexRef.current);

        const { firstEmptyIndex: firstEmptyCommentIndex, emptyCount: remainingCommentCount } = countFullyEmptyEntities(HEADER_KEYS.COMMENT);
        firstEmptyCommentIndexRef.current = firstEmptyCommentIndex;
        remainingCommentRowsRef.current = remainingCommentCount;

    }, [table_cr_form]);

    return (
        <>
            <div
                // onClick={() => {
                //     console.log("cr_form DATA: ", cr_form);
                //     console.log("table_cr_form DATA: ", table_cr_form);
                // }}
                className="flex flex-row items-center justify-between space-x-2 text-cBodyText text-fs14 font-fw500">
                <div className="max-w-[24px] min-w-[24px] text-center">#</div>

                <div
                    className={`${address_width} `}
                >
                    <HeaderItem
                        onClick={() => pasteInfo(HEADER_KEYS.ADDRESS)}
                        label={t("Address*")}
                    />
                </div>

                <div className={`${floor_width}`}>
                    <HeaderItem
                        onClick={() => pasteInfo(HEADER_KEYS.FLOOR_NUMBER)}
                        label={t("Floor")}
                    />
                </div>
                {user_role === role.company && (
                    <>
                        <div className={`${date_width}`}>
                            <HeaderItem onClick={() => pasteInfo(HEADER_KEYS.DATE)} label={t("Date")} />
                        </div>

                        <div className={time_width}>
                            <HeaderItem
                                onClick={() => pasteInfo(HEADER_KEYS.START_TIME)}
                                label={t("Time")}
                            />
                        </div>
                    </>
                )}

                <div className={`${product_width}`}>
                    <HeaderItem
                        onClick={() => pasteInfo(HEADER_KEYS.PRODUCT)}
                        label={t("Product")}
                    />
                </div>

                <div className={`${comment_width}`}>
                    <HeaderItem
                        onClick={() => pasteInfo(HEADER_KEYS.COMMENT)}
                        label={t("Comment")}
                    />
                </div>
                <div
                    title={t("Remove All Stops")}
                    className="max-w-[38px] min-w-[38px] flex justify-center"
                >
                    <div className="flex  h-[24px] justify-start cursor-pointer"></div>
                </div>
            </div>

            <AddAdditionalStopsModal
                pasteInfo={() => {
                    pasteInfo(propertyData, propertyData);
                }}
                additionalStops={getAdditionalRowsByProperty()}
                totalCopyData={
                    getAdditionalRowsByProperty()
                }
                // totalTableRows={totalTableRowsRef.current}
                totalTableRows={getRemainingRowsByProperty()}
                onCompleteAddRows={() => {
                    remainingCommentRowsRef.current = remainingCommentRowsRef.current + getAdditionalRowsByProperty();
                    remainingFloorRowsRef.current = remainingFloorRowsRef.current + getAdditionalRowsByProperty();
                    remainingDateRowsRef.current = remainingDateRowsRef.current + getAdditionalRowsByProperty();
                    remainingTimeRowsRef.current = remainingTimeRowsRef.current + getAdditionalRowsByProperty();
                    remainingAddressRowsRef.current = remainingAddressRowsRef.current + getAdditionalRowsByProperty();
                    remainingProductRowsRef.current = remainingAddressRowsRef.current + getAdditionalRowsByProperty();
                }}
            />
        </>
    );
};

const HeaderItem = ({
    label,
    onClick,
    paste = true,
    showPosition = "items-start justify-start",
}) => {
    const [isHover, setHover] = useState(false);

    const { t } = useTranslation();
    return (
        <div
            className={` flex justify-start space-x-[2px] items-center space-c flex-row ${showPosition} `}
        >
            <div className="flex items-center justify-center font-normal w-fit text-fs14 text-cGray800">
                {t(label)}
            </div>
            {paste && (
                <Tooltip color={"#242826"} title={t("paste")}>
                    <div
                        className={`${isHover ? "bg-cGray50" : ""
                            } p-[6px] cursor-pointer rounded-full`}
                        onMouseEnter={() => {
                            setHover(true);
                        }}
                        onMouseLeave={() => {
                            setHover(false);
                        }}
                        onClick={onClick}
                    >
                        <img
                            src={iPast}
                            className="text-base cursor-pointer min-w-[15px] max-w-[15px] max-h-[15px] min-h-[16px]"
                            alt=""
                        />
                    </div>
                </Tooltip>
            )}
        </div>
    );
};

const AddAdditionalStopsModal = ({
    pasteInfo,
    additionalStops,
    totalCopyData,
    totalTableRows = 0,
    onCompleteAddRows = () => { },
}) => {
    const {
        table_cr_form,
        addDeliveryTable,
        is_show_AdditionStopAlertModal,
        setShowAdditionStopAlertModal,
    } = useCreateRequestStore();

    const { setLoading } = useGeneralStore();

    const [rowAddOngoing, setRowAddOngoing] = useState(false);

    const { t } = useTranslation();

    const addMore = () => {
        setRowAddOngoing(true);
        try {
            for (let i = 0; i < additionalStops; i++) {
                addDeliveryTable();
            }
            onCompleteAddRows();
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };


    return (
        <CommonModal
            preventClose={rowAddOngoing}
            showCrossIcon={!rowAddOngoing}
            showModal={is_show_AdditionStopAlertModal}
            setShowModal={setShowAdditionStopAlertModal}
            modalTitle={t("Warning")}
            mainContent={
                <>
                    <div className="mt-s20">
                        {/* {`${t("You have copied")} ${totalCopyData} ${t(
                            "items but you have only generated"
                        )} ${totalTableRows} ${t(
                            "rows. Increase"
                        )} ${additionalStops} ${t("rows by clicking below.")}`} */}
                        {/* You have copied 20 items but you have only generated 5 rows. Increase 20 rows by clicking below. */}
                        {`For your additional ${totalCopyData} rows, please increase ${additionalStops} rows by clicking below.`}
                    </div>
                    <div className="flex flex-row justify-end mt-5">
                        <CommonButton
                            btnLabel={rowAddOngoing ? "Please wait.." : `${t("Increase")} ${additionalStops} ${t("stops")}`}
                            isDisabled={rowAddOngoing}
                            onClick={async () => {
                                setRowAddOngoing(true);
                                setLoading(true);
                                const success = addMore();
                                // console.log("add more success: ", success);
                                setRowAddOngoing(false);
                                if (success) {
                                    setLoading(false);
                                    await pasteInfo();
                                    await setShowAdditionStopAlertModal(false);
                                }
                            }}
                            btn_type="submit"
                            width="w-[180px]"
                        />
                    </div>
                </>
            }
        />
    );
};
