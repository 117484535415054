import React from "react";
import { useTranslation } from "react-i18next";
import useCreateRequestStore from "../../app/stores/others/createRequestStore";
import { Toastr } from "../../app/utility/utilityFunctions";
import RequestAccordion from "./RequestAccordion";

const DeliveryAccordion = ({
  optionArray = [],
}) => {

  const {
    setIsInvalidProduct,
    stopData,
    tampDeliveries,
    tampDeliveriesIndex,
    setCrFullForm,
    setTampDeliveriesIndex,
    setTampDeliveries,
    deliveryExpandedAccordion,
    setDeliveryExpandedAccordion,
    cr_form,
    removeDelivery,
    updateCrField,
    isApplyGenerate
  } = useCreateRequestStore();

  const { t } = useTranslation();

  const updateDelivery = (indexToRemove, newDelivery) => {
    // Create a copy of the current deliveries array
    const updatedDeliveries = [...cr_form?.deliveries];
    let deliveryToAdd = newDelivery
    deliveryToAdd.floor_number = newDelivery?.floor_number?.trim()

    // Remove the delivery at the specified index
    if (indexToRemove >= 0 && indexToRemove < updatedDeliveries.length) {
      updatedDeliveries.splice(indexToRemove, 1);
    }

    // Insert the new delivery object at the same index
    updatedDeliveries.splice(indexToRemove, 0, deliveryToAdd);

    // Update the state with the new deliveries array
    setCrFullForm({
      ...cr_form,
      deliveries: updatedDeliveries,
    });
  };

  const handleClear = (index = 0, item = null) => {
    if (tampDeliveriesIndex === index) {
      setTampDeliveries({
        date: "",
        formatted_date: "",
        start_time: "",
        end_time: "",
        delivery_time: "",
        floor_number: "",
        address: "",
        lat: null,
        lng: null,
        comment: "",
        attachment: "",
        attachment_url: "",
        temp_attachment: null,
        products: [{ text: "" }],
        has_old_image: 0,
      })
    } else {
      removeDelivery(index);
      if (cr_form?.removed_stops_ids?.length > 0) {
        updateCrField("removed_stops_ids", [...cr_form?.removed_stops_ids, parseInt(item?.id) ? parseInt(item?.id) : null]);
      } else {
        updateCrField("removed_stops_ids", parseInt(item?.id) ? [parseInt(item?.id)] : []);
      }
    }
  }

  const thirdTitleClickHandler = () => {
    setTampDeliveriesIndex(null)
    setTampDeliveries(null, true)
  }

  const secondaryTitleClickHandler = async (index = 0, item = {}) => {
    setIsInvalidProduct(false)
    if (tampDeliveriesIndex === index) {  //note: update mode codes...

      if (tampDeliveries?.date && !tampDeliveries?.start_time) return Toastr({ message: t("Please fill in the time field!"), type: "warning" });
      if (tampDeliveries?.date && !tampDeliveries?.end_time) return Toastr({ message: t("Please fill in the range field!"), type: "warning" });
      // if (new Date(tampDeliveries?.date) < new Date()) {
      //   return Toastr({ message: t("Invalid Date!"), type: "warning" });
      // }
      // console.log("tampDeliveries", tampDeliveries)
      if (tampDeliveries?.duration && tampDeliveries?.duration < 3) {
        return Toastr({ message: t("Duration can not be less than 3 minutes!"), type: "warning" });
      }
      if (tampDeliveries?.address && tampDeliveries?.lat && tampDeliveries?.lng) {

        console.log("tampDeliveries", tampDeliveries);
        let updatedTempDeliveries = { ...tampDeliveries };

        updatedTempDeliveries.products = updatedTempDeliveries?.products?.map((item) => {
          return {
            ...item, // Spread the existing properties
            text: item.text?.trim() || "Product" // Replace empty text with "Product"
          };
        });


        setTampDeliveriesIndex(null)
        setTampDeliveries(null)
        await updateDelivery(tampDeliveriesIndex, updatedTempDeliveries);
      }
      else if (tampDeliveries?.address && !tampDeliveries?.lat && !tampDeliveries?.lng) {
        return Toastr({ message: t("Address is invalid. Select a valid address from the suggestions."), type: "warning" });
      }
      else {
        return Toastr({ message: t("Please fill in the address field!"), type: "warning" });
      }
    } else {  //note: view mode codes...
      setTampDeliveriesIndex(index)
      // let finalStop = item?.stop;
      // if (finalStop?.products) { if (finalStop?.products?.length === 0) finalStop.products = [{ text: "" }]; }
      // else { finalStop = { ...finalStop, products: [{ text: "" }] }; }
      // console.log("final stop : ", finalStop);
      // setTampDeliveries(finalStop);
      setTampDeliveries(item?.stop?.products ? item?.stop?.products?.length === 0 ? { ...item?.stop, products: [{ text: '' }] } : item?.stop : { ...item?.stop, products: [{ text: '' }] }, true);
    }
  }

  const isCrossVisible =
    !!tampDeliveries?.date ||
    !!tampDeliveries?.start_time ||
    !!tampDeliveries?.duration ||
    !!tampDeliveries?.end_time ||
    !!tampDeliveries?.delivery_time ||
    !!tampDeliveries?.floor_number ||
    !!tampDeliveries?.address ||
    !!tampDeliveries?.comment ||
    !!tampDeliveries?.attachment ||
    stopData?.products.length > 1
  // || !!tampDeliveries?.products?.filter((item) => !!item.text).length;
  return (
    <div className="space-y-5">
      {
        optionArray?.map((itemOriginal, index) => {
          // Calculate the reversed index
          const reversedIndex = optionArray.length - 1 - index;
          let item = { ...itemOriginal, ...itemOriginal?.products?.length === 0 ? { products: [{ text: '' }] } : {} };
          return (
            <RequestAccordion
              onClick={() => {
                console.log("STOP DATA: ", item);
              }}
              isInvalid={isApplyGenerate && (!(item?.lat && item?.lng) || new Date(item?.date) <= new Date(cr_form?.pickup?.date))}
              showCancelRightButton={true}
              clearButtonTooltipMessage={tampDeliveriesIndex !== index ? t("Delete") : t("Clear")}
              showClearButton={tampDeliveriesIndex !== index ? true : isCrossVisible}
              clearHandler={() => handleClear(index, item)}

              thirdTitle={tampDeliveriesIndex === index && t("Cancel")}
              handleThirdTitleClick={thirdTitleClickHandler}

              secondaryTitle={tampDeliveriesIndex === index ? t("Update") : t("Edit")}
              isDisabledSecondaryTitle={cr_form?.pickup?.date ? new Date(tampDeliveries?.date) < (cr_form?.pickup?.date ? new Date(cr_form?.pickup?.date) : new Date()) : false}
              handleSecondaryTitleClick={async () => secondaryTitleClickHandler(index, item)}

              fontSize="text-fs18"
              key={index}
              // title={t("Delivery") + " " + parseInt(reversedIndex + 1)}
              title={t("Delivery") + " " + parseInt(index + 1)}
              isForm={true}
              isBorder={true}
              subTitleTwo={item?.address}
              subTitleOne={itemOriginal?.products?.length + " " + t("products")}
              isExpanded={deliveryExpandedAccordion === index}
              onExpand={() => {
                setDeliveryExpandedAccordion(deliveryExpandedAccordion === index ? null : index)
                setTampDeliveriesIndex(null)
                setTampDeliveries(null, true)
              }}
              handleCancel={() => {
                removeDelivery(index);
                if (cr_form?.removed_stops_ids?.length > 0) {
                  updateCrField("removed_stops_ids", [...cr_form?.removed_stops_ids, parseInt(item?.id)]);
                } else {
                  updateCrField("removed_stops_ids", parseInt(item?.id) ? [parseInt(item?.id)] : []);
                }
              }}
              TransitionProps={{
                timeout: 500
              }}
            >
              {item?.children}
            </RequestAccordion>
          );
        })
      }

    </div>
  );
};

export default DeliveryAccordion;






