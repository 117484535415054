import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import useGeneralStore from '../../app/stores/others/generalStore';

export default function BackLink({
  linksArray = [
    { label: "one", linkTo: "/test/one", linkFn: '' },
    { label: "two", linkTo: "/test/two" }
  ],
  cursorPointer = false
}) {

  const navigateTo = useNavigate();
  const location = useLocation();
  const { isDirty, setIsDirty } = useGeneralStore();

  const checkIsDirty = (hasCheck, withoutReset = false, stepperCallBack) => {
    if (!hasCheck || !isDirty) {
      stepperCallBack();
      return;
    }
    let confirm = false;
    if (isDirty && !confirm) {
      confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?');
    }
    if (isDirty && confirm) {
      if (!withoutReset) {
        setIsDirty(false);
      }
      stepperCallBack()
    }
  }

  return (
    <div
      onClick={() => {
        console.log("BACK LINK ARRAY::", linksArray);
      }}
      className='flex items-center font-normal text-fs14'>
      {
        linksArray?.filter(item => item !== null)?.map((item, index) =>
          item?.label !== "" &&
          (<div key={index}>
            <div key={index} onClick={() => {
              if (item?.isActive) return;
              checkIsDirty(item?.checkDirtyFunction, item?.withoutReset, () => {
                item?.onClick && item?.onClick();
                item?.linkFn && item?.linkFn();
                item?.linkTo && navigateTo(item?.linkTo, { state: item?.state ?? location?.state });
              })
            }}
              className={`${item?.isDisabled ? "cursor-not-allowed" : !item?.isActive ? "cursor-pointer" : ""} capitalize flex items-center `} >
              {index !== 0 ?
                <div className='px-1 text-cGray600'>/</div>
                : ""}
              <div className={`${item?.isActive ? "text-cGray800" : "text-cGray600"}`}>{item?.label}</div>
            </div>
          </div>)
        )
      }
    </div>
  )
}