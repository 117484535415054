/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import useRequestStore from '../../../../../../app/stores/others/requestStore';
import { useTranslation } from 'react-i18next';
import CommonTable from '../../../../../../components/table/CommonTable';
import { FormControlLabel, Radio } from "@mui/material";
import Image from '../../../../../../components/image/Image';
import { iFavCompanyGray, iFavoriteIcon } from '../../../../../../app/utility/imageImports';
import RatingFiveStar from '../../../../../../components/rating/RatingFiveStar';
import BiddingDetailsModal from '../modal/BiddingDetailsModal';
import { useEffect } from 'react';
import { useParams } from "react-router-dom";
import CommonCompanyDetailsModal from '../../../../../../components/modal/CommonCompanyDetailsModal';
import RequestAccordion from '../../../../../../components/Accordion/RequestAccordion';
import useCreateRequestStore from '../../../../../../app/stores/others/createRequestStore';
import { create_request_steps, request_details_types } from '../../../../../../app/utility/const';
import SelectedCompaniesModal from './SelectedCompaniesModal';
import CommonEmptyView from '../../../../../common/createRequest/components/content/selectShift/components/CommonEmptyView';
import useGeneralStore from '../../../../../../app/stores/others/generalStore';


const BiddingReceived = () => {

    const { request_details, setBiddingModal, selectBiddingId, setSelectBiddingId, setSelectedCompaniesModal } = useRequestStore();
    const { expandedAccordion, setExpandedAccordion } = useCreateRequestStore();
    const {isDirty, setIsDirty} = useGeneralStore()

    const [selectIndex, setSelectIndex] = useState(0);

    const params = useParams();
    const { request_id, type } = params;

    const [selectCompanyId, setSelectCompanyId] = useState(null);

    const [showCompanyDetailsModal, setShowCompanyDetailsModal] = useState(false);

    const handleCompanyDetailsShow = async (id) => {
        await setSelectCompanyId(id);
        setShowCompanyDetailsModal(true);
        setSelectedCompaniesModal(false)
    }

    const { t } = useTranslation();

    const headers = [
        { name: t("Name") },
        { name: t("From") },
        { name: t("Budget") },
        { name: t("Action") },
    ];

    useEffect(() => {
        setSelectBiddingId(null);
    }, [request_id])

    const contents = request_details?.biddings?.map((item, index) => {
        return {
            contents: [
                {
                    value:
                        <div className='flex space-x-2 items-center'>
                            <div className='flex items-center justify-center ml-[25px]'>
                                <FormControlLabel
                                    size="small"
                                    checked={selectBiddingId === item?.id}
                                    value={true}
                                    control={<Radio style={{ color: selectBiddingId === item?.id ? '#60B436' : "#939D98", maxWidth: '10px', minWidth: "10px", height: '10px' }} />}
                                    onClick={async (e) => {
                                        e.stopPropagation();
                                        if(!isDirty) setIsDirty(true);
                                        if (selectBiddingId === item?.id) {
                                            await setSelectBiddingId(null)
                                        } else {
                                            await setSelectBiddingId(item?.id)
                                        }
                                    }}
                                />
                            </div>
                            <div className="relative">
                                {(item?.is_favorite || item?.company_info?.is_favorite) ?
                                    <div className="p-[2px] shadow-lg bg-white rounded-full absolute top-[-8px] right-[-8px]">
                                        <img className="z-10" src={iFavoriteIcon} alt="" />
                                    </div>
                                    : <></>
                                }
                                <Image dummyImage={iFavCompanyGray} className="rounded-full border border-cGray300 max-w-[44px] min-w-[44px] h-s44" cursorPointerClass='cursor-pointer' src={item?.company_info?.image} onClick={() => {handleCompanyDetailsShow(item?.company_info?.id)}}/>
                            </div>

                            <div className='flex flex-col justify-center'>
                                <div className='text-cGray800 leading-6 limadi-medium text-fs16 cursor-pointer' onClick={() => {handleCompanyDetailsShow(item?.company_info?.id)}}>{item?.company_info?.name ?? "NA"}</div>
                                <RatingFiveStar rating={Math.round(item?.company_info?.rate)} />
                            </div>
                        </div>,
                    className: "min-h-[70px]"
                },
                { value: item?.is_from_invite ? t('From Invite') : t('From Global'), className: "min-h-[70px]" },
                { value: `DKK ${item?.budget?.toLocaleString("da-DK")}`, className: "min-h-[70px]" },
                {
                    value: "Details", hasClickAction: true, 
                    onClickAction: () => {
                        // e.stopPropagation();
                        setBiddingModal(true)
                        setSelectIndex(index);
                    }, 
                    className: "min-h-[70px] text-cBrand cursor-pointer",
                    hasclickAction: true,
                },
            ],
            clickAction: () => {
                // return console.log("item: ", item);
            }
        }
    })

    return (
        <RequestAccordion
            showClearButton={false}
            titleRightComponent={"Selected Companies (" + request_details?.invitation_data?.length + ")"}
            titleRightComponentOnClick={() => { setSelectedCompaniesModal(true) }}
            title={type === request_details_types.in_bidding ? t("Bid Received") : t("Bidding Details")}
            subTitleOne={request_details?.biddings?.length ? request_details?.biddings?.length + " bid received" : "No bid received"}
            isForm={true}
            isExpanded={expandedAccordion === create_request_steps.bidReceived}
            onExpand={() => { setExpandedAccordion(expandedAccordion === create_request_steps.bidReceived ? null : create_request_steps.bidReceived) }}
        >
            <div className={`${expandedAccordion === create_request_steps.bidReceived ? "pt-5" : "pt-8"}`}>
                {
                    request_details?.biddings?.length ?
                        <CommonTable
                            tableTitleClassName={"sub-title"}
                            emptyDataMessage={t("No bids have been received yet.")}
                            showSearchBox={false}
                            sort={false}
                            showTopRightFilter={false}
                            showTakeOption={false}
                            showPagination={false}
                            showPageCountText={true}
                            headers={headers}
                            tableHeaders={headers}
                            tableContents={contents}
                            widths="auto 20% 20% 20%"
                            hasClickFunction={false}
                            outerPadding="p-s0"
                            topRightFilterComponentOnClick={() => { }}
                            paginationObject={{}}
                            withClearSearch={false}
                            onSearchClear={() => { }}
                            searchValue={""}
                            searchOnChange={(e) => { }}
                            currentTakeAmount={10}
                            withReloader={true}
                            onReload={() => { }}
                            filtered={false}
                            firstIndexColPosition={"items-center justify-start"}
                            headersFirstIndexFixApply={false}
                            takeOptionOnChange={async (e) => { }}
                            paginationOnClick={async (url) => { }}
                        />
                        :
                        <CommonEmptyView message={t("No bids have been received yet.")} />
                }


                <SelectedCompaniesModal openOnClick={handleCompanyDetailsShow}/>
                {request_details?.biddings?.length > 0 ? <BiddingDetailsModal data={request_details?.biddings[selectIndex]} /> : ""}
                <CommonCompanyDetailsModal company_id={selectCompanyId} showModal={showCompanyDetailsModal} setShowModal={setShowCompanyDetailsModal} />
            </div>
        </RequestAccordion>
    );
};

export default BiddingReceived;