import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useCreateRequestStore, {
  isCrFormValidation,
  isCrFormValidationWithAddress,
  saveRequest,
} from "../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../app/stores/others/generalStore";
import { k_create_request_paths } from "../../../../../../app/utility/AllRoute";
import {
  create_request_path,
  create_request_steps,
  k_cr_actions,
  k_request_paths,
  user_role as role
} from "../../../../../../app/utility/const";
import { Toastr } from "../../../../../../app/utility/utilityFunctions";
import CommonButton from "../../../../../../components/button/CommonButton";
import CommonButtonOutlined from "../../../../../../components/button/CommonButtonOutlined";
import RequestDeleteConfirmModal from "../../../../requests/requestDetails/components/requestDetailsTopBar/components/modals/RequestDeleteConfirmModal";
import useRequestStore from "../../../../../../app/stores/others/requestStore";
import useShiftStore from "../../../../../../app/stores/company/shiftStore";

export default function Actions() {
  const {
    setCurrentSetup,
    setExpandedAccordion,
    setApplyGenerate,
    setShowRequestConfirmModal,
    current_step,
    cr_form,
    changeCrForm,
    setDeliveryInvalidIndex,
    crFormSelectedShift,
    setCrFormSelectedShift,
    selected_companies
  } = useCreateRequestStore();
  const { request_details, setFetchReqDetails } = useRequestStore()
  const { shiftDetailsData } = useShiftStore()
  const { user_role, isDirty, setIsDirty } = useGeneralStore();
  const { t } = useTranslation();

  const isCompany = user_role === role.company;

  const { shift_id, request_id } = useParams();

  const location = useLocation();

  const [showDeleteModal, setDeleteModal] = useState(false);

  const navigateTo = useNavigate();

  const getCancelLink = (status) => {
    if (
      status === create_request_steps.pickup ||
      status === create_request_steps.stops ||
      status === create_request_steps.proposal ||
      status === create_request_steps.select_shift ||
      status === create_request_steps.select_company ||
      status === "init"
    ) {
      return k_request_paths.saved + `/details/${request_id}`;
    } else if (cr_form.pickup_status === "in_bidding") {
      return k_request_paths.in_bidding + `/details/${request_id}`;
    } else {
      return k_request_paths.saved + `/details/${request_id}`;
    }
  };

  const isAllShift = current_step === create_request_steps.allShifts || location?.pathname?.includes("all-shift");
  const isEditPage = window.location.pathname === "/request/edit/" + request_id;

  const isActive = isCrFormValidation();

  const handleGenerate = () => {
    if (cr_form?.pickup?.duration && cr_form?.pickup?.duration < 3) {
      Toastr({
        message: t("Pickup duration can not be less than 3 minutes"),
        type: "warning",
      });
      return;
    }
    setApplyGenerate(true);
    setExpandedAccordion(null);
    let invalidDateIndices = cr_form?.deliveries
      .map((delivery, index) => {
        if (new Date(delivery.date) < new Date(cr_form?.pickup?.date)) {
          return index;
        }
      })
      .filter(index => index !== undefined);

    setDeliveryInvalidIndex(invalidDateIndices)
    if (isCompany && invalidDateIndices?.length > 0) {
      return console.log("invalidDateIndices", invalidDateIndices);
    }


    if (!isCrFormValidationWithAddress().generate) {
      return console.log("isCrFormValidationWithAddress().generate", isCrFormValidationWithAddress().generate);
      ;
    }

    if (!isActive.generate) {
      Toastr({
        message: t("Please fill up all required fields"),
        type: "warning",
      });
      return;
    }

    setShowRequestConfirmModal(true);

    let extraData = {};
    if (user_role === role.customer && location?.pathname?.includes("request/edit")) {
      let selectedCompanyIds = selected_companies?.map(item => item?.id)
      let removedCompanyId = request_details?.invitation_ids?.filter(item => !selectedCompanyIds?.includes(item))

      extraData.removed_company_user_ids = removedCompanyId
    }

    saveRequest(k_cr_actions.save, false, extraData);
  };

  const handleRequestSave = async () => {
    let extraData = {};
    if (user_role === role.customer && location?.pathname?.includes("request/edit")) {
      let selectedCompanyIds = selected_companies?.map(item => item?.id)
      let removedCompanyId = request_details?.invitation_ids?.filter(item => !selectedCompanyIds?.includes(item))

      extraData.removed_company_user_ids = removedCompanyId
    }

    const success = await saveRequest(k_cr_actions.save, true, extraData)
    if (success) {
      setIsDirty(false);
      if (isEditPage) {
        if (location?.state?.referrer === "in-bidding" && !isCompany) navigateTo(`/requests/saved`);
        else navigateTo(`/requests/${location?.state?.referrer}/details/${request_id ?? location?.state?.request_id}`, { state: { ...location?.state, isAllShiftExists: false, isEditMode: false } });
      }
      else navigateTo(`/requests/saved`);
      // navigateTo(getCancelLink(cr_form?.pickup_status))
    }
  }

  return (
    <div onClick={() => {
      console.log("isActive", isActive);
    }} className="flex flex-row justify-end space-x-4">
      <RequestDeleteConfirmModal
        navigate={create_request_path}
        showModal={showDeleteModal}
        setShowModal={setDeleteModal}
        request_id={cr_form?.id}
      />

      {location.pathname === k_create_request_paths.shift_details + shift_id ? (
        location?.state?.isEditMode && <>
          <CommonButtonOutlined
            height="h-11"
            colorType="danger"
            btnLabel={t("Cancel")}
            onClick={() => {
              setCurrentSetup(null);
              setFetchReqDetails(false)
              console.log("location state: ", location?.state);
              if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") return navigateTo(`/request/edit/${request_id ?? location?.state?.request_id}`, { state: { ...location?.state, is_fetch_req_details: false, isAllShiftExists: false } })
              else if (location?.state?.referrer === "not-planned") return navigateTo(`/requests/${location?.state?.referrer}/details/${request_id ?? location?.state?.request_id}/edit`, { state: { ...location?.state, is_fetch_req_details: false, isAllShiftExists: false } })
              else if (location?.state?.referrer === "global-request") return navigateTo(`/global-request/details/${request_id ?? location?.state?.request_id}`, { state: { ...location?.state, is_fetch_shift_details: false, isAllShiftExists: false } })
              navigateTo(k_create_request_paths.create);
            }}
          />

          <CommonButton
            className="h-11"
            btnLabel={
              parseInt(crFormSelectedShift) === parseInt(shift_id)
                ? t("Unselect shift ")
                : t("Select Shift")
            }
            onClick={async () => {
              console.log(cr_form?.shift_id, shift_id);
              // return
              if (parseInt(crFormSelectedShift) == parseInt(shift_id)) {
                console.log("Unselect shift running...");

                await changeCrForm("shift_plan", null);
                await changeCrForm("shift_id", null);
                setCrFormSelectedShift(null)
                if (!isDirty) setIsDirty(true)

              } else {
                console.log("Select shift running...");

                await changeCrForm("shift_plan", { id: parseInt(shift_id), driver_name: shiftDetailsData?.driver_user?.name });
                await changeCrForm("shift_id", parseInt(shift_id));
                setCrFormSelectedShift(parseInt(shift_id))
                if (!isDirty) setIsDirty(true)
              }
              setFetchReqDetails(false)
              setCurrentSetup(null);
              if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") return navigateTo(`/request/edit/${request_id ?? location?.state?.request_id}`, { state: { ...location?.state, is_fetch_req_details: false, isAllShiftExists: false } })
              else if (location?.state?.referrer === "not-planned") return navigateTo(`/requests/${location?.state?.referrer}/details/${request_id ?? location?.state?.request_id}/edit`, { state: { ...location?.state, is_fetch_shift_details: false, is_fetch_req_details: false, isAllShiftExists: false } })
              else if (location?.state?.referrer === "global-request") return navigateTo(`/global-request/details/${request_id ?? location?.state?.request_id}`, { state: { ...location?.state, is_fetch_shift_details: false, isAllShiftExists: false } })
              navigateTo(k_create_request_paths.create);
            }}
            width="w-[120px]"
          />
        </>
      ) : (
        <>
          {isAllShift ? (
            <></>
          ) : (
            <>
              {location.pathname === k_create_request_paths.create ? (
                <>
                  {cr_form?.id && (
                    <CommonButtonOutlined
                      height="h-11"
                      colorType="danger"
                      onClick={() => {
                        console.log("CHECK");

                        setDeleteModal(true)
                      }}
                      width="w-[120px]"
                      btnLabel={t("Delete")}
                    />
                  )}
                </>
              ) : (
                <CommonButtonOutlined
                  height="h-11"
                  colorType="danger"
                  onClick={async () => {
                    if (location?.state?.referrer === "saved" || location?.state?.referrer === "awarded") {
                      // setIsDirty(false);
                      let confirm = false
                      if (isDirty && !confirm) {
                        confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?')
                      }

                      if (isDirty && confirm) {
                        setIsDirty(false);
                        setFetchReqDetails(true);
                        return navigateTo(`/requests/${location?.state?.referrer}/details/${location?.state?.request_id}`, { state: { ...location?.state, isEditMode: false, isAllShiftExists: false } });
                      }

                      else if (!isDirty) {
                        setFetchReqDetails(true);
                        return navigateTo(`/requests/${location?.state?.referrer}/details/${location?.state?.request_id}`, { state: { ...location?.state, isEditMode: false, isAllShiftExists: false } });
                      }


                    }

                    const link = await getCancelLink(cr_form?.pickup_status);
                    navigateTo(link);
                  }}
                  width="w-[120px]"
                  btnLabel={t("Cancel")}
                />
              )}

              {location?.state?.referrer === "awarded" ?
                <CommonButton
                  className="h-11"
                  btnLabel="Update"
                  isDisabled={!cr_form?.shift_id}
                  onClick={async () => {
                    console.log("update cr_form", cr_form);
                    // return 
                    if (cr_form?.pickup?.duration && cr_form?.pickup?.duration < 3) {
                      return Toastr({ message: "Pickup duration can not be less than 3 minutes", type: "warning" })
                    }

                    let extraData = {};
                    if (user_role === role.customer && location?.pathname?.includes("request/edit")) {
                      let selectedCompanyIds = selected_companies?.map(item => item?.id)
                      let removedCompanyId = request_details?.invitation_ids?.filter(item => !selectedCompanyIds?.includes(item))

                      extraData.removed_company_user_ids = removedCompanyId
                    }

                    const success = await saveRequest(k_cr_actions.generate, true, extraData)

                    if (success) {
                      setIsDirty(false)
                      navigateTo(`/requests/awarded/details/${request_id}`)
                    };
                  }}
                />
                :
                <>

                  <CommonButtonOutlined
                    height="h-11"
                    onClick={handleRequestSave}
                    width="w-[120px]"
                    btnLabel={t("Save")}
                  />

                  <CommonButton
                    className="h-11"
                    width="w-[120px]"
                    btnLabel={t("Generate")}
                    onClick={handleGenerate}
                    isDisabled={!isActive.generate}
                  />
                </>

              }
            </>
          )}
        </>
      )}
    </div>
  );
}
