/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Toastr } from "../../app/utility/utilityFunctions";
import { iImageUploadIcon } from "../../app/utility/imageImports";
import ImageUploadViewModal2 from "./ImageUploadViewModal2";
import { VALID_IMAGE_AND_PDF_TYPES, VALID_IMAGE_TYPES, base_url_src } from "../../app/utility/const";
import { useTranslation } from "react-i18next";
import CancelImageBtn from "../image/CancelImageBtn";

export default function ImageUpload({
    setImage,
    FileData,
    onChange = () => { },
    finalBase64 = () => { },
    imageUploader,
    imageName = "",
    image_url = null,
    src,
    setSrc,
    title = "Attachment",
    imageUploadIcon = null,
    addButtonClassName = "",
    width = "w-[150px]",
    showInfoText = false,
    textStyle = "text-fs14 font-fw400 text-cMainBlack",
    isClearedImage = false
}) {
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB in bytes
    const [show_modal, setShowModal] = useState(false);
    const [can_upload, setCanUpload] = useState(false);
    const [extensionType, setExtensionType] = useState("");
    const { t } = useTranslation();

    const inputFile = useRef(null);

    const onButtonClick = () => inputFile.current.click();

    const fileType = (type) => {
        return type?.split("/")?.pop()?.toLowerCase()
    }

    const onChangeFile = (event) => {
        event.preventDefault();
        const file = event.target.files[0];

        if (file) {
            if (!VALID_IMAGE_TYPES.includes(file.type)) {
                Toastr({ message: t("Invalid file type. Only jpeg, png, jpg files are allowed."), type: "warning" });
                return;
            }
            if (file.size > MAX_FILE_SIZE) {
                Toastr({ message: t("File size exceeds the limit of 5MB."), type: "warning" });
                return;
            }
            setExtensionType(fileType(file?.type))

            event.stopPropagation();
            event.preventDefault();
            const reader = new FileReader();
            reader.onloadend = async function () {
                setSrc(reader.result);
                finalBase64(reader.result);
                FileData && (await FileData("icon", reader.result));
                imageUploader && (await imageUploader(imageName, reader.result));
                setImage && (await setImage(reader.result));
                setCanUpload(false);
            };
            reader.onload = (event) => {
                const image = new Image();
                image.src = event.target.result;
            };

            reader.readAsDataURL(file);
            onChange(file);
        }
    };

    useEffect(() => {
        if (!src) {
            inputFile.current.value = null;
        }
    }, [src, image_url]);

    return (
        <div>
            {((src || image_url) && !can_upload) ? (
                <div className={`bg-cMainWhite flex ${width} rounded-br4 items-center gap-3 ${addButtonClassName}`}>
                    <input
                        onChange={onChangeFile.bind(this)}
                        type="file"
                        id="file"
                        accept="image/jpeg,image/png,image/jpg"
                        ref={inputFile}
                        style={{ display: "none" }}
                    />

                    <div
                        onClick={() => {
                            if ((src || image_url) && !can_upload) {
                                setShowModal(true)
                            } else { onButtonClick() }
                        }}
                        className="flex items-center gap-2 cursor-pointer"
                    >
                        <img
                            src={imageUploadIcon ? imageUploadIcon : iImageUploadIcon}
                            alt="upload a pic"
                            className="w-[15px] h-[15px] ml-s5"
                        />
                        <div className={`${textStyle}`}>{extensionType ? t("Attachment") + "." + extensionType : t("Attachment")} </div>

                    </div>

                    <div className="flex cursor-pointer">
                        <CancelImageBtn 
                            onClick={() => {
                                inputFile.current.value = null;
                                setSrc(null);
                                setCanUpload(true);
                                setExtensionType("")
                            }}
                            iconSize="max-w-[12px] min-w-[12px] max-h-[12px] min-h-[12px]"
                        />
                    </div>
                </div>
            ) : (

                <div className={`bg-cMainWhite ${width} rounded ${addButtonClassName}`}>
                    <input
                        onChange={onChangeFile.bind(this)}
                        type="file"
                        id="file"
                        accept="image/jpeg,image/png,image/jpg"
                        ref={inputFile}
                        style={{ display: "none" }}
                    />

                    <div
                        onClick={onButtonClick}
                        className="flex space-x-2 cursor-pointer"
                    >
                        <div className="flex items-center justify-center">
                            <img
                                src={imageUploadIcon ? imageUploadIcon : iImageUploadIcon}
                                alt="upload a pic"
                                className="w-[15px] h-[15px] ml-s5"
                            />
                        </div>
                        <div className={`${textStyle} mr-s5`}>{t(title)} </div>
                    </div>
                </div>
            )}
            {showInfoText && <p className="mt-2 leading-5 text-fs12 font-fw400 text-cGray500">{t("Jpg & Png file with 5mb max file size")}</p>}

            <ImageUploadViewModal2 fileName={t("Attachment")} show_modal={show_modal} setShowModal={setShowModal} url={src ?? image_url} src={src ?? (base_url_src + image_url)} />
        </div>
    );
}