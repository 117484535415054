/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import useCarStore, { licensePlateNumberAvailable } from '../../../../app/stores/company/carStore';
import { useTranslation } from 'react-i18next';
import CommonButton from '../../../../components/button/CommonButton';
import CommonInput from '../../../../components/input/CommonInput';
import CommonTitle from '../../../../components/title/CommonTitle';
import SquareImageUploader from '../../../../components/imageUpload/SquareImageUploader';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toastr, countEmptySpaces } from '../../../../app/utility/utilityFunctions';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import BackLink from '../../../../components/backLink/BackLink';

const AddCar = () => {

    const { addCarForm, setAddCarForm, setCarDetails } = useCarStore();

    const { setPathRecord, isDirty, setIsDirty } = useGeneralStore();

    const [isLicenseAvailable, setIsLicenseAvailable] = useState(true);

    const { t } = useTranslation();

    const navigateTo = useNavigate();

    const location = useLocation();

    const handleSubmit = async () => {
        if (!addCarForm?.name) {
            return Toastr({ message: t("Name is required."), type: "warning" });
        } else if (addCarForm?.name?.trim()?.length < 3) {
            return Toastr({ message: t("Name must be at least 3 characters."), type: "warning" });
        }
        else if (!addCarForm?.car_license_plate_number) {
            return Toastr({ message: t("License number is required."), type: "warning" });
        } else if (addCarForm?.car_license_plate_number?.length > 9) {
            return Toastr({ message: t("License number max 9 characters"), type: "warning" });
        } else if (!addCarForm?.comment || countEmptySpaces(addCarForm?.comment)) {
            return Toastr({ message: t("Car Specification is required."), type: "warning" });
        }
        const available = await licensePlateNumberAvailable(addCarForm?.car_license_plate_number)
        if (!available) return Toastr({ message: t("The car license plate number has already been taken."), type: "warning" })
        setCarDetails({ id: null })
        navigateTo("/car-manager/add/license");
    };

    useEffect(() => { setPathRecord(location.pathname); }, [location.pathname]);

    const allInputValid = () => {
        return addCarForm?.name?.trim()?.length > 2 && addCarForm?.car_license_plate_number && addCarForm?.comment;
    }

    return (
        <div>

            <div className='flex justify-between'>
                <div>
                    <CommonTitle
                        withConfirm={true}
                        title={t("Add Car")}
                        link={"/car-manager"}
                        icon={true}
                    />
                    <div className='mt-2 mb-5'>
                        <BackLink
                            linksArray={[
                                { label: t("Add Car"), isActive: true, linkFn: '' },
                                { label: t("Choose License"), onClick: () => allInputValid() && handleSubmit(), isDisabled: !allInputValid() }
                            ]}
                        />
                    </div>
                </div>

                <div className='flex justify-end space-x-5'>
                    <CommonButtonOutlined
                        onClick={() => {
                            let confirm = false;
                            if (isDirty && !confirm) {
                                confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?')
                            }

                            if (isDirty && confirm) {
                                setIsDirty(false);
                                navigateTo("/car-manager");
                            }

                            else if (!isDirty) {
                                navigateTo("/car-manager");
                            }
                        }}
                        colorType='danger' btnLabel={t('Cancel')}
                        width={'w-[120px]'}
                    />
                    <CommonButton
                        isDisabled={
                            !addCarForm?.name ||
                            addCarForm?.name?.trim()?.length < 3 ||
                            !addCarForm?.car_license_plate_number ||
                            !addCarForm?.comment?.trim() ||
                            !isLicenseAvailable ||
                            addCarForm?.car_license_plate_number.length < 2
                        }
                        width={'w-[120px]'}
                        type='submit'
                        btnLabel={t('Next')}
                        onClick={handleSubmit}

                    />
                </div>
            </div>

            <div className='flex space-x-10'>
                <div className='w-full'>
                    <SquareImageUploader
                        iImage={addCarForm?.image ?? null}
                        onChange={(e) => { setAddCarForm({ ...addCarForm, image: e }); if (!isDirty) setIsDirty(true); }}
                        clearImage={() => { setAddCarForm({ ...addCarForm, image: null }) }}
                        rounded={"!rounded-full"}
                    />
                    <div className='mt-5 sub-title'>{t("Details")}</div>

                    <form className='mt-4' onSubmit={(e) => e.preventDefault()}>

                        <div className='flex justify-between space-x-5'>
                            <CommonInput
                                labelText={t('Car Name')}
                                value={addCarForm?.name}
                                name={'name'}
                                min={3}
                                max_input={55}
                                type='text'
                                required={true}
                                show_asterisk={true}
                                onChange={(e) => {

                                    if (e.target.value !== " ") {
                                        const trimmedValue = e.target.value.replace(/\s+/g, " ");
                                        setAddCarForm({ ...addCarForm, name: trimmedValue })
                                    }
                                    if (!isDirty) setIsDirty(true);
                                }}
                            />

                            <CommonInput
                                labelText={t('Car License Number')}
                                value={addCarForm?.car_license_plate_number}
                                max_input={9}
                                name={'license'}
                                required={true}
                                show_asterisk={true}
                                onChange={async (e) => {
                                    let value = e.target.value;
                                    if (value !== " ") {
                                        setAddCarForm({ ...addCarForm, car_license_plate_number: value.replace(/\s+/g, " ") })
                                    }
                                    if (!isDirty) setIsDirty(true);
                                }}
                            />
                        </div>
                        <CommonInput
                            textarea={true}
                            rows={1}
                            max_input={255}
                            labelText={t('Car Specification')}
                            value={addCarForm?.comment}
                            name={'details'}
                            required={true}
                            show_asterisk={true}
                            onChange={(e) => {
                                setAddCarForm({ ...addCarForm, comment: e.target.value });
                                if (!isDirty) setIsDirty(true);
                            }} />
                    </form>
                </div>

                <div className='max-w-[380px] min-w-[380px]'></div>

            </div>
        </div>
    )
}

export default AddCar
