/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRequestDetailsPathChecks } from "../../../../../../app/hooks/useRequestDetailsPathChecks";
import useCreateRequestStore, {
  editRequest,
} from "../../../../../../app/stores/others/createRequestStore";
import useGeneralStore from "../../../../../../app/stores/others/generalStore";
import useRequestStore, {
  assignNotPlannedRequestShift,
  awardRequest,
  downloadRequest,
  getRequestDetails,
  handleSubmitPlaceBid,
  stopTimeDurationSet,
  submitOrUpdateRequestInvitationBidding,
  updateAwardedRequestFromCustomer,
  writeReview,
} from "../../../../../../app/stores/others/requestStore";
import useUtilityStore from "../../../../../../app/stores/others/utilityStore";
import { kuGetRequestsNew } from "../../../../../../app/urls/commonUrl";
import {
  BUTTON_WIDTH,
  k_arcd_status,
  k_request_action,
  k_request_paths,
  k_requests_status,
  request_details_types,
  user_role as role
} from "../../../../../../app/utility/const";
import { changePageTitle, isFutureDateTime, isTimeExpired, isWithinTimeLimit, removeHyphensFromString, Toastr } from "../../../../../../app/utility/utilityFunctions";
import ActionButton from "../../../../../../components/button/ActionButton";
import CommonButton from "../../../../../../components/button/CommonButton";
import CommonButtonOutlined from "../../../../../../components/button/CommonButtonOutlined";
import CommonTopTitleSection from "../../../../../../components/title/CommonTopTitleSection";
import DeclineInvitationConfirmModal from "../summary/components/modals/DeclineInvitationConfirmModal";
import CompletedTopBar from "./components/CompletedTopBar";
import CancelInBiddingRequest from "./components/modals/CancelInBiddingRequest";
import ConfirmBiddingRequestEditModal from "./components/modals/ConfirmBiddingRequestEditModal";
import RatingAndReviewModal from "./components/modals/RatingAndReviewModal";
import RequestActionsModal from "./components/modals/RequestActionsModal";
import RequestDeleteConfirmModal from "./components/modals/RequestDeleteConfirmModal";
import Stepper from "./components/Stepper";
import useLayoutStore from "../../../../../../app/stores/others/layoutStore";

export default function RequestDetailsTopBar({
  SectionTitle = "Request Details",
}) {
  const params = useParams();
  const { request_id, type, shift_id } = params;
  const navigateTo = useNavigate();
  const { user_role } = useGeneralStore();
  const {
    showDeclineInvitationConfirmModal,
    available_shifts,
    selected_shift_index,
    setRequestApiUrl,
    inBiddingCheck,
    request_details,
    selectBiddingId,
    setShowDeclineInvitationConfirmModal,
    setShowRequestTableViewModal,
    isVisitNotPlanedShift,
    setVisitNotPlanedShift,
    selectedShift,
    readyToReview,
    setReadyToReview,
    place_bid_form,
    pickup_duration,
    updatePlaceBidForm,
    setFetchReqDetails,
  } = useRequestStore();

  const {
    setCurrentSetup,
    crFormSelectedShift,
    setSearchCompanyKey,
    setRate,
    setFavSelected,
    resetStopData,
    setSelectedCompanies,
    setCrFormSelectedShift,
    setIsCompanyChosen,
  } = useCreateRequestStore();
  const { awarded } = request_details;
  const { t } = useTranslation();
  const location = useLocation();

  const { setShowCommonShareModal } = useUtilityStore();
  const { setActiveSection } = useLayoutStore();
  const { isDirty, setIsDirty } = useGeneralStore()

  const [show_request_delete_confirm_modal, setShowRequestDeleteConfirmModal] =
    useState(false);
  const [show_delete_bidding_request_modal, setShowDeleteBiddingRequestModal] =
    useState(false);
  const [show_request_actions_modal, setShowRequestActionsModal] =
    useState(false);

  const [show_rating_and_review_modal, setShowRatingAndReviewModal] =
    useState(false);
  const [
    show_edit_bidding_request_confirm_modal,
    setShowEditBiddingRequestConfirmModal,
  ] = useState(false);
  const [action, setAction] = useState("");
  const [title, setTitle] = useState("");
  const [sub_title, setSubTitle] = useState("");

  const [declinePopupText, setDeclinePopupText] = useState({
    title: "",
    submitButtonText: "",
  });
  const [isUpcomingEditable, setIsUpcomingEditable] = useState(false);

  const editMode =
    window.location.pathname === `/requests/${type}/details/${request_id}`;

  const isEditingNow = window.location.pathname === `/requests/${type}/details/${request_id}/edit`;

  const {
    request_details_path,
    isUpDateProposalPath,
    isAvailableShifts,
    detailsPath,
    isDetailsPath,
    isEditPath,
    isProposalPath,
    isShiftDetailsPath,
    isBudgetPath,
    isPlaceBidPath,
    isAssignNotPlannedRequestShiftPath,
    isAcknowledgementPath,
  } = useRequestDetailsPathChecks(type, request_id, shift_id);

  const getCancelAction = () => ({
    title: t("Cancel"),
    action: () => {
      setTitle(t("Cancel Request"));
      setSubTitle(
        t(
          "Are you sure you want to cancel this request? By doing so, two things will happen: 1. The request will move to 'In Bidding'. 2. Any awards to companies will be cancelled. You will need to update your pickup or delivery date to receive new bids from other companies."
        )
      );
      setAction("cancel");
      setShowRequestActionsModal(true);
    },
  });

  const getRejectAction = () => {
    if (
      user_role === role.company &&
      type === "awarded" &&
      awarded?.status === k_arcd_status.init &&
      request_details?.user?.role !== role.company &&
      request_details?.start_in >= 2 &&
      request_details?.user?.role === "private"
    ) {
      return {
        title: t("Cancel"),
        action: () => {
          setTitle(t("Cancel Request"));
          setSubTitle(t("Are you sure want to cancel this request?"));
          setAction("reject");
          setShowRequestActionsModal(true);
        },
      };
    } else {
      return;
    }
  };

  const getDeleteAction = () => ({
    title: t("Delete"),
    action: () => {
      setTitle(t("Delete Request"));
      setSubTitle(t("Are you sure you want to delete this request?"));
      setAction("delete");
      setShowRequestActionsModal(true);
    },
  });

  const getDeleteConfirmAction = () => ({
    title: t("Delete"),
    action: () => {
      setShowRequestDeleteConfirmModal(true);
    },
  });

  const getShareAction = (button = false) =>
    button ? (
      <CommonButtonOutlined
        width={BUTTON_WIDTH}
        onClick={async () => {
          await setShowCommonShareModal(true);
        }}
        btnLabel={t("Share")}
      />
    ) : (
      {
        title: t("Share"),
        action: () => {
          setShowCommonShareModal(true);
        },
      }
    );

  const getDownloadAction = (button = false) =>
    button ? (
      <CommonButtonOutlined
        onClick={async () => {
          await downloadRequest(request_id);
        }}
        width={BUTTON_WIDTH}
        btnLabel={t("Download")}
      />
    ) : (
      {
        title: t("Download"),
        action: async () => {
          await downloadRequest(request_id);
        },
      }
    );

  const getAwardedEdit = () => {
    if (request_details?.user?.role === role.company) {
      return {
        title: t("Edit"),
        action: async () => {
          const res = await editRequest(request_id);
          res && navigateTo(`/request/edit/${request_id}`);
        },
      };
    } else if (request_details?.user?.role === role.customer) {
      return {
        title: t("Edit"),
        action: async () => {
          navigateTo(`/requests/${type}/details/${request_id}/edit`);
        },
      };
    } else {
      return [];
    }
  };

  const { req_details_path } = useGeneralStore();


  const submitRating = async () => {
    try {
      const success = await writeReview(request_details?.id, request_details?.awarded?.company_user_id, readyToReview?.rating, readyToReview?.comment);
      if (success) {
        setIsDirty(false)
        await getRequestDetails(type, request_details?.id);
        setReadyToReview(null);
      } else {
        // Show a toast notification for unsuccessful review submission
        Toastr({ message: 'Failed to submit review', type: "warning" });
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (request_details?.user?.user_role === "company")
      setIsUpcomingEditable(true);
    else if (type === request_details_types.awarded && request_details?.pickup_date && request_details?.pickup_start_time && isFutureDateTime(request_details?.pickup_date + "T" + request_details?.pickup_start_time))
      setIsUpcomingEditable(true);
    else setIsUpcomingEditable(false);
  }, [request_details]);


  const handleBidUpdate = async () => {
    if (location.pathname.includes(`/requests/awarded/details/${request_id}/edit`)) {
      if (pickup_duration && pickup_duration < 3) return Toastr({ message: "Pickup duration can not be less than 3 minutes", type: "warning" })
      if (!crFormSelectedShift) {
        return Toastr({ message: "Please select a shift!", type: "warning" });
      }
      const success = await updateAwardedRequestFromCustomer(request_details?.id, request_details)
      if (success) {
        await getRequestDetails(type, request_id);
        setIsDirty(false)
        navigateTo(request_details_path);
      }
    }
    else {
      const success = await
        submitOrUpdateRequestInvitationBidding(
          "update",
          request_details?.my_bid?.id
        );
      if (success) {
        await getRequestDetails(type, request_id);
        setIsDirty(false)
        navigateTo(request_details_path);
      }
    }
  }

  const getPageTitle = () => {
    if (type === "awarded") return "Upcoming";
    if (type === "on-going") return "Ongoing";
    if (type === "history" && user_role === role?.customer) return "Completed";
    else return `${removeHyphensFromString(type)}`
  }

  return (
    <div
      onClick={() => {
        console.log("location?.state:::::", location?.state, " __type::::", type, " __request_id::::", request_id);
      }}
    >
      <CommonTopTitleSection
        PaddingComponent={<Stepper />}
        title={getPageTitle()}
        withBackLink={location?.state?.referrer === "global-request" ? "/global-request" : req_details_path ?? `/requests/${type}`}
        rightSideComponent={
          <div className="flex space-x-4">
            {/* {user_role === role.customer &&
              type === request_details_types.history &&
              !request_details?.is_rated &&
              request_details?.status === request_details_types.history && (
                <CommonButton
                  width="w-[160px]"
                  btnLabel={t("Rate Company")}
                  onClick={() => setShowRatingAndReviewModal(true)}
                />
              )} */}

            {user_role === role.company &&
              ((type === request_details_types.not_planned &&
                (isEditPath ||
                  isAssignNotPlannedRequestShiftPath ||
                  (isProposalPath && isVisitNotPlanedShift))) ||
                (type === request_details_types.in_bidding &&
                  (isEditPath || isAvailableShifts || isUpDateProposalPath)) ||
                (type === request_details_types.awarded &&
                  (isEditPath ||
                    isAssignNotPlannedRequestShiftPath ||
                    (isProposalPath && isVisitNotPlanedShift))
                  && !location.pathname.includes("edit/shift"))) ? (
              <>
                <CommonButtonOutlined
                  colorType="danger"
                  width="w-[120px]"
                  onClick={() => {
                    const normalClickFunction = () => {
                      changePageTitle("Limadi | Request Details");
                      setVisitNotPlanedShift(false);
                      if (user_role === role.company && (type === request_details_types.in_bidding) && (request_details?.user?.role === role.customer)) {
                        updatePlaceBidForm({ budget: request_details?.my_bid?.budget, description: request_details?.my_bid?.details, customer_name: request_details?.my_bid?.customer_name ?? request_details?.user?.name });
                      } else if (type === request_details_types.invitation && user_role === role.company) {
                        updatePlaceBidForm({ ...place_bid_form, description: "", budget: "", customer_name: request_details?.user?.name });
                      }
                      setIsDirty(false)
                      setFetchReqDetails(true)
                      navigateTo(request_details_path);
                    }

                    let confirm = false;
                    if (isDirty && !confirm) {
                      confirm = window.confirm('You have unsaved changes. Do you want to leave without saving?')
                    }

                    if (isDirty && confirm) {
                      setIsDirty(false);
                      normalClickFunction();
                    }

                    else if (!isDirty) {
                      normalClickFunction();
                    }

                  }}
                  btnLabel={t("Cancel")}
                />
              </>
            ) : (
              <></>
            )}

            {user_role === role.customer &&
              type === request_details_types.history ? (
              <>
                {!request_details?.is_rated
                  && request_details?.acknowledge
                  ? (
                    <>
                      {getShareAction(true)}
                      {getDownloadAction(true)}
                      <CommonButton
                        width="w-[135px]"
                        btnLabel={t("Rate Company")}
                        isDisabled={(readyToReview?.comment && readyToReview?.rating > 0) ? false : true}
                        onClick={() => submitRating()}
                      />
                    </>
                  ) : (
                    <>
                      {getShareAction(true)}
                      {getDownloadAction(true)}
                    </>
                  )}
              </>
            ) : null}

            {type === request_details_types.saved && !shift_id && (
              <>
                <CommonButtonOutlined
                  width={BUTTON_WIDTH}
                  onClick={() => setShowRequestDeleteConfirmModal(true)}
                  btnLabel={t("Delete")}
                  colorType="danger"
                />
                <CommonButton
                  width={BUTTON_WIDTH}
                  onClick={async () => {
                    setIsDirty(false)
                    resetStopData();
                    await setCurrentSetup(null);
                    if (user_role === role?.customer) {
                      setSelectedCompanies([])
                      setIsCompanyChosen(false)
                      setSearchCompanyKey("")
                      setRate(0)
                      setFavSelected(false)
                    }
                    navigateTo(`/request/edit/${request_id}`, { state: { referrer: "saved", role: user_role, request_id: request_id } });
                  }}
                  btnLabel={t("Edit")}
                />
              </>
            )}

            {user_role === role.company &&
              type === request_details_types.in_bidding && (
                <>
                  {(isDetailsPath || isProposalPath) && (
                    <CommonButtonOutlined
                      width={"w-[120px]"}
                      onClick={() => {
                        setDeclinePopupText({
                          title: t("Cancel Bid"),
                          submitButtonText: t("Confirm"),
                        });
                        setShowDeleteBiddingRequestModal(true);
                      }}
                      colorType="danger"
                      btnLabel={t("Cancel Bid")}
                    />
                  )}

                  {isAvailableShifts && (
                    <CommonButtonOutlined
                      width={BUTTON_WIDTH}
                      btnLabel={t("Update")}
                      onClick={async () => {
                        setIsDirty(false)
                        navigateTo(
                          detailsPath + k_request_action.UpDateProposal
                        );
                      }}
                    />
                  )}

                  {isEditPath ? (
                    <>
                      <CommonButton
                        width={BUTTON_WIDTH}
                        onClick={async () => {
                          if (location?.state?.referrer === request_details_types.not_planned || type === request_details_types?.not_planned) {
                            const stopSuccess = await stopTimeDurationSet(
                              request_id
                            );
                          }
                          const success =
                            await submitOrUpdateRequestInvitationBidding(
                              "update",
                              request_details?.my_bid?.id
                            );
                          if (success) {
                            setIsDirty(false)
                            await getRequestDetails(type, request_id);
                            navigateTo(request_details_path);
                          }
                        }}
                        btnLabel={t("Update")}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}


            {
              user_role === role.company &&
              type === request_details_types.awarded && (
                isEditingNow ?
                  <>
                    <CommonButton
                      width={BUTTON_WIDTH}
                      btnLabel={t("Update")}
                      isDisabled={!crFormSelectedShift}
                      onClick={
                        handleBidUpdate
                      }
                    />
                  </> :
                  isUpcomingEditable && !location.pathname.includes("/edit/shift") ?
                    <>
                      {
                        request_details?.user?.user_role !== role.company ?
                          <CommonButtonOutlined
                            width={"w-[140px]"}
                            onClick={() => {
                              setDeclinePopupText({
                                title: t("Cancel Request"),
                                submitButtonText: t("Confirm"),
                              });
                              setShowDeleteBiddingRequestModal(true);
                            }}
                            colorType="danger"
                            btnLabel={t("Cancel Request")}
                          />
                          :
                          <CommonButtonOutlined
                            width={"w-[120px]"}
                            onClick={() => {
                              setShowRequestDeleteConfirmModal(true);
                            }}
                            colorType="danger"
                            btnLabel={t("Delete")}
                          />
                      }

                      <CommonButtonOutlined
                        width="w-[120px]"
                        onClick={async () => {
                          setIsDirty(false)
                          resetStopData();
                          setCurrentSetup("");
                          if (request_details?.user?.role === "company") {
                            console.log("COMPANY EDIT");
                            // const res = await editRequest(request_id);
                            // if (res) {
                            setActiveSection(k_requests_status.awarded)
                            navigateTo(`/request/edit/${request_id}`, { state: { referrer: "awarded", role: "company", request_id: request_id } });
                            // }
                          }
                          else
                            navigateTo(`/requests/${type}/details/${request_id}/edit`)
                        }}
                        btnLabel={t("Edit")}
                      />

                      <ActionButton
                        label="Actions"
                        width="w-[120px]"
                        dataArray={[
                          {
                            title: "Download",
                            action: async () => {
                              const success = await downloadRequest(request_id);
                              if (success) {
                                await getRequestDetails(type, request_id);
                              }
                            }
                          },

                          {
                            title: "Share",
                            action: async () => {
                              setShowCommonShareModal(true);
                            }
                          }
                        ]}
                      />
                    </>
                    : location.pathname.includes("/edit/shift") ? <>
                      <CommonButtonOutlined
                        height="h-11"
                        colorType="danger"
                        btnLabel={t("Cancel")}
                        onClick={() => {
                          setFetchReqDetails(false);
                          navigateTo(`/requests/awarded/details/${request_id}/edit`, { state: { ...location?.state, is_fetch_req_details: false } })
                        }}
                      />

                      <CommonButton
                        className="h-11"
                        btnLabel={
                          parseInt(crFormSelectedShift) === parseInt(shift_id)
                            ? t("Unselect shift ")
                            : t("Select Shift")
                        }
                        onClick={async () => {
                          if (!isDirty) setIsDirty(true);
                          if (parseInt(crFormSelectedShift) == parseInt(shift_id)) {
                            setCrFormSelectedShift(null)
                          } else {
                            setCrFormSelectedShift(parseInt(shift_id))
                          }
                          {
                            setFetchReqDetails(false)
                            navigateTo(`/requests/awarded/details/${request_id}/edit`, { state: { ...location?.state, is_fetch_req_details: false } })
                          }
                        }}
                        width="w-[120px]"
                      />
                    </> :
                      <>
                        {getDownloadAction(true)}
                        {getShareAction(true)}
                      </>
              )
            }

            {user_role === role.customer &&
              type === request_details_types.in_bidding && (
                <>
                  <CommonButtonOutlined
                    width="w-[120px]"
                    colorType="danger"
                    onClick={async () => {
                      await setShowRequestDeleteConfirmModal(true);
                    }}
                    btnLabel={t("Delete")}
                  />

                  <CommonButtonOutlined
                    width="w-[120px]"
                    onClick={async () => {
                      changePageTitle("Limadi | Request Edit")
                      await setShowEditBiddingRequestConfirmModal(true);
                    }}
                    btnLabel={t("Edit")}
                  />

                  {/* {isBudgetPath && ( */}
                  <CommonButton
                    width="w-[120px]"
                    btnLabel={t("Accept")}
                    isDisabled={!inBiddingCheck || !selectBiddingId}
                    onClick={async () => {
                      if (!inBiddingCheck) {
                        Toastr({
                          message:
                            "You must agree to the terms and conditions.",
                          type: "warning",
                        });
                        return;
                      }

                      const success = await awardRequest(
                        selectBiddingId,
                        navigateTo
                      );
                      if (success) {
                        setIsDirty(false)
                        setRequestApiUrl(kuGetRequestsNew);
                        navigateTo(k_request_paths.awarded);
                      }
                    }}
                  />
                  {/* )} */}
                </>
              )}

            {user_role === role.customer &&
              type === request_details_types.awarded && (
                <>

                  <CommonButtonOutlined
                    width="w-[120px]"
                    colorType="danger"
                    onClick={async () => {
                      await setShowRequestDeleteConfirmModal(true);
                    }}
                    btnLabel={t("Delete")}
                  />

                  <CommonButtonOutlined
                    width="w-[120px]"
                    onClick={() => {
                      setShowCommonShareModal(true);
                    }}
                    btnLabel={t("Share")}
                  />



                  <CommonButtonOutlined
                    width="w-[120px]"
                    onClick={async () => {
                      await downloadRequest(request_id);
                    }}
                    btnLabel={t("Download")}
                  />


                </>
              )}

            {type === request_details_types.invitation && !isShiftDetailsPath && (
              <>
                <CommonButtonOutlined
                  width={BUTTON_WIDTH}
                  onClick={() => {
                    setDeclinePopupText({
                      title: t("Decline"),
                      submitButtonText: t("Decline"),
                    });
                    setShowDeclineInvitationConfirmModal(true);
                  }}
                  colorType="danger"
                  btnLabel={t("Decline")}
                />

                {isPlaceBidPath ? (
                  <CommonButton
                    width="w-[120px]"
                    onClick={() => {
                      handleSubmitPlaceBid(request_id, navigateTo);
                    }}
                    btnLabel={t("Accept")}
                    type={"submit"}
                  />
                ) : (
                  <CommonButton
                    width="w-[120px]"
                    // isDisabled={!place_bid_form?.budget || !place_bid_form?.description}
                    isDisabled={!!place_bid_form?.budget === false || parseInt(place_bid_form?.budget) < 1 || !!place_bid_form?.description === false || place_bid_form?.description?.trim() === ""}
                    onClick={() => {
                      if (isDirty) setIsDirty(false);
                      if (pickup_duration && pickup_duration < 3) {
                        Toastr({
                          message: t("Pickup duration can not be less than 3 minutes"),
                          type: "warning",
                        });
                        return;
                      }
                      handleSubmitPlaceBid(request_id, navigateTo);
                      // navigateTo(detailsPath + k_request_action.placeBid);
                    }}
                    btnLabel={t("Accept")}
                    type={"submit"}
                  />
                )}
              </>
            )}

            {user_role === role.company &&
              type === request_details_types.not_planned ? (
              <>
                {awarded?.status === k_arcd_status.init &&
                  isTimeExpired(request_details?.pickup_date, request_details?.pickup_start_time) &&
                  (editMode || (isProposalPath && !isVisitNotPlanedShift)) && (
                    <>
                      <CommonButtonOutlined
                        width="w-[150px]"
                        onClick={() => {
                          setTitle(t("Cancel Request"));
                          setSubTitle(
                            t("Are you sure want to cancel this request?")
                          );
                          setAction("reject");
                          setShowRequestActionsModal(true);
                        }}
                        btnLabel={t("Cancel Request")}
                        colorType="danger"
                      />
                    </>
                  )}

                {isEditPath && type === request_details_types.not_planned && (
                  <CommonButton
                    width={BUTTON_WIDTH}
                    btnLabel={t("Update")}
                    isDisabled={!crFormSelectedShift}
                    onClick={async () => {
                      if (crFormSelectedShift) {
                        await stopTimeDurationSet(request_id);
                        let success = await assignNotPlannedRequestShift(
                          request_id,
                          crFormSelectedShift
                        );
                        if (success) {
                          setIsDirty(false)
                          navigateTo(k_request_paths.not_planned);
                        }
                      } else {
                        Toastr({
                          message: t("You haven't selected a shift yet."),
                          type: "warning",
                        });
                      }
                    }}
                  />
                )}

                {/* {isEditPath && (
                  <CommonButtonOutlined
                    width={BUTTON_WIDTH}
                    btnLabel={t("Submit")}
                    onClick={async () => {
                      const success = await stopTimeDurationSet(request_id);
                      if (success) await getRequestDetails(type, request_id);
                      navigateTo("/requests/not-planned");
                    }}
                  />
                )} */}

                {
                  (type === request_details_types.not_planned || type === request_details_types.awarded) && isWithinTimeLimit(request_details?.pickup_date, request_details?.pickup_end_time) && !isEditingNow && !location.pathname?.includes("/shift/") && (
                    <CommonButton
                      width={BUTTON_WIDTH}
                      btnLabel={t("Edit")}
                      onClick={async () => {
                        setIsDirty(false)
                        changePageTitle("Limadi | Edit Request");
                        setFetchReqDetails((type === request_details_types.not_planned && request_details?.id == request_id) ? false : true);
                        if (type === request_details_types.not_planned) {
                          setCrFormSelectedShift(null)
                        }
                        navigateTo(
                          `/requests/${type}/details/${request_id}/edit`,
                          {
                            state: {
                              referrer: type,
                              role: user_role,
                              request_id: request_id,
                              isEditMode: true,
                              is_fetch_req_details: (type === request_details_types.not_planned && request_details?.id == request_id) ? false : true,
                            }
                          }
                        );
                      }}
                    />
                  )
                }

                {(isDetailsPath || (isProposalPath)) && type !== request_details_types.not_planned && (
                  <Button
                    commonButton={isProposalPath ? true : false}
                    width={BUTTON_WIDTH}
                    btnLabel={t("Edit")}
                    onClick={async () => {
                      setIsDirty(false)
                      navigateTo(`/requests/${type}/details/${request_id}/edit`);
                    }}
                  />
                )}
              </>
            ) : (
              ""
            )}

            {type === request_details_types.in_bidding &&
              (isProposalPath || isDetailsPath) &&
              user_role === role.company && (
                <CommonButton
                  width={BUTTON_WIDTH}
                  btnLabel={t("Edit")}
                  onClick={async () => {
                    setIsDirty(false)
                    navigateTo(`/requests/${type}/details/${request_id}/edit`);
                  }}
                />
              )}

            {type === request_details_types.history &&
              user_role === role.company ? (
              <>
                <Button
                  commonButton={isAcknowledgementPath ? true : false}
                  btnLabel={t("Download")}
                  onClick={async () => {
                    await downloadRequest(request_id);
                  }}
                />
                {getShareAction(true)}
              </>
            ) : null}

            {type === request_details_types.ongoing ? (
              <>

                {user_role === role.company ? (
                  <>
                    <Button
                      commonButton={isProposalPath ? true : false}
                      btnLabel={t("Download")}
                      onClick={async () => {
                        await downloadRequest(request_id);
                      }}
                    />
                    {getShareAction(true)}
                  </>
                ) : (
                  <>
                    {getShareAction(true)}
                    <Button
                      commonButton={true}
                      btnLabel={t("Download")}
                      onClick={async () => {
                        await downloadRequest(request_id);
                      }}
                    />
                  </>
                )}


              </>
            ) : null}


            <CompletedTopBar />
          </div>
        }
      />

      < RequestDeleteConfirmModal
        showModal={show_request_delete_confirm_modal}
        setShowModal={setShowRequestDeleteConfirmModal}
        request_id={request_id}
      />

      <CancelInBiddingRequest
        showModal={show_delete_bidding_request_modal}
        setShowModal={setShowDeleteBiddingRequestModal}
        request_id={request_id}
        title={declinePopupText?.title}
        submitButtonText={declinePopupText?.submitButtonText}
      />

      <RequestActionsModal
        showModal={show_request_actions_modal}
        setShowModal={setShowRequestActionsModal}
        request_id={request_id}
        action={action}
        title={title}
        sub_title={sub_title}
      />

      <RatingAndReviewModal
        showModal={show_rating_and_review_modal}
        setShowModal={setShowRatingAndReviewModal}
        request_id={request_id}
      />

      <DeclineInvitationConfirmModal
        title={declinePopupText?.title}
        submitButtonText={declinePopupText?.submitButtonText}
        showModal={showDeclineInvitationConfirmModal}
        setShowModal={setShowDeclineInvitationConfirmModal}
        request_id={request_id}
      />

      <ConfirmBiddingRequestEditModal
        showModal={show_edit_bidding_request_confirm_modal}
        setShowModal={setShowEditBiddingRequestConfirmModal}
        onConfirm={async () => {
          const res = await editRequest(request_id);
          setIsDirty(false);
          setSelectedCompanies([])
          setIsCompanyChosen(false)
          setSearchCompanyKey("")
          setRate(0)
          setFavSelected(false)
          res && navigateTo(`/request/edit/${request_id}`, { state: { referrer: request_details_types?.in_bidding, role: user_role, request_id: request_id, isEditMode: true } });
        }}
      />
    </div >
  );
}

export const Button = ({ commonButton = false, onClick, btnLabel }) => {
  if (commonButton) {
    return (
      <CommonButton
        width={BUTTON_WIDTH}
        onClick={onClick}
        btnLabel={btnLabel}
      />
    );
  } else {
    return (
      <CommonButtonOutlined
        width={BUTTON_WIDTH}
        onClick={onClick}
        btnLabel={btnLabel}
      />
    );
  }
};
