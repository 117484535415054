import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageBtn from "../image/ImageBtn";
import CancelImageBtn from "../image/CancelImageBtn";

const RequestAccordion = ({
  children,
  isInvalid = false,
  isExpanded,
  title,
  isForm = false,
  isBorder = false,
  isTable = false,
  onExpand,
  showClearButton = true,
  clearHandler,
  secondaryTitle,
  isDisabledSecondaryTitle = false,
  thirdTitle,
  isThirdTitleRed = true,
  subTitleOne,
  subTitleTwo,
  onClick = () => { },
  handleSecondaryTitleClick = () => { },
  handleThirdTitleClick = () => { },
  handleCancel = () => { },
  showCancelRightButton = false,
  showShift = false,
  fontSize = "text-[20px]",
  showBorder = false,
  clearButtonTooltipMessage = "",
  showBackgroundColor = true,
  expandedPaddingWidth = "p-s15",
  titleSecondaryText = "",
  titleRightComponent = null,
  titleRightComponentOnClick = () => { }, }) => {
  const { t } = useTranslation();


  let backgroundColor = "";
  if (!isBorder) {
    if (!isExpanded && !showShift) {
      backgroundColor = "#F9F3F0";
    }
  }

  return (
    <div
      className={`border-[1px] ${isBorder || (showShift && !isExpanded)
        ? `${isInvalid && !isExpanded ? "border-cRed500" : "border-cGray300"} rounded  ${isExpanded && `${expandedPaddingWidth} overflow-hidden`}`
        : `${(isInvalid & !isExpanded) ? "rounded border-cRed500" : " border-none"} `
        }`}
    >
      <Accordion
        sx={{
          borderRadius: "4px",
          "&.MuiAccordion-root:before": {
            height: "0",
          },
          "&.Mui-expanded": {
            padding: "0",
          },
        }}
        style={{
          // border: "1px solid #F0554B",
          borderTop: "none", // Remove the top border
          boxShadow: "none", // Remove the box shadow
          border: "none", // Remove all borders
          backgroundColor: showBackgroundColor ? backgroundColor : "",
          paddingTop: "-20px",
        }}
        expanded={isExpanded}
        onChange={() => {
          onExpand();
          onClick();
        }}
        ref={null}
        key={title}
        TransitionProps={{
          timeout: 500
        }}
      >
        <AccordionSummary
          sx={{
            padding: isExpanded ? "0" : "8px 16px 8px 16px",
            marginTop: isExpanded ? "-16px" : "0",
          }}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="flex justify-between w-full">
            <div className="flex flex-col w-full">
              <div className="flex justify-between w-full">
                <div className="flex items-center justify-between max-h-[36px] min-h-[36px] w-full space-x-3">
                  <div className="flex items-center space-x-3">
                    <div className={`${fontSize} flex justify-center items-center font-medium text-cGray800 leading-6`} >
                      {title ?? "NA"}
                    </div>

                    {isExpanded && titleSecondaryText && (
                      <div className="limadi-font-regular text-lg leading-6">{titleSecondaryText}</div>
                    )}
                    {isExpanded && showClearButton && (
                      <CancelImageBtn
                        hasHoverAction={true}
                        onClick={(e) => {
                          e.stopPropagation();
                          clearHandler();
                        }}
                        showTooltip={true}
                        tooltipMessage={clearButtonTooltipMessage === "" ? t("Clear") : clearButtonTooltipMessage}
                        iconSize="max-w-[12px] min-w-[12px] min-h-[12px] max-h-[12px]"
                      />
                    )}
                  </div>

                  {isExpanded && titleRightComponent && <div onClick={(e) => { e.stopPropagation(); titleRightComponentOnClick() }} className="leading-5 limadi-medium cursor-pointer text-cBrand">{titleRightComponent}</div>}
                </div>
                <div className="flex mr-4 space-x-3">
                  {
                    thirdTitle && isExpanded && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleThirdTitleClick();
                        }}
                        className={`font-medium text-fs16 flex justify-center items-center leading-[20px] w-max ${isThirdTitleRed ? "text-[#F0554B]" : "text-cBrand"} cursor-pointer mr-5`}
                      >
                        {thirdTitle}
                      </div>
                    )}
                  {
                    secondaryTitle && isExpanded && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          if (!isDisabledSecondaryTitle) handleSecondaryTitleClick();
                        }}
                        className={`font-medium text-fs16 flex justify-center items-center leading-[20px] w-max ${isDisabledSecondaryTitle ? "cursor-not-allowed text-cGray600" : "text-cBrand cursor-pointer"}`}
                      >
                        {secondaryTitle}
                      </div>
                    )}
                </div>
              </div>
              {!isExpanded && (
                <div className="w-full truncate  normal-text">
                  {!subTitleOne && !subTitleTwo
                    ? "No info added yet"
                    : `${subTitleOne ?? ""}  ${subTitleOne && subTitleTwo ? `|` : ""
                    } ${subTitleTwo ?? ""}`}

                </div>
              )}
            </div>
            {!isExpanded && showCancelRightButton && (
              <div className="flex items-center justify-center cursor-pointer">
                <CancelImageBtn
                  hasHoverAction={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCancel(e);
                  }}
                  showTooltip={true}
                  tooltipMessage={t("Delete")}
                  iconSize="max-w-[12px] min-w-[12px] min-h-[12px] max-h-[12px]"
                />
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: "0px", // Remove padding
            marginTop: isForm ? "-18px" : isTable ? "-10px" : "-4px", // Remove margin
            backgroundColor: "#Ffffff",
          }}
        >
          <div
            className={`${(showShift && !showBorder) && "rounded border-[1px] border-cGray300 p-4"} ${isExpanded ? "" : "pl-4"} `}
          >
            {children}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RequestAccordion;
