import React, { useEffect } from 'react'
import { useState } from 'react';
import useSettingsStore from '../../../../../app/stores/others/settingsStore';
import CommonButton from '../../../../../components/button/CommonButton';
import CommonInput from '../../../../../components/input/CommonInput';
import CommonModal from '../../../../../components/modal/CommonModal';
import { useTranslation } from 'react-i18next';
import { Toastr } from '../../../../../app/utility/utilityFunctions';
import useGeneralStore from '../../../../../app/stores/others/generalStore';


function EditAboutCompanyModal() {

    const { showAboutCompanyModal, setShowAboutCompanyModal, editCompanyProfile_form, setEditCompanyProfile_form, profileDetails } = useSettingsStore();
    const {isDirty, setIsDirty} = useGeneralStore()

    const [about, setAbout] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        setAbout(profileDetails?.about ?? "")
    }, [profileDetails])

    const handleCloseModal = () => {
        setShowAboutCompanyModal(false);
        setTimeout(()=> {
            setAbout(editCompanyProfile_form?.about ? editCompanyProfile_form?.about : profileDetails?.about)
        }, 500)
    }

    return (
        <div>
            <CommonModal
                showModal={showAboutCompanyModal}
                setShowModal={handleCloseModal}
                modalTitle={t("Edit About Company")}
                mainContent={
                    <>
                        <div className='mt-s20'>
                            <CommonInput
                                textarea={true}
                                labelText={t('About Company')}
                                name={'about'}
                                type='text'
                                max_input={255}
                                value={about}
                                onChange={(e) => {
                                    if(!isDirty) setIsDirty(true); 
                                    setAbout(e.target.value)
                                }}
                            />

                            <div className='flex justify-end mt-s50'>
                                <CommonButton
                                    onClick={() => {
                                        if (about) {
                                            setEditCompanyProfile_form({ ...editCompanyProfile_form, about: about })
                                            setShowAboutCompanyModal(false)
                                        } else {
                                            Toastr({ message: t("About Company is required"), type: 'error' })
                                        }

                                    }}
                                    isDisabled={!about?.trim()}
                                    btnLabel={t('Submit')}
                                />
                            </div>
                        </div>
                    </>
                }

            />
        </div>
    )
}

export default EditAboutCompanyModal;
