/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import useRequestStore, { getRequestDetails, handleSubmitPlaceBid, stopTimeDurationSet } from '../../../../app/stores/others/requestStore';
import { formatDate, formatSchedule, formatTime, isEndTimeSmaller, Toastr } from '../../../../app/utility/utilityFunctions';
import CommonButtonOutlined from '../../../../components/button/CommonButtonOutlined';
import CommonTopTitleSection from '../../../../components/title/CommonTopTitleSection';
import Summary from '../../../../components/utility/summary/Summary';
import { useTranslation } from 'react-i18next';
import useGeneralStore from '../../../../app/stores/others/generalStore';
import CommonButton from '../../../../components/button/CommonButton';
import Details from './Details';
import { create_request_steps, k_request_action } from '../../../../app/utility/const';
import AvailableShifts from '../../../common/requests/requestDetails/components/summary/components/AvailableShifts';
import PlaceBid from '../../../common/requests/requestDetails/components/summary/components/PlaceBid';
import BackLink from '../../../../components/backLink/BackLink';
import { iTableViewPrimary, iTableViewWhite } from '../../../../app/utility/imageImports';
import Pickup from '../../../common/requests/requestDetails/components/mainContents/components/Pickup';
import Deliveries from '../../../common/requests/requestDetails/components/mainContents/components/deliveries/Deliveries';
import ProposalInfo from '../../../common/requests/requestDetails/components/summary/components/ProposalInfo';
import RequestID from '../../../common/requests/components/RequestID';
import useCreateRequestStore from '../../../../app/stores/others/createRequestStore';
import useShiftStore from '../../../../app/stores/company/shiftStore';

const GlobalReqDetails = () => {

    const { request_details } = useRequestStore();
    const { setPathRecord } = useGeneralStore();
    const { generateStops, setPickupDuration, setGlobalReqMode, place_bid_form, updatePlaceBidForm, setShowRequestTableViewModal, fetchReqDetails } = useRequestStore();
    const { setExpandedAccordion } = useCreateRequestStore()
    const { resetFilterForm, resetFilterFormCopy } = useShiftStore();

    const { request_id } = useParams();

    const { t } = useTranslation();
    const location = useLocation();
    const navigateTo = useNavigate();

    const detailsPath = `/global-request/details/${request_id}`
    const isDetailsPath = window.location.pathname === `/global-request/details/${request_id}`
    const isAvailableShiftsPath = window.location.pathname === `/global-request/details/${request_id}/available-shifts`
    const isPlaceBid = window.location.pathname === `/global-request/details/${request_id}/${k_request_action.placeBid}`

    const requestSummaryContents = [

        {
            title: t('Status'),
            // description: (request_details?.status) ?? "NA",
            description: t("Global Request"),
        },
        {
            title: t('Title'),
            description: request_details?.title ?? "No Title",
        },
        {
            title: t('Transportation Type'),
            description: request_details?.transport_type ?? "",
            className: 'capitalize'
        },
        {
            title: t('Pickup Date'),
            description: formatSchedule(request_details?.pickup_date, request_details?.pickup_start_time, request_details?.pickup_end_time, " ", (isEndTimeSmaller(request_details?.pickup_start_time, request_details?.pickup_end_time) || (request_details?.pickup_start_time === request_details?.pickup_end_time)), false) ?? "",
        },
        {
            title: (request_details?.status === 'on-going' || request_details?.status === 'history') ? t('Picked Up Time') : t('Pickup Time'),
            description: request_details?.pickup_start_time && request_details?.pickup_end_time ? (formatTime(request_details?.pickup_start_time) ?? "") + ' - ' + (formatTime(request_details?.pickup_end_time) ?? "") : "",
        },
        {
            title: t('Delivery Overview'),
            description: `${request_details?.stops?.length ?? 0} ${request_details?.stops?.length > 1 ? 'Stops' : 'Stop'} ( ${request_details?.products?.length ?? 0} ${request_details?.products?.length > 1 ? t('Packages') : t('Package')} )`,
        },

        { title: t('Customer Name'), description: "Global", titleClassName: "summaryBorder" },
        { title: t('Agreed Budget'), description: place_bid_form?.budget ? ` DKK ${parseInt(place_bid_form?.budget)?.toLocaleString("da-DK")}` : "" },
    ]

    useEffect(() => {
        fetchData()
    }, [request_id, location]);

    const fetchData = async () => {
        if (location?.state?.is_fetch_req_details === false && fetchReqDetails === false) return;
        const success = await getRequestDetails("invitation", request_id);
        if (success) {
            window.scrollTo(0, 0);
        }
    }

    useEffect(() => {
        if (location?.state?.is_fetch_req_details === false && fetchReqDetails === false) return;
        setPickupDuration(request_details?.pickup_duration)
    }, [request_details])


    useEffect(() => {
        if (location?.state?.is_fetch_req_details === false && fetchReqDetails === false) return;
        updatePlaceBidForm({ customer_name: "Global" });
        setGlobalReqMode(true)
    }, [request_details?.my_bid, window.location.pathname])

    useEffect(() => {
        setPathRecord(location.pathname);
        resetFilterFormCopy();
        resetFilterForm();
    }, [location.pathname]);

    useEffect(() => {
        setExpandedAccordion(null);
        return () => setExpandedAccordion(null)
    }, [])

    let Component;
    switch (location.pathname) {

        case `${detailsPath}/` + k_request_action.availableShifts:
            Component = AvailableShifts;
            break;

        case `${detailsPath}/` + k_request_action.placeBid:
            Component = PlaceBid;
            break;
        default:
            Component = Details;
    }

    return (
        <div
            onClick={() => {
                console.log("location?.state:::::", location?.state);

            }}
        >

            {/* top title bar */}
            <CommonTopTitleSection
                withBackLink='/global-request'
                title={t('Global Request')}
                rightSideComponent={
                    <div className='flex space-x-5'>

                        {/* {
                            isDetailsPath && <CommonButtonOutlined
                                onClick={() => {
                                    setShowRequestTableViewModal(true)
                                }}
                                width='w-[140px]'
                                colorType="primary"
                                iconLeft={iTableViewWhite}
                                iconLeftHover={iTableViewPrimary}
                                btnLabel={t('Table view')}
                            />
                        } */}
                        {/* {
                            isPlaceBid ? */}
                        <CommonButton
                            isDisabled={!!place_bid_form?.budget === false || parseInt(place_bid_form?.budget) < 1 || !!place_bid_form?.description === false || place_bid_form?.description?.trim() === ""}
                            onClick={async () => {
                                if (!place_bid_form?.budget || !place_bid_form?.description) {
                                    Toastr({ message: t('Please fill up all required fields'), type: "warning" });
                                    return;
                                }

                                await setGlobalReqMode(true);
                                const success = await handleSubmitPlaceBid(request_id, navigateTo, "/global-request");
                                if (success) {
                                    await navigateTo("/global-request");
                                }
                            }}
                            btnLabel={t('Submit')}
                            type={'submit'}
                        />
                        {/* : ""} */}
                    </div>
                }
            />
            <BackLink
                linksArray={[
                    { label: t("Global Request"), isActive: false, linkTo: "/global-request", cursorPointer: true, linkFn: '', checkDirtyFunction: true },
                    { label: t("Request Details"), isActive: true, linkTo: "", linkFn: '' },
                ]}
            />

            <div className="mt-5">
                <RequestID request_id={request_details?.request_id} />
            </div>
            <div className='flex mt-4 space-x-10'>

                <div className='w-full space-y-5'>
                    {/* <Component /> */}
                    <Pickup data={request_details} />

                    <Deliveries data={request_details} showTableViewButton />

                    <AvailableShifts />

                    <ProposalInfo />
                </div>

                <div>
                    <Summary content={requestSummaryContents} />

                    {/* <div className={`flex ${(isPlaceBid || isAvailableShiftsPath) ? "justify-between" : "justify-end"} mt-5`}>

                        {(isPlaceBid || isAvailableShiftsPath) && <CommonButtonOutlined
                            btnLabel={t('Previous')}
                            onClick={async () => {
                                await getRequestDetails("invitation", request_id);
                                navigateTo(-1)
                            }}
                        />}


                        {(isDetailsPath || isAvailableShiftsPath) &&
                            <CommonButton
                                width='w-[100px]'
                                btnLabel={t('Next')}
                                onClick={async () => {
                                    if (isDetailsPath) {
                                        const success = await stopTimeDurationSet(request_id);
                                        if (success) {
                                            navigateTo(detailsPath + "/" + k_request_action.availableShifts)
                                        }
                                    } else if (isAvailableShiftsPath) {
                                        navigateTo(detailsPath + "/" + k_request_action.placeBid)
                                    }
                                }}
                            />}
                    </div> */}

                </div>
            </div>

            {/*v         right side section */}
        </div>
    )
}

export default GlobalReqDetails